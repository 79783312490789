import React from 'react'
import {
    Badge,
    FormControl,
    InputLabel,
    Select,
    Input,
    ListItemText,
    Checkbox,
    MenuItem,
    makeStyles,
    Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'
import { FormattedMessage } from 'react-intl'

const MenuProps = {
    PaperProps: {
        style: {
            width: 320,
        },
    },
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    menuItem: {
        height: '65px',
    },
    badge: {
        alignItems: 'center',
    },
    badgeDot: {
        backgroundColor: '#82a9f6',
        color: 'white',
    },
}))

const MultiSelectFilterDropdown = (props) => {
    const classes = useStyles()
    const { selectedFilters, filtersList, onSelectFilter } = props
    const getSelectedFilterNames = (selectedFilterValues) => {
        if (selectedFilterValues) {
            let currentlySelectedFiltersList = filtersList.filter(
                (filtersListItem) => selectedFilterValues.includes(
                    filtersListItem.status,
                ),
            )
            currentlySelectedFiltersList = currentlySelectedFiltersList.map((filter) => filter.name).join(', ')
            return currentlySelectedFiltersList
        }
        return ''
    }

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-checkbox-label">
                <Typography>
                    <FormattedMessage id="organizer_show" />
                </Typography>
            </InputLabel>
            <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedFilters}
                onChange={onSelectFilter}
                input={<Input />}
                renderValue={(selected) => getSelectedFilterNames(selected)}
                MenuProps={MenuProps}
            >
                {filtersList.map((filter) => (
                    <MenuItem key={uuid()} value={filter.status} className={classes.menuItem}>
                        <Badge badgeContent={(props.statusCounts ? props.statusCounts[`${filter.status}`] : 0)} className={classes.badge} classes={{ badge: classes.badgeDot }}>
                            <Checkbox checked={selectedFilters.indexOf(filter.status) > -1} />
                            <ListItemText primary={filter.name} />
                        </Badge>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

MultiSelectFilterDropdown.propTypes = {
    filtersList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        status: PropTypes.number,
    })),
    onSelectFilter: PropTypes.func,
    statusCounts: PropTypes.shape({
        [PropTypes.number]: PropTypes.number,
    }),
    selectedFilters: PropTypes.arrayOf(PropTypes.number),
}

MultiSelectFilterDropdown.defaultProps = {
    filtersList: [],
    onSelectFilter: () => { },
    statusCounts: {},
    selectedFilters: [],
}

export default MultiSelectFilterDropdown
