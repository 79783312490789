import React from 'react'
import { Grid, Box, Button, makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { format, getDay } from 'date-fns'
import { isToday } from 'date-fns/esm'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles({
    button: {
        borderRadius: '17px',
        boxShadow: '0px 3px 6px #00000029',
        border: 'none',
    }
})

const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const DayBrowser = (props) => {
    const classes = useStyles()
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            <Box mr={3}>
                <Button
                    className={classes.button}
                    color="secondary"
                    data-test-id="daybrowser-prev-button"
                    variant="outlined"
                    onClick={props.handlePrevClick}>
                    <FormattedMessage id="previous"/>
                </Button>
            </Box>
            <Grid item>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                >
                    <Grid item data-test-id="daybrowser-week">
                        <Typography color="secondary">
                            {isToday(props.date) && <FormattedMessage id="today" /> }
                            {!isToday(props.date) && <FormattedMessage id={weekDays[getDay(props.date)]} /> }
                            &nbsp;<span hidden={props.hideResultCount}>({props.totalResultCount || '-'})</span>
                        </Typography>
                    </Grid>
                    <Grid item data-test-id="daybrowser-year">
                        <Typography color="secondary">
                            {format(props.date, 'dd.MM.yyyy')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Box ml={3}>
                <Button
                    className={classes.button}
                    color="secondary"
                    data-test-id="daybrowser-next-button"
                    variant="outlined"
                    onClick={props.handleNextClick}>
                    <FormattedMessage id="next" />
                </Button>
            </Box>
        </Grid>
    )
}

export default DayBrowser

DayBrowser.propTypes = {
    handlePrevClick: PropTypes.func,
    handleNextClick: PropTypes.func,
    date: PropTypes.instanceOf(Date),
    totalResultCount: PropTypes.number,
}
