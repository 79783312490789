import React, { useCallback, useEffect, useState } from 'react'
import {
    makeStyles, Grid, Dialog, Typography, Box, CircularProgress, MenuItem, InputLabel, FormControl,
} from '@material-ui/core'
import {addDays, subDays, startOfDay, endOfDay, startOfYear} from 'date-fns'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSnackbar } from 'notistack'
import RideListItem from '../RideListItem'
import DayBrowser from '../DayBrowser'
import { statuses } from '../../utils/constants'
import ConfirmationDialog from '../ConfirmationDialog'
import RideAssignmentDetails from './RideAssignmentDetails'
import RidesStatusContext from '../../context/RidesStatusContext'
import MultiSelectFilterDropdown from '../MultiSelectFilterDropdown'
import Select from '@material-ui/core/Select';
import { useReactOidc } from '@axa-fr/react-oidc-context'
import useSWR from 'swr'

const organizerStatuses = [
    statuses.Ordered,
    statuses.Confirmed,
    statuses.Assigned,
    statuses.DriverConfirmed,
]



const useStyles = makeStyles((theme) => ({
    page: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
            left: '0',
            minWidth: '320px',
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            transform: 'translateX(-50%)',
        },
        top: '60px',
    },
    listContainer: {
        borderRadius: '2px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: '320px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '600px',
        },
        backgroundColor: '#FFF',
        margin: '0 !important',
        boxShadow: '0px 3px 6px #00000029',
        padding: '2em',
    },
    container: {
        width: '100%',
        backgroundColor: '#FFF',

    },
    item: {
        background: '#FFF 0% 0% no-repeat padding-box',
        width: '100%',
        maxWidth: '500px',
        margin: '5px 0px',
    },
}))

const Organizer = () => {
    const classes = useStyles()
    const intl = useIntl()
    const [isLoading, setIsLoading] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const { refreshCounts } = React.useContext(RidesStatusContext)
    const [showRideDetails, setShowRideDetails] = useState(false)
    const [confirmedRide, setConfirmedRide] = useState()
    const [rideData, setRideData] = useState(null)
    const [selectedRideId, setSelectedRideId] = useState()
    const [statusCounts, setStatusCounts] = useState({})
    const { enqueueSnackbar } = useSnackbar()
    const [activeDate, setActiveDate] = useState(new Date())
    const [selectedFilters, setSelectedFilters] = useState(organizerStatuses)
    const { oidcUser } = useReactOidc()
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(oidcUser.profile.org)
    const [organizations, setOrganizations] = useState([])
    const startDateParam = startOfDay(activeDate).toISOString()
    const endDateParam = endOfDay(activeDate).toISOString()
    
    const page = 1;
    const pageSize = 100;
    const showCancelled = selectedFilters.includes(statuses.Cancelled);
    
    let rideDataUrl = `/api/Rides?page=${page}&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}&showCancelled=${showCancelled}`

    if (selectedOrganizationId !== 'all') {
        rideDataUrl = `${rideDataUrl}&serviceProviderId=${selectedOrganizationId}`
    }

    const loadRideDataFetcher = async rideDataUrl => {
        const res = await fetch(rideDataUrl)
    
        if (!res.ok) {
            enqueueSnackbar(<FormattedMessage id="order_get_rides_error" />, { variant: 'error' })
        }
    
        return res.json()
    }

    const { data, isValidating, mutate } = useSWR(rideDataUrl, loadRideDataFetcher)

    const loadOrganizationsData = useCallback(() => {
        if (oidcUser.profile.role !== 'Admin') return;
        setIsLoading(true)
        const url = '/api/Organizations?organizationType=ServiceProvider'
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
                return enqueueSnackbar(<FormattedMessage id="order_get_organizations_error" />, { variant: 'error' })
            })
            .then((data) => {
                setOrganizations(data)
            })
            .catch(() => enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: 'error' }))
            .finally(() => setIsLoading(false))
    }, [enqueueSnackbar, oidcUser.profile.role])

    useEffect(() => {
        loadOrganizationsData()
    }, [loadOrganizationsData])

    useEffect(() => {
        if (data && data.totalCountPerStatus) {
            setStatusCounts(data.totalCountPerStatus)
        } else {
            setStatusCounts({})
        }
        if (data && data.results && data.results.length) {
            setRideData(data.results)
        } else {
            setRideData(null)
        }
    }, [data])

    useEffect(() => {
        mutate()
    }, [activeDate, mutate])

    useEffect(() => {
        mutate()
    }, [selectedOrganizationId, mutate])

    const closeConfirmModal = () => {
        setConfirmModalOpen(false)
    }

    const confirmRide = () => {
        fetch(`/api/Orders/${confirmedRide.id}/confirmation`, {
            method: 'PUT',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="organizer_order_confirmation_success" />, { variant: 'success' })
            closeConfirmModal()
            mutate()
        }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="organizer_order_confirmation_failed" />, { variant: 'error' })
        })
    }

    const handleDateNextClick = () => {
        setActiveDate(addDays(activeDate, 1))
    }

    const handleDatePrevClick = () => {
        setActiveDate(subDays(activeDate, 1))
    }

    const handleSelectingFilter = (event) => {
        setSelectedFilters(event.target.value)
    }

    const handleOrderConfirmation = (ride) => {
        setConfirmedRide(ride)
        setConfirmModalOpen(true)
    }

    const handleOpenRideDetails = (rideId) => {
        setSelectedRideId(rideId)
        setShowRideDetails(true)
    }

    const closeRideDetails = () => {
        setShowRideDetails(false)
        setSelectedRideId(null)
        mutate()
    }

    const assignRide = (rideId, driverId, vehicleId) => {
        fetch(`/api/rides/${rideId}/assignment?driverId=${driverId}&vehicleId=${vehicleId}`, {
            method: 'PUT',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="organizer_driver_assigned_success" />, { variant: 'success' })
            closeRideDetails()
            mutate()
            // Refresh organizer badge notification count after ride is assigned
            refreshCounts()
        }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="organizer_driver_assigned_failed" />, { variant: 'error' })
        })
    }

    const organizerFilter = [
        {
            name: intl.formatMessage({ id: 'organizer_filter_unconfirmeds' }),
            status: statuses.Ordered,
        },
        {
            name: intl.formatMessage({ id: 'organizer_filter_unhandleds' }),
            status: statuses.Confirmed,
        },
        {
            name: intl.formatMessage({ id: 'organizer_filter_confirmeds' }),
            status: statuses.Assigned,
        },
        {
            name: intl.formatMessage({
                id: 'organizer_filter_driver_acknowledgeds',
            }),
            status: statuses.DriverConfirmed,
        },
        {
            name: intl.formatMessage({ id: 'organizer_filter_cancelleds' }),
            status: statuses.Cancelled,
        },
        {
            name: intl.formatMessage({ id: 'organizer_filter_driver_started' }),
            status: statuses.DriverStarted,
        },
        {
            name: intl.formatMessage({ id: 'organizer_filter_in_progress' }),
            status: statuses.RideStarted,
        },
        {
            name: intl.formatMessage({ id: 'organizer_filter_ride_ended' }),
            status: statuses.RideEnded,
        },
    ]

    const filterRideData = () => (
        rideData.filter((ride) => selectedFilters.includes(ride.status))
    )
    return (
        <>
            <div className={classes.page}>
                <div className={classes.listContainer}>
                    <Grid container justify="space-between" alignItems="center" direction="column" className={classes.container}>
                        <Grid item container className={classes.item} alignItems="center" justify="center">
                            <Typography variant="h5"><FormattedMessage id="organizer_orders" /></Typography>
                        </Grid>
                        <Box m={1} />
                        <Grid item className={classes.item}>
                            <DayBrowser
                                date={activeDate}
                                handlePrevClick={handleDatePrevClick}
                                handleNextClick={handleDateNextClick}
                                totalResultCount={rideData && rideData.length}
                            />
                        </Grid>
                        {oidcUser.profile.role === 'Admin' && 
                            <Grid item className={classes.item}>
                                <FormControl className={classes.item}>
                                    <InputLabel id="organization-select-input-label">
                                        <Typography>
                                            <FormattedMessage id="service_provider" />
                                        </Typography>
                                    </InputLabel>
                                    <Select
                                        data-test-id="organization-select"
                                        labelId="organization-select"
                                        onChange={(e) => setSelectedOrganizationId(e.target.value)}
                                        defaultValue={oidcUser.profile.org}
                                    >
                                        <MenuItem key='all' value={'all'}><FormattedMessage id="all" /></MenuItem>
                                        {organizations && organizations.map((organization) =>
                                            <MenuItem key={organization.id} value={organization.id}>
                                                {organization.name}
                                            </MenuItem>,
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item className={classes.item}>
                            <MultiSelectFilterDropdown
                                onSelectFilter={handleSelectingFilter}
                                filtersList={organizerFilter}
                                statusCounts={statusCounts}
                                selectedFilters={selectedFilters}
                            />
                        </Grid>
                        <Grid item className={classes.item}>
                            {
                                isValidating || isLoading ?
                                    <CircularProgress /> : rideData ? filterRideData().map((ride) => <RideListItem
                                        key={ride.id}
                                        rideData={ride}
                                        confirmOrder={(ride) => handleOrderConfirmation(ride)}
                                        openRideDetails={(ride) => handleOpenRideDetails(ride.id)}
                                        allowAssignmentConfirm={false}
                                    />) : <div><FormattedMessage id="organizer_no_orders_today" /></div>
                            }
                        </Grid>
                    </Grid>
                </div>

                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={confirmModalOpen}
                    onClose={closeConfirmModal}
                >
                    <ConfirmationDialog
                        onCloseClick={closeConfirmModal}
                        onConfirmClick={confirmRide}
                        modalConfirmButtonText={<FormattedMessage id="organizer_dialog_modalConfirmButtonText" />}
                        headerText={<FormattedMessage id="organizer_dialog_headerText" />}
                        typography1={<FormattedMessage id="organizer_dialog_typo1" />}
                        typography2={<FormattedMessage id="organizer_dialog_typo2" />}
                    />
                </Dialog>

                {
                    selectedRideId
                    && rideData
                    && <Dialog
                        fullWidth
                        maxWidth="md"
                        open={showRideDetails}
                        onClose={closeRideDetails}
                    >
                        <RideAssignmentDetails
                            onAssignDriver={(rideId, driverId, vehicleId) => assignRide(rideId, driverId, vehicleId)}
                            onCloseClick={closeRideDetails}
                            rideId={selectedRideId}
                            rides={rideData}
                            confirmOrder={(ride) => handleOrderConfirmation(ride)}
                        />
                    </Dialog>
                }
            </div>
        </>
    )
}

export default Organizer
