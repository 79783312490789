import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { paymentTypes } from '../utils/constants'

const useStyles = makeStyles((theme) => ({
    invoicePanel: {
        backgroundColor: '#FBFBFB',
        padding: theme.spacing(2),
    },
    invoiceTable: {
        width: '100%',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    minimumFeeRow: {
        fontWeight: 'bold',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    totalRow: {
        fontWeight: 'bold',
    },
}))

const RidePaymentPanel = ({ paymentType, invoiced }) => {
    const classes = useStyles()

    return (
        <div className={classes.invoicePanel}>
            <div>
                <b><FormattedMessage id="cards_payment_method" />:</b>
                &nbsp;
                {paymentType === paymentTypes.Invoice ? <FormattedMessage id="cards_invoice" /> : <FormattedMessage id="cards_credit_card" />}
            </div>
            <div>
                <b><FormattedMessage id="order_invoiced" />:</b>
                &nbsp;
                {invoiced ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}
            </div>
        </div>
    )
}

export default RidePaymentPanel

RidePaymentPanel.propTypes = {
    paymentType: PropTypes.number.isRequired,
    invoiced: PropTypes.bool.isRequired,
}
