import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Typography, DialogContent, Grid, Button, Divider, makeStyles, CircularProgress, IconButton, DialogTitle } from '@material-ui/core'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { addHours, format } from 'date-fns'
import { useSnackbar } from 'notistack'
import CarIcon from '@material-ui/icons/DriveEta'
import PersonIcon from '@material-ui/icons/Person'
import ScheduleIcon from '@material-ui/icons/Schedule'
import CloseIcon from '@material-ui/icons/Close'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import VehicleCategoriesDropdown from '../VehicleCategoriesDropdown'

const useStyles = makeStyles((theme) => ({
  scheduleRow: {
    display: 'inline-flex',
    textTransform: 'none',
  },
  scheduleIcon: {
    marginRight: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  driverDialog: {
    minHeight: '100px',
    minWidth: '230px',
  },
  titlePanel: {
    backgroundColor: '#F8F6F1',
  },
}))

const RideDriverDialog = ({ handleClose, handleChange, startDate, category }) => {
  const { oidcUser } = useContext(AuthenticationContext)
  const [schedules, setSchedules] = useState()
  const [showProgressIcon, setShowProgressIcon] = useState(false)
  const [filterCategory, setFilterCategory] = useState(category)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const loadSchedules = useCallback(() => {
    const orgId = oidcUser?.profile?.org
    const start = addHours(new Date(startDate), -1)
    const end = addHours(new Date(startDate), 1)
    setShowProgressIcon(true)

    fetch(`/api/vehicleschedules?organizationId=${orgId}&startTime=${start.toISOString()}&endTime=${end.toISOString()}&vehicleCategoryId=${filterCategory}&page=1&pageSize=100`)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.status)
        }
        return results.json()
      }).then((data) => {
        setSchedules(data.results)
      }).catch(() => {
        enqueueSnackbar(<FormattedMessage id="schedules_load_error" />, { variant: 'error' })
      }).finally(() => {
        setShowProgressIcon(false)
      })

  }, [enqueueSnackbar, startDate, filterCategory])

  useEffect(() => {
    loadSchedules()
  }, [startDate, filterCategory])

  const onCategoryChange = (newCategory) => {
    setFilterCategory(newCategory)
  }

  return (
    <>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle className={classes.titlePanel}><FormattedMessage id="ride_driver_dialog_choose_driving_shift" /></DialogTitle>
      <DialogContent className={classes.driverDialog}>
        <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
                <VehicleCategoriesDropdown
                    handleCategoryChange={onCategoryChange}
                    defaultValue={category}
                    labelText={<FormattedMessage id="organizer_show_schedules_for_vehicle_class" />}
                />
            </Grid>
                  {!schedules
                      && <Grid item xs={12}>
                            <CircularProgress hidden={showProgressIcon} />
                        </Grid>
                  }
          {(schedules && schedules.length === 0) &&
            <Grid item xs={12}>
              <Typography variant="body1"><FormattedMessage id="ride_driver_dialog_no_shifts" /></Typography>
            </Grid>
          }
          {schedules?.map((schedule, i) => {
            const startTimestamp = format(new Date(schedule.startTime), 'HH:mm')
            const endTimestamp = format(new Date(schedule.endTime), 'HH:mm')
            return (
              <Grid item key={schedule.id} xs={12}>
                <Button
                  id={`selectDriver${i}`}
                  data-test-id={`selectDriver${i}`}
                  fullWidth
                  onClick={() => {
                    handleChange(
                      { id: schedule.driverId, name: schedule.driverName },
                      { id: schedule.vehicleId, name: schedule.licensePlate }
                    )
                  }}
                >
                  <Grid container>
                    <Grid container item alignItems="flex-start" direction="column">
                      <div className={classes.scheduleRow}>
                        <CarIcon fontSize="small" color="disabled" className={classes.scheduleIcon} />
                        <Typography variant="body1">{schedule.licensePlate} {schedule.vehicleName}</Typography></div>
                      <div className={classes.scheduleRow}>
                        <PersonIcon fontSize="small" color="disabled" className={classes.scheduleIcon} />
                        <Typography variant="body1"> {schedule.driverName}</Typography></div>
                      <div className={classes.scheduleRow}>
                        <ScheduleIcon fontSize="small" color="disabled" className={classes.scheduleIcon} />
                        <Typography variant="body2">{startTimestamp} &rarr; {endTimestamp}</Typography></div>
                    </Grid>
                  </Grid>
                </Button>
                {(schedules && schedules[i + 1]) && <Divider />}
              </Grid>)
          })}
        </Grid>
      </DialogContent>
    </>
  )

}

RideDriverDialog.propTypes = {
  handleClose: PropTypes.func,
  handleChange: PropTypes.func,
  startDate: PropTypes.string,
  category: PropTypes.string,
}

export default RideDriverDialog