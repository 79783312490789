import { addDays } from 'date-fns'

export const mapOrderDataToBackCreate = (data) => (
        {
            driveType: data.driveType,
            additionalInformation: data.additionalInformation,
            showAdditionalInfoForPassenger: data.showAdditionalInfoForPassenger,
            numberOfPassengers: data.numberOfPassengers,
            passengerUserGuid: data.passengerUserGuid === 'otherPassenger' ? null : data.passengerUserGuid,
            otherPassengerName: data.otherPassengerName,
            otherPassengerPhone: data.otherPassengerPhone,
            otherPassengerEmail: data.otherPassengerEmail,
            otherPassengerCompany: data.otherPassengerCompany,
            paymentType: data.paymentType,
            paymentCreditCardGuid: data.paymentCreditCardGuid,
            usePassengerUserGuid: data.passengerUserGuid !== 'otherPassenger',
            filesToUpload: data.filesToUpload,
            terminalPickup: data.terminalPickup,
            rides: [
                {
                    plannedPickupDate: data.plannedPickupDate,
                    requestedVehicleClassGuid: data.requestedVehicleClassGuid,
                    filesToUpload: data.filesToUpload,
                    sendConfirmationEmailToSubscriber: data.sendConfirmationEmailToSubscriber,
                    routeSteps: data.routeSteps.map((routeStep, index) => ({
                        ...routeStep,
                        stepOrder: index + 1,
                    })),
                },
            ],
        }
    )

export const mapOrderDataToBackUpdate = (data) => (
        {
            id: data.id,
            driveType: data.driveType,
            additionalInformation: data.additionalInformation,
            showAdditionalInfoForPassenger: data.showAdditionalInfoForPassenger,
            numberOfPassengers: data.numberOfPassengers,
            passengerUserGuid: data.passengerUserGuid === 'otherPassenger' ? null : data.passengerUserGuid,
            otherPassengerName: data.otherPassengerName,
            otherPassengerPhone: data.otherPassengerPhone,
            otherPassengerEmail: data.otherPassengerEmail,
            otherPassengerCompany: data.otherPassengerCompany,
            paymentType: data.paymentType,
            paymentCreditCardGuid: data.paymentCreditCardGuid,
            usePassengerUserGuid: data.passengerUserGuid !== 'otherPassenger',
            filesToUpload: data.filesToUpload,
            terminalPickup: data.terminalPickup,
            rides: [
                {
                    id: data.rideId,
                    plannedPickupDate: data.plannedPickupDate,
                    requestedVehicleClassGuid: data.requestedVehicleClassGuid,
                    routeSteps: data.routeSteps.map((routeStep, index) => ({
                        ...routeStep,
                        stepOrder: index + 1,
                    })),
                },
            ],
        }
    )

export const mapOrganizerEditRideDataToBackUpdate = (formData, rideData) => (
        {
            ...rideData.order,
            filesToUpload: formData.filesToUpload,
            numberOfPassengers: formData.numberOfPassengers,
            passengerUserGuid: rideData.order?.passengerUser?.id ? rideData.order.passengerUser.id : null,
            usePassengerUserGuid: !!rideData.order?.passengerUser?.id,
            driveType: formData.driveType,
            additionalInformation: formData.additionalInformation,
            terminalPickup: formData.terminalPickup,
            rides: [
                {
                    id: rideData.id,
                    plannedPickupDate: formData.plannedPickupDate,
                    requestedVehicleClassGuid: formData.requestedVehicleClassGuid,
                    routeSteps: formData.routeSteps.map((routeStep, index) => ({
                        ...routeStep,
                        stepOrder: index + 1,
                    })),
                },
            ],
        }
    )

export const mapWeekByDate = (firstDayOfWeek) => {
    if (!firstDayOfWeek)
        return []
    const weekDateList = []
    for (let i = 0; i < 7; i++) {
        if (i === 0) {
            weekDateList[i] = firstDayOfWeek
        }
        weekDateList[i] = addDays(firstDayOfWeek, i)
    }
    return weekDateList
}
