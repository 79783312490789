import React, { useState, useCallback, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { IconButton } from '@material-ui/core';
import { Typography, Button, Grid, DialogActions, DialogContentText, DialogContent, DialogTitle, Dialog } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile'
import DeleteIcon from '@material-ui/icons/Delete'
import AttachmentIcon from '@material-ui/icons/Attachment'

const Attachment = (props) => {
    return (
        <Grid key={props.id} container item direction="row" alignItems="center">
            <a href={props.fileUrl}>
                <Typography>
                    <AttachmentIcon fontSize="small" /> {props.fileName}
                </Typography>
            </a>
            <IconButton data-test-id="deleteAttachmentButton" onClick={() => props.deleteAttachment()}>
                <DeleteIcon color="secondary" />
            </IconButton>
        </Grid>)
}

const FileUpload = ({ orderId }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [attachments, setAttachments] = useState([])
    const [deletedAttachmentId, setDeletedAttachmentId] = useState()

    const {
        watch,
        setValue,
        getValues,
    } = useFormContext()

    const loadAttachments = useCallback(() => {
        if (!orderId) {
            return
        }
        fetch(`/api/Orders/${orderId}/attachments`, {
            method: 'GET'
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
            }).then(res => {
                if (res && res.length > 0) {
                    setAttachments(res[0])
                } else {
                    setAttachments([])
                }
            })
    }, [orderId])

    // fetch attachments
    useEffect(() => {
        loadAttachments()
    }, [loadAttachments])



    const confirmAttachmentDelete = (attachmentId) => {
        setDeletedAttachmentId(attachmentId)
        setShowDeleteConfirmation(true)
    }

    const deleteAttachmentFromBackend = () => {
        fetch(`/api/Attachments/${deletedAttachmentId}`, {
            method: 'DELETE'
        })
            .then((response) => {
                if (response.ok) {
                    return response
                }
            }).then(() => {
                enqueueSnackbar(<FormattedMessage id="delete_succeed" />, { variant: 'info' })
                loadAttachments()
            })
            .catch(() => enqueueSnackbar(<FormattedMessage id="order_fileupload_failed" />, { variant: 'error' }))
            .finally(() => setShowDeleteConfirmation(false))
    }

    // delete file that is not yet uploaded
    const deleteAttachment = (file) => {
        const uploads = getValues()['filesToUpload']
        const listOfAttachments = []
        for (const f in uploads) {
            if (uploads[f].name !== file.name) {
                listOfAttachments.push(uploads[f])
            }
        }
        setValue('filesToUpload', listOfAttachments)
    }

    const addToUploads = (file, uploads) => {
        // Check if user clicked "cancel" on file dialog
        if (file) {
            if (uploads === undefined) {
                setValue('filesToUpload', [file])
            } else {
                uploads.push(file)
                setValue('filesToUpload', uploads)
            }
        }
    }

    return (
        <>
            <Grid item container alignItems="flex-start" direction="column">
                {attachments && attachments.map(attachment => <Attachment key={attachment.id} id={attachment.id} fileUrl={attachment.fileUrl} fileName={attachment.fileName} deleteAttachment={() => confirmAttachmentDelete(attachment.id)}></Attachment>)}
                {watch()['filesToUpload'] && watch()['filesToUpload'].map((f, index) => <Attachment key={index} fileName={f.name} deleteAttachment={() => deleteAttachment(f)}></Attachment>)}
            </Grid>
            <Grid item container direction="column">
                <input
                    accept=".gif, .png, .jpg, .jpeg, .zip, .pdf, .doc, .docx, .xls, .xlsx, .rtf"
                    style={{ display: 'none' }}
                    id="outlined-button-file"
                    type="file"
                    name="filesToUpload"
                    onChange={(e) => {
                        addToUploads(e.target.files[0], getValues()['filesToUpload'])
                        // EDUS-355: Clear input field so that user can select file with same name again
                        // https://stackoverflow.com/a/42192710/3605
                        e.target.value = null
                    }}
                />
                <label htmlFor="outlined-button-file">
                    <Typography variant="subtitle2" color="secondary" style={{ cursor: 'pointer' }}>
                        <AttachFileIcon />
                        <FormattedMessage id="order_add_attachment_button" />
                    </Typography>
                </label>
            </Grid>
            <Dialog open={showDeleteConfirmation} maxWidth="xs">
                <DialogTitle><FormattedMessage id="order_attachment_are_you_sure" /></DialogTitle>
                <DialogContent>
                    <DialogContentText><FormattedMessage id="order_attachment_cannot_be_retrieved" /></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button data-test-id="cancelAttachmentDelete" color="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button data-test-id="confirmAttachmentDelete" color="primary" variant="outlined" onClick={() => deleteAttachmentFromBackend()}>
                        <FormattedMessage id="delete" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FileUpload