import React, {
    useState, useEffect, useCallback, Fragment,
} from 'react'
import {
    makeStyles, Grid, Select, MenuItem, Button,
    DialogActions, Dialog, DialogTitle, DialogContent,
    InputLabel, Box, Typography, TextField, CircularProgress, IconButton,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import {
    format, addDays, addHours, subDays, getMonth, setYear,
    getYear, addMinutes, isValid, startOfDay, endOfDay,
    differenceInMinutes, differenceInHours, getDate, getHours, getMinutes,
} from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { KeyboardTimePicker, } from '@material-ui/pickers'
import { parseISO, setDate, setMonth } from 'date-fns/esm'
// Icons - Remember to fetch per icon to keep hot reload fast
import AddCircle from '@material-ui/icons/AddCircle'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import { useReactOidc } from '@axa-fr/react-oidc-context'

import DayBrowser from './DayBrowser'
import OrganizationsDropdown from './OrganizationDropdown'

const useStyles = makeStyles((theme) => ({
    page: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
            left: '0',
            minWidth: '320px',
        },
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            transform: 'translateX(-50%)',
        },
        top: '60px',
    },

    container: {
        width: '100%',
        borderRadius: '2px',
        minWidth: '320px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '600px',
        },
        backgroundColor: '#FFF',
        margin: '0 !important',
        boxShadow: '0px 3px 6px #00000029',
        padding: '1em',
    },

    tab: {
        width: '100%',
        maxWidth: '720px',
    },

    formInput: {
        width: '100%',
        verticalAlign: 'bottom',
        margin: '0px',
    },

    table: {
        width: '100%',
        '& th': {

            padding: '12px',
            textOverflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        '& tr': {
            borderTop: '1px solid #B7A355',
        },
    },

    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: 0,
        marginLeft: 0,
    },
    genericProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: 0,
        marginLeft: 0,
    },

    schHeader: {
        backgroundColor: '#F8F6F1',
        border: '1px solid #B7A355',
    },
    vehicleCol: {
        width: '20%',
    },
    daysCol: {
        width: '80%',
    },
    dayCol: {
        width: '10%',
    },

    schedule: {
        width: '80%',
    },
    dayDateTitle: {
        width: '100%',
        maxWidth: '720px',
    },
    contactNameSelect: {
        width: '100%',
        maxWidth: '720px',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

const emptyGuid = '00000000-0000-0000-0000-000000000000'

const emptyShiftDetails = ({
    driver: '',
    startTime: startOfDay(new Date()),
    shiftHours: 8,
    shiftMinutes: 0,
    selectedVehicleLicensePlate: '',
})

const VehicleSchedules = () => {
    const { oidcUser } = useReactOidc()
    const { profile } = oidcUser
    const [selectedDay, setSelectedDay] = useState(startOfDay(new Date()))
    const [schedules, setSchedules] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('')
    const [drivers, setDrivers] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [showDialog, setShowDialog] = useState(false)
    const [showSaveSpinner, setShowSaveSpinner] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [deletedScheduleId, setDeletedScheduleId] = useState('')
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [serviceProvider, setServiceProvider] = useState(emptyGuid)

    const [shiftDetails, setShiftDetails] = useState(emptyShiftDetails)

    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const handlePrevClick = () => {
        setSelectedDay(subDays(selectedDay, 1))
    }

    const handleNextClick = () => {
        setSelectedDay(addDays(selectedDay, 1))
    }

    const loadCategories = useCallback(() => {
        if (!categories || categories.length === 0) {
            fetch('/api/vehiclecategories')
                .then((results) => {
                    return results.json()
                }).then((data) => {
                    setCategories(data)
                })
        }
    }, [categories])

    const loadSchedules = useCallback((category) => {
        setShowSpinner(true)
        const orgId = serviceProvider === emptyGuid ? profile?.org : serviceProvider
        fetch(`/api/vehicleschedules?organizationId=${orgId}&startTime=${startOfDay(selectedDay).toISOString()}&endTime=${endOfDay(selectedDay).toISOString()}&vehicleCategoryId=${category}&page=1&pageSize=100`)
            .then((results) => {
                if (!results.ok) {
                    throw Error(results.status)
                }
                return results.json()
            }).then((data) => {
                setSchedules(data.results)
                setShowSpinner(false)
            }).catch(() => {
                setShowSpinner(false)
                enqueueSnackbar(<FormattedMessage id="schedules_load_error" />, { variant: 'error' })
            })
    }, [enqueueSnackbar, selectedDay, serviceProvider])

    const loadVehicles = (orgId) => {
        fetch(`/api/vehicles?OwnerId=${orgId}`)
            .then((results) => {
                if (!results.ok) {
                    throw Error(results.status)
                }
                return results.json()
            }).then((data) => {
                setVehicles(data)
            }).catch(() => {
                enqueueSnackbar(<FormattedMessage id="schedules_vehicles_load_error" />, { variant: 'error' })
            })
    }

    const loadDrivers = (orgId) => {
        if (orgId) {
            fetch(`/api/organizations/${orgId}/drivers`)
                .then((results) => {
                    return results.json()
                }).then((data) => {
                    setDrivers(data)
                })
        }
    }

    useEffect(() => {
        if (serviceProvider != null && serviceProvider !== emptyGuid) {
            loadVehicles(serviceProvider)
            loadDrivers(serviceProvider)
        }
    }, [serviceProvider])

    useEffect(() => {
        setServiceProvider(profile.org)
    }, [profile])

    const updateCategory = (category) => {
        setSelectedCategory(category)
        loadSchedules(category)
    }

    useEffect(() => {
        loadCategories()
    }, [selectedCategory, loadCategories])

    useEffect(() => {
        if (selectedCategory && selectedCategory !== '') {
            loadSchedules(selectedCategory)
        }
    }, [selectedDay, selectedCategory, loadSchedules])

    const handleSubmit = (e) => {
        e.preventDefault()

        setShowSaveSpinner(true)

        // Cleanup the start time by removing seconds and fraction of seconds
        const startTimeWithoutSeconds = new Date(
            getYear(shiftDetails.startTime),
            getMonth(shiftDetails.startTime),
            getDate(shiftDetails.startTime),
            getHours(shiftDetails.startTime),
            getMinutes(shiftDetails.startTime),
        )

        const newSchedule = {
            id: shiftDetails.id,
            driverId: shiftDetails.driver,
            vehicleId: vehicles.find((car) => car.licensePlate === shiftDetails.selectedVehicleLicensePlate).id,
            licensePlate: shiftDetails.selectedVehicleLicensePlate,
            startTime: startTimeWithoutSeconds,
            endTime: addMinutes(addHours(startTimeWithoutSeconds, shiftDetails.shiftHours), shiftDetails.shiftMinutes)
        }

        const apiUrl = shiftDetails.id ? `api/vehicleschedules/${shiftDetails.id}` : '/api/vehicleschedules'
        const apiMethod = shiftDetails.id ? 'PUT' : 'POST'

        fetch(apiUrl, {
            method: apiMethod,
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(newSchedule),
        }).then((response) => {
            if (!response.ok) {
                response.json().then((err) => {
                    setShowSaveSpinner(false)
                    return enqueueSnackbar(
                        <><FormattedMessage id="save_failed" /><br />{err.message}</>, { variant: 'warning' })
                })
                return
            }
            enqueueSnackbar(<FormattedMessage id="save_succeed" />, { variant: 'success' })
            loadSchedules(selectedCategory)
            setShowSaveSpinner(false)
            closeDialog()
        }).catch(() => {
            setShowSaveSpinner(false)
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: 'warning' })
        })
    }

    const deleteSchedule = () => {
        fetch(`/api/vehicleschedules/${deletedScheduleId}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
            }
        }).then((response) => {
            if (!response.ok) {
                response.json().then((err) => {
                    return enqueueSnackbar(<><FormattedMessage id="delete_failed" /><br />{err.message}</>, { variant: 'warning' })
                })
                return
            }
            enqueueSnackbar(<FormattedMessage id="delete_succeed" />, { variant: 'success' })
            setShowDeleteConfirmation(false)
            loadSchedules(selectedCategory)
        }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="delete_failed" />, { variant: 'warning' })
        })
    }

    const openDialog = () => setShowDialog(true)

    const closeDialog = () => {
        setShowDialog(false)
        setShiftDetails(emptyShiftDetails)
    }

    const formatHhMm = (endTime, startTime) => {
        const totalMinutes = differenceInMinutes(endTime, startTime)
        const hours = differenceInHours(new Date(endTime), new Date(startTime))
        const minutes = Math.round(((totalMinutes / 60) % 1) * 60)
        return <>{hours}:{minutes < 10 ? `0${minutes}` : minutes}</>
    }

    const formatSamedayHhMm = (dateStamp) => {
        let stamp = ''

        if (getDate(selectedDay) !== getDate(dateStamp)) {
            stamp += `${format(dateStamp, 'dd.MM.')} `
        }
        stamp += format(dateStamp, 'HH:mm')
        return stamp
    }
    
    return (
        <div className={classes.page}>
            <Grid container className={classes.container}>
                <Grid container justify="center" alignItems="center" direction="column">
                    <Grid item xs={12}>
                        <h1><FormattedMessage id="schedules_title" /></h1>
                        <Box m={4} />
                        <DayBrowser
                            hideResultCount
                            handlePrevClick={handlePrevClick}
                            handleNextClick={handleNextClick}
                            date={selectedDay}
                        />

                        {profile?.role === 'Admin' &&
                            <>
                                <Box m={4} />
                                <OrganizationsDropdown
                                    defaultOrg={profile.org}
                                    orgType="ServiceProvider"
                                    handleOrgChange={(org) => setServiceProvider(org.id)}
                                />
                            </>}

                        <Box m={4} />
                        <InputLabel id="category-select-label">
                            <FormattedMessage id="schedules_select_category" />
                        </InputLabel>
                        <Select
                            fullWidth
                            required
                            labelId="category-select-label"
                            id="category"
                            value={selectedCategory}
                            displayEmpty
                            onChange={(event) => updateCategory(event.target.value)}
                        >
                            {categories.map((category) => (
                                <MenuItem value={category.id} key={category.id}>{category.name}</MenuItem>
                            ))}
                        </Select>
                        {showSpinner && <CircularProgress size={24} className={classes.genericProgress} />}
                        <Box m={4} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <table className={classes.table}>
                        <thead className={classes.schHeader}>
                            <tr>
                                <th className={classes.vehicleCol}>
                                    <FormattedMessage id="schedules_vehicle" />
                                </th>
                                <th colSpan={8} />
                                {/* TODO ajovuorot aikajanalle
                <th className={classes.dayCol}>00:00</th>
                <th className={classes.dayCol}>03:00</th>
                <th className={classes.dayCol}>06:00</th>
                <th className={classes.dayCol}>09:00</th>
                <th className={classes.dayCol}>12:00</th>
                <th className={classes.dayCol}>15:00</th>
                <th className={classes.dayCol}>18:00</th>
                <th className={classes.dayCol}>21:00</th>
                */}
                            </tr>
                        </thead>

                        {vehicles.filter((car) => car.category.id === selectedCategory).map((vehicle) => (
                            <Fragment key={vehicle.id}>
                                <thead className={classes.schHeader}>
                                    <tr>
                                        <th className={classes.vehicleCol}>
                                            <Typography fontFamily="Monospaced">{vehicle.licensePlate}</Typography> {vehicle.name}
                                        </th>
                                        <th colSpan={8} />
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={1}><Box fontWeight="fontWeightBold"><FormattedMessage id="schedules_driver" /></Box></td>
                                        <td colSpan={4}><Box fontWeight="fontWeightBold"><FormattedMessage id="schedules_start" /> &#8594; <FormattedMessage id="schedules_end" /></Box></td>
                                        <td colSpan={4} />
                                    </tr>
                                    {schedules.filter((sch) => sch.licensePlate === vehicle.licensePlate).map((sch, schIndex) => (
                                        <tr key={sch.id}>
                                            <td colSpan={1}>{sch.driverName}</td>
                                            <td colSpan={8}>
                                                {formatSamedayHhMm(parseISO(sch.startTime))} &#8594; {formatSamedayHhMm(parseISO(sch.endTime))}
                    &nbsp;(<FormattedMessage id="schedules_total_abbrv" /> {formatHhMm(parseISO(sch.endTime), parseISO(sch.startTime))})
                    &nbsp;
                                                <IconButton
                                                    size="small"
                                                    aria-label={`delete schedule ${schIndex}`}
                                                    onClick={() => {
                                                        setDeletedScheduleId(sch.id)
                                                        setShowDeleteConfirmation(true)
                                                    }}
                                                >
                                                    <Delete data-test-id={`deleteSchedule${schIndex}`} />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        const hours = differenceInHours(new Date(sch.endTime), new Date(sch.startTime))
                                                        const totalMinutes = differenceInMinutes(new Date(sch.endTime), new Date(sch.startTime))
                                                        const minutes = Math.round(((totalMinutes / 60) % 1) * 60)
                                                        setShiftDetails(
                                                            {
                                                                ...shiftDetails,
                                                                id: sch.id,
                                                                driver: sch.driverId,
                                                                startTime: new Date(sch.startTime),
                                                                shiftHours: hours,
                                                                shiftMinutes: minutes,
                                                                selectedVehicleLicensePlate: sch.licensePlate,
                                                            },
                                                        )
                                                        openDialog()

                                                        // save by ID
                                                    }}
                                                >
                                                    <Edit data-test-id={`editSchedule${schIndex}`} />
                                                </IconButton>
                                            </td>
                                            {/* v */}
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan={9}>
                                            <Button
                                                onClick={() => {
                                                    let maxSchedule
                                                    if (schedules && schedules.length > 0) {
                                                        const carSchedules = schedules.filter((sch) => sch.licensePlate === vehicle.licensePlate)
                                                        if (carSchedules && carSchedules.length > 0) {
                                                            maxSchedule = carSchedules.reduce((prev, current) => {
                                                                return (prev.endTime > current.endTime) ? prev : current
                                                            })
                                                        }
                                                    }
                                                    let maxEndTime
                                                    if (!maxSchedule) {
                                                        maxEndTime = addHours(startOfDay(selectedDay), 6)
                                                    } else {
                                                        maxEndTime = parseISO(maxSchedule.endTime)
                                                    }
                                                    if (isValid(maxEndTime)) {
                                                        if (getDate(maxEndTime) !== getDate(selectedDay)) {
                                                            maxEndTime = setDate(maxEndTime, getDate(selectedDay))
                                                            maxEndTime = setMonth(maxEndTime, getMonth(selectedDay))
                                                            maxEndTime = setYear(maxEndTime, getYear(selectedDay))
                                                        }
                                                    }
                                                    setShiftDetails({ ...shiftDetails, selectedVehicleLicensePlate: vehicle.licensePlate, startTime: maxEndTime})
                                                    openDialog()
                                                }}
                                                startIcon={<AddCircle />}
                                            >
                                                <FormattedMessage id="schedules_add" />
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Fragment>

                        ))}
                    </table>
                </Grid>
            </Grid>

            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                open={showDeleteConfirmation}
            >
                <DialogTitle><FormattedMessage id="schedules_delete_confirmation_title" /></DialogTitle>
                <DialogContent dividers>
                    <FormattedMessage id="schedules_delete_description" />
                </DialogContent>
                <DialogActions>
                    <Button
                        data-test-id="cancelScheduleDelete"
                        aria-label="Cancel delete"
                        onClick={() => setShowDeleteConfirmation(false)}
                        color="secondary"
                    >
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        data-test-id="confirmScheduleDelete"
                        aria-label="Confirm delete"
                        onClick={deleteSchedule}
                    >
                        <FormattedMessage id="delete" />
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="xs"
                open={showDialog}
                onClose={closeDialog}
                aria-labelledby="form-dialog-title"
            >
                <form id="orgForm" onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title"><FormattedMessage id="schedules_schedule" /></DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <InputLabel id="vehicle-label">
                                    <FormattedMessage id="schedules_vehicle" />: {shiftDetails.selectedVehicleLicensePlate}
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel id="date-label">
                                    <FormattedMessage id="schedules_date" />: {format(selectedDay, 'd.M.yyyy')}
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel id="driver-select-label">
                                    <FormattedMessage id="schedules_driver" />
                                </InputLabel>
                                <Select
                                    required
                                    className={classes.formInput}
                                    id="driver"
                                    value={shiftDetails.driver}
                                    displayEmpty
                                    onChange={(e) => setShiftDetails({ ...shiftDetails, driver: e.target.value })}
                                >
                                    {drivers.map((driver) => {
                                        if (driver.fullName && driver.fullName.length) {
                                            return (
                                                <MenuItem value={driver.id} key={driver.id}>{driver.fullName}</MenuItem>
                                            )
                                        } else {
                                            return
                                        }
                                    })}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel id="starttime-select-label">
                                    <FormattedMessage id="schedules_starttime" />
                                </InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <InputLabel id="endtime-select-label">
                                    <FormattedMessage id="schedules_duration" /> &#8594; {format(addMinutes(addHours(shiftDetails.startTime, shiftDetails.shiftHours), shiftDetails.shiftMinutes), 'HH:mm')}
                                </InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                                <KeyboardTimePicker
                                    required
                                    margin="normal"
                                    id="time-picker"
                                    label={<FormattedMessage id="schedules_starttime" />}
                                    ampm={false}
                                    className={classes.formInput}
                                    value={shiftDetails.startTime}
                                    onChange={(newTime) => {
                                        if (isValid(newTime)) {
                                            let newStartTime = setDate(newTime, getDate(selectedDay))
                                            newStartTime = setMonth(newStartTime, getMonth(selectedDay))
                                            newStartTime = setYear(newStartTime, getYear(selectedDay))
                                            setShiftDetails({ ...shiftDetails, startTime: newStartTime })
                                        }
                                    }}
                                    autoOk
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.formInput}
                                    fullWidth
                                    id="shiftHoursInput"
                                    label={<FormattedMessage id="schedules_hours" />}
                                    value={shiftDetails.shiftHours}
                                    onChange={(e) => setShiftDetails({ ...shiftDetails, shiftHours: e.target.value })}
                                    type="number"
                                    helperText=""
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.formInput}
                                    fullWidth
                                    id="shiftMinutesInput"
                                    value={shiftDetails.shiftMinutes}
                                    onChange={(e) => setShiftDetails({ ...shiftDetails, shiftMinutes: e.target.value })}
                                    type="number"
                                    label={<FormattedMessage id="schedules_minutes" />}
                                    helperText=""
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                            </Grid>
                        </Grid>

                        <DialogActions>
                            <Button onClick={closeDialog} color="secondary"><FormattedMessage id="cancel" /></Button>
                            <Button type="submit" color="primary"><FormattedMessage id="save" /></Button>
                            {showSaveSpinner && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </DialogActions>

                    </DialogContent>
                </form>
            </Dialog>
        </div>
    )
}

export default VehicleSchedules
