import './NavMenu.css'
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { IntlContext } from '../utils/IntlProviderWrapper'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'

import {
    Menu, AppBar, Toolbar, IconButton, Drawer,
    List, ListItem, ListItemIcon,
    ListItemText, Divider, CssBaseline,
    Container, Grid, Typography, MenuItem, Badge
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import MenuBook from '@material-ui/icons/MenuBook'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import EuroIcon from '@material-ui/icons/Euro'
import People from '@material-ui/icons/People'
import Domain from '@material-ui/icons/Domain'
import Description from '@material-ui/icons/Description'
import Assignment from '@material-ui/icons/Assignment'
import DriverAssignmentIcon from '@material-ui/icons/AssignmentInd'
import Close from '@material-ui/icons/Close'
import GroupIcon from '@material-ui/icons/GroupWork'
import Category from '@material-ui/icons/Category'
import Schedule from '@material-ui/icons/Schedule'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/core/styles'
import RidesStatusContext from '../context/RidesStatusContext'
import logoUrl from '../images/logo.png'

const drawerWidth = 256

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    toolbar: {
        marginBottom: theme.spacing(3),
        ...theme.mixins.toolbar,
    },
    title: {
        marginLeft: theme.spacing(1),
        flexGrow: 1,
        color: '#fff',
        '&:hover': {
            color: '#fff',
            textDecoration: 'none',
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    profileInfo: {
        textAlign: 'right',
    },
    logo: {
        width: '120px',
        height: 'auto',
    },
    loginLogo: {
        width: '150px',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
    },
}))

const Layout = (props) => {
    const localeData = useContext(IntlContext)
    const [anchorEl, setAnchorEl] = useState(null)
    const { oidcUser } = React.useContext(AuthenticationContext)
    const { unapprovedRidesCount, unhandledRidesCount } = React.useContext(RidesStatusContext)
    const notificationTotalCount = unapprovedRidesCount + unhandledRidesCount
    const classes = useStyles()
    const [drawerOpen, setDrawerOpen] = useState(false)

    function toggleSidebar() {
        setDrawerOpen(!drawerOpen)
    }

    const handleProfileMenu = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleProfileMenuClose = () => {
        setAnchorEl(null)
    }

    const allowedRoles = (allowedRolesList) => allowedRolesList.includes(oidcUser?.profile?.role)

    function createMenuList() {
        return (
            <>
                {allowedRoles(['Organizer', 'Admin', 'Customer', 'CustomerAdmin']) && (
                    <ListItem button data-test-id="orders-link" component={Link} to="/orders" onClick={toggleSidebar}>
                        <ListItemIcon><MenuBook /></ListItemIcon>
                        <ListItemText><FormattedMessage id="sidebar_orders" /></ListItemText>
                    </ListItem>)}
                {allowedRoles(['Organizer', 'Admin', 'Driver']) && (
                    <>
                        <ListItem button data-test-id="rides-link" component={Link} to="/rides" onClick={toggleSidebar}>
                            <ListItemIcon>
                                <Badge badgeContent={unapprovedRidesCount} color="error">
                                    <DriverAssignmentIcon />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText><FormattedMessage id="sidebar_rides" /></ListItemText>
                        </ListItem>
                        <ListItem button data-test-id="myschedule-link" component={Link} to="/myschedule" onClick={toggleSidebar}>
                            <ListItemIcon><CalendarTodayIcon /></ListItemIcon>
                            <ListItemText><FormattedMessage id="sidebar_my_schedule" /></ListItemText>
                        </ListItem>
                    </>)}
                {allowedRoles(['Organizer', 'Admin']) && (
                    <>
                        <ListItem button data-test-id="organizer-link" component={Link} to="/organizer" onClick={toggleSidebar}>
                            <ListItemIcon>
                                <Badge badgeContent={unhandledRidesCount} color="error">
                                    <Assignment />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText><FormattedMessage id="sidebar_organizing" /></ListItemText>
                        </ListItem>
                    </>)}
                {allowedRoles(['Organizer', 'Admin']) && (
                    <>
                        <ListItem button data-test-id="vehicleschedules-link" component={Link} to="/vehicleschedules" onClick={toggleSidebar}>
                            <ListItemIcon><Schedule /></ListItemIcon>
                            <ListItemText><FormattedMessage id="sidebar_vehicle_schedules" /></ListItemText>
                        </ListItem>
                        <ListItem button data-test-id="vehicles-link" component={Link} to="/vehicles" onClick={toggleSidebar}>
                            <ListItemIcon><DirectionsCar /></ListItemIcon>
                            <ListItemText><FormattedMessage id="sidebar_vehicles" /></ListItemText>
                        </ListItem>
                    </>)}
                {allowedRoles(['Admin']) && (
                    <>
                        <ListItem button data-test-id="vehiclecategories-link" component={Link} to="/vehiclecategories" onClick={toggleSidebar}>
                            <ListItemIcon><Category /></ListItemIcon>
                            <ListItemText><FormattedMessage id="sidebar_vehicle_categories" /></ListItemText>
                        </ListItem>
                    </>)}
                {allowedRoles(['Organizer', 'Admin', 'CustomerAdmin']) && (
                    <>
                        <ListItem button data-test-id="users-link" component={Link} to="/users" onClick={toggleSidebar}>
                            <ListItemIcon><People /></ListItemIcon>
                            <ListItemText><FormattedMessage id="sidebar_users" /></ListItemText>
                        </ListItem>
                    </>)}
                {allowedRoles(['Organizer', 'Admin']) && (
                    <>
                        <ListItem button data-test-id="partners-link" component={Link} to="/partners" onClick={toggleSidebar}>
                            <ListItemIcon><GroupIcon /></ListItemIcon>
                            <ListItemText><FormattedMessage id="sidebar_partners" /></ListItemText>
                        </ListItem>
                        <ListItem button data-test-id="organizations-link" component={Link} to="/organizations" onClick={toggleSidebar}>
                            <ListItemIcon><Domain /></ListItemIcon>
                            <ListItemText><FormattedMessage id="sidebar_companies" /></ListItemText>
                        </ListItem>
                    </>)}
                {allowedRoles(['Admin']) && (
                <>
                    <ListItem button data-test-id="pricing-link" component={Link} to="/pricing" onClick={toggleSidebar}>
                        <ListItemIcon><EuroIcon /></ListItemIcon>
                        <ListItemText><FormattedMessage id="sidebar_pricing" /></ListItemText>
                    </ListItem>
                </>)}
            </>
        )
    }

    return (
        <Grid>
            <CssBaseline />
            <AppBar>
                <Toolbar>

                    <AuthenticationContext.Consumer>
                        {props => {
                            return (
                                <>
                                    {props.oidcUser ? (
                                        <>
                                            <IconButton edge="start" data-test-id="menu" aria-label="menu" color="secondary" onClick={toggleSidebar}>
                                                <Badge badgeContent={notificationTotalCount} color="error">
                                                    <MenuIcon />
                                                </Badge>
                                            </IconButton>
                                            <Link to="/" className={classes.title} color="inherit">
                                                <Typography variant="h6">
                                                    <img className={classes.logo} src={logoUrl} alt="apperclass-logo" />
                                                </Typography>
                                            </Link>
                                            <div className={classes.profileInfo}>
                                                <div>{props.oidcUser.profile.fullname}</div>
                                                <div>{props.oidcUser.profile.orgname}</div>
                                            </div>
                                            <IconButton onClick={handleProfileMenu} color="secondary">
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="profile-menu"
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                open={Boolean(anchorEl)}
                                                onClose={handleProfileMenuClose}>
                                                <MenuItem onClick={handleProfileMenuClose} component={Link} to="/profile/details"><FormattedMessage id="profile_profile" /></MenuItem>
                                                <MenuItem onClick={localeData.toggleFi}>Suomeksi</MenuItem>
                                                <MenuItem onClick={localeData.toggleEn}>In English</MenuItem>
                                                <MenuItem onClick={handleProfileMenuClose} component={Link} to="/logout"><FormattedMessage id="login_logout" /></MenuItem>
                                            </Menu>
                                        </>
                                    ) : (
                                            <Link to="/" className={classes.title} color="inherit">
                                                <Typography variant="h6" className={classes.title} text-align="center">
                                                    <img className={classes.loginLogo} src={logoUrl} alt="apperclass-logo" />
                                                </Typography>
                                            </Link>
                                        )
                                    }
                                </>
                            )
                        }}
                    </AuthenticationContext.Consumer>
                </Toolbar>
            </AppBar>
            <Drawer
                open={drawerOpen}
                variant="temporary"
                anchor="left"
                classes={{
                    paper: classes.drawerPaper
                }}
                ModalProps={{
                    keepMounted: true
                }}
                onClose={toggleSidebar}
            >
                <List>
                    <ListItem button onClick={toggleSidebar}>
                        <ListItemIcon><Close /></ListItemIcon>
                    </ListItem>
                    {createMenuList()}
                </List>
            </Drawer>
            <div className={classes.toolbar} />
            <Container maxWidth={false}>
                {props.children}
            </Container>
        </Grid>
    )
}

export default Layout
