import {
    Box, Button, CircularProgress, Dialog,
    DialogActions, DialogContent, DialogTitle,
    Grid, IconButton, makeStyles, TextField,
    Typography,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ConfirmationDialog from './ConfirmationDialog'
import { IntlContext } from '../utils/IntlProviderWrapper'

const useStyles = makeStyles((theme) => ({
    page: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
            left: '0px',
            minWidth: '320px',
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
    container: {
        backgroundColor: '#FFF',
        color: '#000',
        boxShadow: '0px 3px 6px #00000029',
        padding: '1em',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: '320px',
            margin: '0 !important',
        },
        [theme.breakpoints.up('sm')]: {
            width: '480px',
        },
        maxWidth: '640px',
    },
    errorRequired: {
        color: '#f00',
    },
    textAlignRight: {
        textAlign: 'right',
    },
}))

const CreditCardDetails = () => {
    const intl = useIntl()
    const classes = useStyles()
    const [cards, setCards] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const [showDeleteCardModal, setShowDeleteCardModal] = useState(false)
    const [showEditCardModal, setShowEditCardModal] = useState(false)
    const [cardIdToBeDeleted, setCardIdToBeDeleted] = useState(null)
    const currentLocale = useContext(IntlContext).locale
    const [cardName, setCardName] = useState('')
    const [cardId, setCardId] = useState('')

    const getCards = () => {
        setIsLoading(true)
        fetch('/api/Cards')
        .then((response) => {
            if (response.ok) {
                return response.json()
            }
            setIsLoading(false)
            enqueueSnackbar(<FormattedMessage id="cards_get_cards_error" />, { variant: 'error' })
            return []
        })
        .then((data) => {
            setIsLoading(false)
            setCards(data)
        })
        .catch(() => {
            enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: 'error' })
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getCards()
        const params = new URLSearchParams(window.location.search)
        if (params.get('errorKey')?.length > 0) {
            enqueueSnackbar(<FormattedMessage id="cards_add_new_card_error" />, { variant: 'error' })
        }
        if (params.get('showNewNotification')?.length > 0 &&
            params.get('showNewNotification') !== 'false'
        ) {
            enqueueSnackbar(<FormattedMessage id="cards_new_card_added" />, { variant: 'success' })
        }
    }, [])

    const deleteCard = (id) => {
        setIsLoading(true)
        fetch(`/api/Cards/${id}`, {
            method: 'DELETE',
        })
        .then((response) => {
            if (response.ok) {
                return
            }
            setIsLoading(false)
            throw Error()
        })
        .then(() => {
            setIsLoading(false)
            enqueueSnackbar(<FormattedMessage id="cards_delete_card_success" />, { variant: 'success' })
            getCards()
        })
        .catch(() => {
            enqueueSnackbar(<FormattedMessage id="cards_delete_card_error" />, { variant: 'error' })
            setIsLoading(false)
        })
    }

    const closeConfirmModal = () => {
        setShowDeleteCardModal(false)
    }

    const openConfirmModal = (id) => {
        setCardIdToBeDeleted(id)
        setShowDeleteCardModal(true)
    }

    const onNameSubmit = () => {
        fetch(`/api/cards/${cardId}?name=${cardName}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
        })
        .then((response) => {
            setShowEditCardModal(false)
            if (!response.ok) {
                throw Error()
            }
        })
        .then(() => {
            setIsLoading(false)
            getCards()
        })
        .catch(() => {
            enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: 'error' })
            setIsLoading(false)
        })
    }

    const showEditCardDialog = (id, name) => {
        console.log(id + ' ja ' + name)
        setCardId(id)
        setCardName(name)
        setShowEditCardModal(true)
    }

    const confirmDeleteCard = () => {
        setShowDeleteCardModal(false)
        deleteCard(cardIdToBeDeleted)
    }

    return (
        <div className={classes.page}>
            <Grid container spacing={4} justify="center" direction="column" className={classes.container}>
                <Grid item xs={12}>
                    <Box width={300} />
                    <Typography variant="h5"><FormattedMessage id="profile_card_details" /></Typography>
                    <Box mt={4}>
                        {isLoading ? <CircularProgress className={classes.loadingSpinner} color="secondary" /> :
                            <Grid container direction="column">

                                <table>
                                    <thead>
                                        <tr>
                                            <th aria-label="Name"><FormattedMessage id="name" /></th>
                                            <th aria-label="Pan"><FormattedMessage id="cards_maskedPan" /></th>
                                            <th aria-label="Actions" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {cards && cards?.map((card) => (
                                        <tr key={card.id}>
                                            <td>
                                                {card.name}
                                                &nbsp;
                                                <IconButton
                                                    onClick={() => showEditCardDialog(card.id, card.name)}
                                                    title={intl.formatMessage({ id: 'cards_edit_card_name' })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </td>
                                            <td>
                                                {card.maskedPAN}
                                                &nbsp;
                                                (exp. {card.expiryDate})
                                            </td>
                                            <td>
                                                <IconButton onClick={() => openConfirmModal(card.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Grid>}
                        <Box mt={4}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => {
                                    window.location.href = `/api/cards/new?locale=${currentLocale}`
                                }}
                                data-test-id="add-new-card-button"
                                name="add-new-card"
                                aria-label="Add new credit card"
                            >
                                <Link to={`/api/Cards/new?locale=${currentLocale}`}>
                                    <FormattedMessage id="cards_add_new_card" />
                                </Link>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                fullWidth
                maxWidth="md"
                open={showDeleteCardModal}
                onClose={closeConfirmModal}
            >
                <ConfirmationDialog
                    onCloseClick={closeConfirmModal}
                    onConfirmClick={confirmDeleteCard}
                    modalConfirmButtonText={<FormattedMessage id="cards_confirm_card_delete" />}
                    headerText={<FormattedMessage id="cards_delete_card_modal_header_text" />}
                    typography1={<FormattedMessage id="cards_delete_card_modal_typo1" />}
                />
            </Dialog>

            <Dialog
                open={showEditCardModal}
                onClose={() => setShowEditCardModal(false)}
            >
                <DialogTitle>
                    <FormattedMessage id="cards_rename_title" />
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="cardId"
                        name="cardId"
                        value={cardId}
                        hidden
                    />

                    <TextField
                        aria-label={<FormattedMessage id="cards_name" />}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            maxLength: 38,
                        }}
                        fullWidth
                        id="cardName"
                        name="cardName"
                        value={cardName}
                        onChange={(e) => setCardName(e.target.value)}
                        label={<FormattedMessage id="cards_name" />}
                        helperText=""                        
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowEditCardModal(false)} color="secondary">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button onClick={() => onNameSubmit()} color="primary"><FormattedMessage id="save" /></Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

CreditCardDetails.propTypes = {

}

export default CreditCardDetails
