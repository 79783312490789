import React, { useCallback, useState, useEffect, useContext } from 'react';
import { TextField, Grid, Button, Box, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, FormControlLabel, RadioGroup, Radio, Typography } from '@material-ui/core';
import OrganizationsTable from './OrganizationsTable';
import OrganizationsDropdown from './OrganizationDropdown';
import uuid from 'uuid/v4';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { AuthenticationContext } from '@axa-fr/react-oidc-context'

export const Organizations = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false)
  const [orgs, setOrgs] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [orderOwner, setOrderOwner] = useState();
  const { oidcUser } = useContext(AuthenticationContext);
  const [selectedOrgType, setSelectedOrgType] = useState(0);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    const org = {
      id: uuid(),
      name: e.target.orgName.value.trim(),
      type: parseInt(e.target.orgType.value),      
    }
    if(selectedOrgType===0) {
      org.orderOwner = {
        id: orderOwner?.id || oidcUser?.profile?.org
      }
    }

    fetch("/api/Organizations",{
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json"
      },
      body: JSON.stringify(org)
    }).then(response => {
      if(!response.ok) {
        throw Error(response.status);
      }
      enqueueSnackbar(<FormattedMessage id="save_succeed" />, {variant: "success"});
      closeDialog();
      response.json().then(data=>{
        orgs.push(org);
        setOrgs(orgs);
        loadData()  
      })
    }).catch(error => {
      console.log(error);
      enqueueSnackbar(<FormattedMessage id="org_save_failed" />, {variant: "error"});
    });
    
  }

  const openDialog = () => {
    setDialogOpen(!dialogOpen);
  }

  const closeDialog = () => {
    setDialogOpen(!dialogOpen);
  }  

  const loadData = useCallback(() => {
    fetch("/api/organizations")
    .then(results=> {
      if(!results.ok) {
        throw Error(results.status);
      }
      if(results.redirected) {
        throw Error('Pääsy estetty');
      }
      return results.json();
    }).then(data => {
      setOrgs(data);
      const sp = data.filter(org=> parseInt(org.type)===1);
      setServiceProviders(sp);
    }).catch(error => {
      enqueueSnackbar(`Yritysten lataaminen epäonnistui (${error.message})`, {variant: "error"});
    });
  }, [enqueueSnackbar])

  useEffect(() => {
      loadData()
  }, [loadData])

  function updateOrg(org) {
    let owner = null;
    if(org.orderOwner?.name?.length>0) {
      owner = serviceProviders.find(sp=>sp.name===org.orderOwner.name);
    }

    fetch(`/api/organizations/${org.id}`,{
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        id: org.id,
        name: org.name.trim(),
        type: parseInt(org.type),
        orderOwner: owner,
      })
    }).then(response => {
      if(!response.ok) {
        throw Error(response.status);
      }
      loadData();
      enqueueSnackbar(<FormattedMessage id="save_succeed" />, {variant: "success"});
    }).catch(error => {
      console.log(error);
      loadData();
      enqueueSnackbar(<FormattedMessage id="org_save_failed" />, {variant: "error"});
    });
  }

  function removeOrg(org) {
    let newOrgs = orgs;
    const index = newOrgs.indexOf(org);
    newOrgs.splice(index, 1);
    setOrgs(newOrgs);
    loadData();
  }

  const handleOrderOwner = (org) => {
    setOrderOwner(org);
  }

  const handleNewOrgTypeChange = (e) => {
    setSelectedOrgType(parseInt(e.target.value));
  }

    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3"><FormattedMessage variant="" id="org_title"/></Typography>
            <Box m={4} />

            <OrganizationsTable 
              orgs={orgs}
              updateOrg={updateOrg}
              removeOrg={removeOrg}
              serviceProviders={serviceProviders}
               />

            <Box m={4} />
            <Grid item xs={12}>
              <Button 
                  id='addOrg' 
                  variant='outlined' 
                  onClick={openDialog}
                  data-test-id="org-add-new-button"
                  >{<FormattedMessage id="org_add_new"/>}</Button>
            </Grid>

          </Grid>            
        </Grid>
        <Dialog 
            open={dialogOpen} 
            onClose={closeDialog} 
            maxWidth='md'
            aria-labelledby='form-dialog-title'>
          <form id="orgForm" onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title"><FormattedMessage id="org_company" /></DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField 
                    required 
                    InputLabelProps={{shrink:true}}
                    fullWidth={true} 
                    id='orgName' 
                    label={<FormattedMessage id="org_company_name" />}
                    defaultValue=''
                    helperText=''>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Box m={4}/>
                  <DialogContentText>
                    <FormattedMessage id="org_type_description"/>
                  </DialogContentText>
                  <RadioGroup 
                      name="orgType" 
                      id="orgType" 
                      label={<FormattedMessage id="org_company_type" />} 
                      onChange={handleNewOrgTypeChange}
                      defaultValue="0">
                    <FormControlLabel value="0" control={<Radio />} label={<FormattedMessage id="org_type_customer" />} />
                    <FormControlLabel disabled={oidcUser?.profile?.role === "Organizer"} value="1" control={<Radio />} label={<FormattedMessage id="org_type_operator" />} />
                  </RadioGroup>
                </Grid>
                { 
                  // Show order owner only for customer type orgs
                  selectedOrgType===0 &&
                  <Grid item xs={12}>                     
                    <OrganizationsDropdown
                      id='orderOwner'
                      orgType="ServiceProvider"
                      customLabel="org_customer_order_owner"
                      defaultOrg={oidcUser?.profile?.org}
                      readOnly={oidcUser?.profile?.role === "Organizer"}
                      handleOrgChange={handleOrderOwner} />
                  </Grid>
                }
              </Grid>

              <DialogActions>
                <Button onClick={closeDialog} color="secondary"><FormattedMessage id="cancel"/></Button>
                <Button type="submit" data-test-id="save-button" color="primary"><FormattedMessage id="save"/></Button>
              </DialogActions>


            </DialogContent>
          </form>
        </Dialog>
      </div>
    );
  
}
