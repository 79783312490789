import React from 'react'
import {
  makeStyles, List, ListItem, ListItemText, Box,
  ListItemSecondaryAction, IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import AddIcon from '@material-ui/icons/Add'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles({
  container: {
    backgroundColor: '#FFF',
    color: '#000',
    borderRadius: '2px',
    minWidth: '320px',
    maxWidth: '720px',
    margin: '0 !important',
  },
  groupItem: {
    backgroundColor: '#F8F6F1',
    margin: '2px',
    cursor: 'pointer',
  },
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})


const PartnersList = (props) => {
  const classes = useStyles()

  const partnerGroupItems = props.partners?.map((partnerGroup, i) =>
    <div key={partnerGroup.id}>
      <ListItem
        data-test-id={'partnerGroup' + partnerGroup.name}
        key={partnerGroup.id}
        onClick={() => props.handleEdit(partnerGroup)}
        className={classes.groupItem}
        >
        <ListItemText 
          className={classes.overflow}
          primary={partnerGroup.name}
          primaryTypographyProps={{
            variant:'h6',            
          }}
          secondary={partnerGroup.partnersNames}
          secondaryTypographyProps={{
            color:'primary',
          }}
          />
        <ListItemSecondaryAction>
          <IconButton
              onClick={() => props.handleDelete(partnerGroup)}
              id={'deleteGroup' + partnerGroup.name}
              edge="end"
              aria-label="edit">
            <DeleteIcon color="secondary" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  )

  return (
    <List>
      {(!props.partners || props.partners.length == 0) && <FormattedMessage id="partners_empty_list" />}
      {partnerGroupItems}
      <ListItem 
        id="addPartnerGroupButton"
        onClick={() => props.handleNew()}
        className={classes.groupItem}
        >
          <Box m="auto">
            <AddIcon color="secondary" />
          </Box>
      </ListItem>
    </List>
  )
}

PartnersList.propTypes = {
  partners: PropTypes.array,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleNew: PropTypes.func,
}

export default PartnersList