import React, { useState, useEffect } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Grid, Button, CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

export default function CustomLogin(props) {
    const [loggedOut, setLoggedOut] = useState(false)
    const { logout } = useReactOidc()

    useEffect(() => {
        fetch('/api/users/logout').then((response) => {
            console.log('logged out')
            if (response.ok) {
                console.log('triggering logout')
                logout()
                setLoggedOut(true)
            }
        })
    }, [])

    return (
        <>
            <Grid
                hidden={!loggedOut}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={3}>
                    <Button href="/login">
                        <CircularProgress />
                    </Button>
                </Grid>
            </Grid>
            <Grid
                hidden={loggedOut}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={3}>
                    <FormattedMessage id="login_logged_out" />
                    <Button href="/">
                        <FormattedMessage id="login_login" />
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}
