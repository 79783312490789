import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import history from '../history'

let reactPlugin = null
let appInsights = null

const createTelemetryService = () => {
    const initialize = (instrumentationKey, browserHistory) => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service')
        }
        if (!instrumentationKey) {
            throw new Error(
                'Instrumentation key not provided in ./src/telemetry-provider.jsx',
            )
        }

        reactPlugin = new ReactPlugin()

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history,
                    },
                },
            },
        })

        appInsights.loadAppInsights()
    }

    return { reactPlugin, appInsights, initialize }
}

export const ai = createTelemetryService()
export const getAppInsights = () => appInsights
export const trackException = (error) => appInsights.trackException({
        error: new Error(error),
        severityLevel: SeverityLevel.Error,
    })
