import React, { forwardRef } from 'react'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FileCopy from '@material-ui/icons/FileCopy'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { FormattedMessage } from 'react-intl'

export const tableLocalization = {
  pagination: {
    labelDisplayedRows: '{from}-{to} / {count}',
    labelRowsSelect: 'riviä',
    firstAriaLabel: <FormattedMessage id="table_first_page" />,
    firstTooltip: <FormattedMessage id="table_first_page" />,
    lastAriaLabel: <FormattedMessage id="table_last_page" />,
    lastTooltip: <FormattedMessage id="table_last_page" />,
    nextAriaLabel: <FormattedMessage id="table_next_page" />,
    nextTooltip: <FormattedMessage id="table_next_page" />,
    previousAriaLabel: <FormattedMessage id="table_previous_page" />,
    previousTooltip: <FormattedMessage id="table_previous_page" />,
  },
  header: {
    actions: <FormattedMessage id="table_actions" />,
  },
  body: {
    emptyDataSourceMessage: <FormattedMessage id="table_empty" />,
    filterRow: {
      filterTooltip: <FormattedMessage id="table_filter" />,
    },
    editRow: {
      cancelTooltip: <FormattedMessage id="cancel" />,
      saveTooltip: <FormattedMessage id="save" />,
      deleteText: <FormattedMessage id="table_delete_verify" />,
    },
    editTooltip: <FormattedMessage id="edit" />,
    deleteTooltip: <FormattedMessage id="delete" />,
    addTooltip: <FormattedMessage id="add" />,
  },
  toolbar: {
    searchTooltip: <FormattedMessage id="search" />,
    // FormattedMessage doesn't work on this field
    searchPlaceholder: 'search',
  },
}

export const tableIcons = {
  /* eslint-disable react/display-name */
  Add: forwardRef((props, ref) => <AddBox {...props}  ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Duplicate: forwardRef((props, ref) => <FileCopy data-test-id="duplicate-row-button" {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline data-test-id="delete-row-button" {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit data-test-id="edit-row-button" {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt data-test-id="save-delete-button" {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage data-test-id="last-page-button" {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search data-test-id="search-icon" {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  /* eslint-enable react/display-name */
}
