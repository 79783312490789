import './custom.css'
import React from 'react'
import {
    Router, Route, Switch, Redirect,
} from 'react-router-dom'
import {
    AuthenticationProvider,
    oidcLog,
    OidcSecure,
} from '@axa-fr/react-oidc-context'
import { Grid, CircularProgress, Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'

import Layout from './components/Layout'
import Home from './components/Home'
import CustomLogin from './components/CustomLogin'
import CustomLogout from './components/CustomLogout'
import ForgotPassword from './components/ForgotPassword'
import { UserPasswordReset } from './components/UserPasswordReset'
import UserRegistration from './components/UserRegistration'
import UsagePolicy from './components/UsagePolicy'

import Rides from './components/Rides/Rides'
import Orders from './components/Orders'
import { Partners } from './components/Partners/Partners'
import { Vehicles } from './components/Vehicles'
import { VehicleCategories } from './components/VehicleCategories'
import VehicleSchedules from './components/VehicleSchedules'

import MySchedule from './components/MySchedule'
import Organizer from './components/Organizer/Organizer'
import { Organizations } from './components/Organizations'
import Users from './components/Users'

import history from './utils/history'
import oidcConfiguration from './components/auth/OidcConfiguration'
import VehicleCategoriesProvider from './context/VehicleCategoriesProvider'
import { TravelInfo } from './components/Passenger/TravelInfo'
import RideStatusProvider from './context/RidesStatusProvider'
import PartnerGroupsProvider from './context/PartnerGroupsProvider'
import ApplicationInsights from './utils/ApplicationInsights/ApplicationInsights'
import PricingComponents from './components/Pricing/PricingComponents'
import UserProfileNavigation from './components/UserProfileNavigation'

const App = () => {
    const loadingComponent = () => (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <CircularProgress />
            </Grid>
        </Grid>
    )

    const sessionExpiredComponent = () => (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={4}>
                <FormattedMessage id="login_session_expired" />
            </Grid>
            <Grid item xs={4}>
                <Button href="/logout" variant="outlined">
                    <FormattedMessage id="login_refresh_session" />
                </Button>
            </Grid>
        </Grid>
    )

    const loginComponent = () => (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <Button href="/">
                    <FormattedMessage id="login_login" />
                </Button>
            </Grid>
        </Grid>
    )

    return (
        <Router history={history}>
            <ApplicationInsights>
                <AuthenticationProvider
                    authenticating={() => loadingComponent()}
                    notAuthenticated={() => loginComponent()}
                    sessionLostComponent={() => sessionExpiredComponent()}
                    callbackComponentOverride={loadingComponent}
                    isEnabled
                    configuration={oidcConfiguration}
                    loggerLevel={oidcLog.WARN}
                >
                    <VehicleCategoriesProvider>
                        <RideStatusProvider>
                            <PartnerGroupsProvider>
                                <Layout>
                                    <Switch>
                                        <Route
                                            exact
                                            path="/home"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass</title>
                                                </Helmet>
                                                <Home />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/login"
                                        >
                                            <Helmet>
                                                <title>ApperClass</title>
                                            </Helmet>
                                            <CustomLogin
                                                history={history}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/logout"
                                        >
                                            <CustomLogout
                                                history={history}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/ride/:id"
                                            render={(renderProps) =>
                                                <>
                                                    <Helmet>
                                                        <title>ApperClass | Ride Details</title>
                                                    </Helmet>
                                                    <TravelInfo {...renderProps} />
                                                </>
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/forgot-password"
                                        >
                                            <Helmet>
                                                <title>ApperClass | Forgot Password</title>
                                            </Helmet>
                                            <ForgotPassword
                                                history={history}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/reset-password"
                                            component={UserPasswordReset}
                                        >
                                            <UserPasswordReset
                                                history={history}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/profile/details"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Profile</title>
                                                </Helmet>
                                                <UserProfileNavigation />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/profile/cards"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Cards</title>
                                                </Helmet>
                                                <UserProfileNavigation />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/profile/change-password"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Change Password</title>
                                                </Helmet>
                                                <UserProfileNavigation />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/register"
                                        >
                                            <Helmet>
                                                <title>ApperClass | Registration</title>
                                            </Helmet>
                                            <UserRegistration
                                                history={history}
                                            />
                                        </Route>
                                        <Route
                                            exact
                                            path="/usagepolicy"
                                        >
                                            <Helmet>
                                                <title>ApperClass | Usage policy</title>
                                            </Helmet>
                                            <UsagePolicy />
                                        </Route>
                                        <Route
                                            exact
                                            path="/orders"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Orders</title>
                                                </Helmet>
                                                <Orders />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/vehicles"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Vehicles</title>
                                                </Helmet>
                                                <Vehicles />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/vehiclecategories"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Categories</title>
                                                </Helmet>
                                                <VehicleCategories />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/vehicleschedules"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Schedules</title>
                                                </Helmet>
                                                <VehicleSchedules />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/myschedule"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | My Schedule</title>
                                                </Helmet>
                                                <MySchedule />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/organizations"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Organizations</title>
                                                </Helmet>
                                                <Organizations />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/organizer"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Organizer</title>
                                                </Helmet>
                                                <Organizer />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/partners"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Partners</title>
                                                </Helmet>
                                                <Partners />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/rides"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Rides</title>
                                                </Helmet>
                                                <Rides />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/users"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Users</title>
                                                </Helmet>
                                                <Users />
                                            </OidcSecure>
                                        </Route>
                                        <Route
                                            exact
                                            path="/pricing"
                                        >
                                            <OidcSecure>
                                                <Helmet>
                                                    <title>ApperClass | Pricing</title>
                                                </Helmet>
                                                <PricingComponents />
                                            </OidcSecure>
                                        </Route>
                                        <Redirect
                                            exact
                                            from="/"
                                            to="/home"
                                        />
                                        <Redirect
                                            exact
                                            from="/authentication/login-callback"
                                            to="/home"
                                        />
                                        <Redirect
                                            exact
                                            from="/authentication/logout-callback"
                                            to="/home"
                                        />
                                        <Redirect
                                            exact
                                            from="/authentication"
                                            to="/home"
                                        />
                                    </Switch>
                                </Layout>
                            </PartnerGroupsProvider>
                        </RideStatusProvider>
                    </VehicleCategoriesProvider>
                </AuthenticationProvider>
            </ApplicationInsights>
        </Router>
    )
}

export default App
