import React from 'react'
import MaterialTable from 'material-table'
import { FormattedMessage, useIntl } from 'react-intl'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PropTypes from 'prop-types'
import { tableLocalization, tableIcons } from '../TableConfig'

const PriceListsTable = (props) => {
    const intl = useIntl()

    const {
        priceLists,
        addPriceList,
        updatePriceList,
        deletePriceList,
        customers,
        serviceProviders,
        duplicatePriceList,
    } = props

    return (
        <div>
            <MaterialTable
                icons={tableIcons}
                localization={tableLocalization}
                columns={[
                    { title: intl.formatMessage({ id: 'name' }), field: 'name' },
                    { title: intl.formatMessage({ id: 'pricing_start_date' }), field: 'startDate', type: 'date' },
                    { title: intl.formatMessage({ id: 'pricing_end_date' }), field: 'endDate', type: 'date' },
                    { title: intl.formatMessage({ id: 'pricing_vat_percent' }), field: 'vatPercent', type: 'numeric' },
                    { title: intl.formatMessage({ id: 'pricing_service_provider' }), field: 'serviceProvider.id', lookup: serviceProviders },
                    { title: intl.formatMessage({ id: 'pricing_customer' }), field: 'customer.id', lookup: customers },
                ]}
                options={{
                    pageSize: 5,
                    pageSizeOptions: [5, 10],
                    selection: true,
                }}
                data={priceLists}
                title={<FormattedMessage id="pricing_lists" />}
                editable={{
                    onRowAdd: (newData) => new Promise((resolve) => {
                        setTimeout(() => {
                            addPriceList(newData)
                            resolve()
                        }, 100)
                    }),
                    onRowUpdate: (newData) => new Promise((resolve) => {
                        setTimeout(() => {
                            updatePriceList(newData)
                            resolve()
                        }, 100)
                    }),
                    onRowDelete: (oldData) => new Promise((resolve) => {
                        setTimeout(() => {
                            deletePriceList(oldData)
                            resolve()
                        }, 100)
                    }),
                }}
                actions={[
                    {
                        tooltip: intl.formatMessage({ id: 'duplicate' }),
                        icon: () => <FileCopyIcon />,
                        onClick: (event, data) => {
                            duplicatePriceList(data)
                        },
                    },
                ]}
            />
        </div>
    )
}

PriceListsTable.propTypes = {
    priceLists: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        vatPercent: PropTypes.number.isRequired,
    })).isRequired,
    customers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    serviceProviders: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    addPriceList: PropTypes.func.isRequired,
    updatePriceList: PropTypes.func.isRequired,
    deletePriceList: PropTypes.func.isRequired,
    duplicatePriceList: PropTypes.func.isRequired,
}

export default PriceListsTable
