import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import vehicleCategoriesContext from './VehicleCategoriesContext'

const VehicleCategoriesProvider = (props) => {
    const [categories, setCategories] = useState([])
    const [categoryNameLookup, setCategoryNameLookup] = useState({})
    const { oidcUser } = React.useContext(AuthenticationContext)

    const loadCategories = () => {
        fetch('/api/vehiclecategories')
            .then((results) => {
                if (!results.ok) {
                    throw Error(results.status)
                }
                return results.json()
            }).then((data) => {
                setCategories(data)
                const nameLookup = {}
                data.map((item) => {
                    nameLookup[item.id] = item.name
                })
                setCategoryNameLookup(nameLookup)
            }).catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (oidcUser?.access_token?.length > 0) {
            loadCategories()
        }
    }, [oidcUser])

    return (
        <vehicleCategoriesContext.Provider value={{ categories, categoryNameLookup }}>
            {props.children}
        </vehicleCategoriesContext.Provider>
    )
}

VehicleCategoriesProvider.propTypes = {
    children: PropTypes.node,
}

export default VehicleCategoriesProvider
