import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Dialog, DialogTitle, DialogContent, Grid, 
  TextField, DialogActions, Button, Checkbox, 
  List, ListItemText, ListItem, ListItemIcon } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';

const PartnerDialog = (props) => {
  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    if(props.savedPartnerList?.partners) {
      // Set default values to checkboxes if editing existing list
      setChecked(props.savedPartnerList.partners);
    } else {
      setChecked([]);
    }
  }, [props.savedPartnerList]);

  const handleSaveSubmit = (e) => {
    e.preventDefault();
    props.handleSave(e.target.groupName.value, checked);
  }

  return (
    <Dialog 
      open={props.open}
      onClose={props.handleClose}
    >
      <form id="partnerGroupForm" onSubmit={handleSaveSubmit}
      
      >
        <DialogTitle>
          <FormattedMessage id="partners_list_title" />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item xs={12}>
              <TextField
                label={<FormattedMessage id="partners_list_name" />}
                id="groupName"
                InputLabelProps={{shrink:true}} 
                defaultValue={props.savedPartnerList?.name}
                inputProps={{
                  maxLength:64
                }}
                required
                  />
            </Grid>
            <Grid item xs={12}>
              <List>
              {props.serviceProviders.map((serviceProvider, index) => {
                return(
                  <ListItem 
                      key={index} 
                      dense 
                      button 
                      onClick={handleToggle(serviceProvider.id)}
                      id={"partner" + index}>
                    <ListItemIcon>
                      <Checkbox 
                        name={`selectedServiceProvider${index}`}
                        value={serviceProvider.id}
                        checked={checked.indexOf(serviceProvider.id) !== -1}
                        tabIndex={-1}
                        />
                    </ListItemIcon>
                    <ListItemText primary={serviceProvider.name} />
                  </ListItem>
                );
              })}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" id="savePartnerGroupButton">
            <FormattedMessage id="save"/>
          </Button>
          <Button onClick={props.handleClose} id="cancelPartnerGroupButton">
            <FormattedMessage id="cancel" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

}

PartnerDialog.propTypes = {
  savedPartnerList: PropTypes.object,
  serviceProviders: PropTypes.array,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
}

export default PartnerDialog;