import React, { useState, useEffect } from 'react'
import {
  Button, makeStyles, Grid,
  Typography, TextField, InputAdornment,
  DialogTitle, Dialog, DialogContent, DialogActions, Box,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import Lock from '@material-ui/icons/Lock'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  page: {
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      margin: '0 !important',
      left: '0px',
      minWidth: '320px',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
    top: '60px',
  },
  container: {
      backgroundColor: '#FFF',
      color: '#000',
      borderRadius: '2px',
      boxShadow: '0px 3px 6px #00000029',
      padding: '1em',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        minWidth: '320px',
        margin: '0 !important',
      },
      [theme.breakpoints.up('sm')]: {
        width: '400px',
      },
      maxWidth: '640px',
  },
  errorRequired: {
    color: '#f00',
  },
  textAlignRight: {
      textAlign: 'right',
  },
}))

export const UserPasswordReset = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [redirectDialogOpen, setRedirectDialogOpen] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    // TODO: Validate that invitation is valid before user starts to fill out the form
  })

  const onSubmit = (data, evt) => {
    evt.preventDefault()

    if (data.password !== data.passwordCheck) {
      enqueueSnackbar(<FormattedMessage id="login_password_mismatch" />, { variant: 'warning' })
      return
    }

    const params = new URLSearchParams(window.location.search)
    const passwordResetToken = params.get('resetPassword')

    const registration = {
      password: data.password,
      passwordResetToken,
    }

    fetch('/api/users/reset-password', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(registration),
    }).then((response) => {
      if (response.ok) {
        setRedirectDialogOpen(true)
        enqueueSnackbar(<FormattedMessage id="login_reset_password_changed" />, { variant: 'success' })
      } else {
        enqueueSnackbar(<FormattedMessage id="login_reset_expired" />, { variant: 'error' })
      }
    }).catch(() => {
      // HTTP 302 redirect causes error with fetch eventhough it isn't
      // enqueueSnackbar(`Virhe rekisteröinnissä`, {variant: "error"});
    })
  }

  const redirectToLogin = () => {
    props.history.push('/')
  }

  return (
    <div className={classes.page}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4} justify="center" direction="column" className={classes.container}>
          <Grid item xs={12}>
            <Box width={300} />
            <Typography variant="h5"><FormattedMessage id="login_reset_title" /></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
                <FormattedMessage id="login_reset_instructions" />
                &nbsp;
                <FormattedMessage id="reg_password_complexity" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
                {...register('password', {
                  required: true,
                  pattern: {
                    value: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/i,
                    message: <FormattedMessage id="reg_password_complexity" />,
                  },
                })}
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="password"
                name="password"
                type="password"
                label={<FormattedMessage id="login_reset_new_password" />}
                error={Boolean(errors.password)}
                helperText={errors.password && errors.password.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="secondary" />
                    </InputAdornment>
                  ),
                }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                {...register('passwordCheck', { required: true })}
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="passwordCheck"
                name="passwordCheck"
                type="password"
                label={<FormattedMessage id="login_reset_new_password_confirmation" />}
                error={Boolean(errors.passwordCheck)}
                helperText={errors.passwordCheck && errors.passwordCheck.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="secondary" />
                    </InputAdornment>
                  ),
                }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
                type="submit"
                fullWidth
                id="save"
                label="Aseta"
                variant="contained"
            >
                    <FormattedMessage id="login_reset_set_password" />
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={redirectDialogOpen}
        onClose={redirectToLogin}
      >
        <DialogTitle><FormattedMessage id="login_reset_password_changed" /></DialogTitle>
        <DialogContent><FormattedMessage id="login_reset_password_login" /></DialogContent>
        <DialogActions>
          <Button onClick={redirectToLogin}>OK</Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default UserPasswordReset
