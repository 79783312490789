import React, { useState, useEffect } from 'react'
import {
    Button, makeStyles, Grid, Typography,
    TextField, InputAdornment, Box, InputLabel,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl'
import { FormHelperText } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle'
import Phone from '@material-ui/icons/Phone'

const useStyles = makeStyles((theme) => ({
    page: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
            left: '0px',
            minWidth: '320px',
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
    container: {
        backgroundColor: '#FFF',
        color: '#000',
        borderRadius: '2px',
        boxShadow: '0px 3px 6px #00000029',
        padding: '1em',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: '320px',
            margin: '0 !important',
        },
        [theme.breakpoints.up('sm')]: {
            width: '480px',
        },
        maxWidth: '640px',
    },
    errorRequired: {
        color: '#f00',
    },
    textAlignRight: {
        textAlign: 'right',
    },
}))

export const UserProfile = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { handleSubmit, formState: { errors }, control, setValue } = useForm({defaultValues: {fullName: '', phone: ''}})
    const [profile, setProfile] = useState({ email: '', fullName: '', phone: '' })
    const classes = useStyles()
    const [email, setEmail] = useState('')

    const loadProfile = () => {
        fetch('/api/UserProfile', {
            method: 'GET',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.status)
            }
            return response.json()
        }).then((data) => {
            setValue('fullName', data.fullName)
            setValue('phone', data.phone)
            setEmail(data.email)
            setProfile(data)
        }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="error_occurred" />, { variant: "error" })
        })
    }

    useEffect(() => {
        loadProfile()
    }, [])

    const onProfileSubmit = (data, evt) => {
        evt.preventDefault()
        let newProfile = {
            fullName: data.fullName,
            phone: data.phone,
        }

        fetch('/api/UserProfile', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(newProfile),
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="save_succeed" />, { variant: 'success' })
        }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: 'error' })
        })
    }

    return (
        <div className={classes.page}>
            <form onSubmit={handleSubmit(onProfileSubmit)}>
                <Grid container spacing={4} justify="center" direction="column" className={classes.container}>
                    <Grid item xs={12}>
                        <Box width={300} />
                        <Typography variant="h5"><FormattedMessage id="profile_profile" /></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink><FormattedMessage id="profile_username" /></InputLabel>
                        <Typography>{email}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            rules={{ required: true }}
                            name="fullName"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    id="fullName"
                                    name="fullName"
                                    label={<FormattedMessage id="profile_fullname" />}
                                    value={value}
                                    onChange={onChange}
                                    error={Boolean(errors.fullName)}
                                    helperText={errors?.fullName && errors?.fullName?.type === 'required' && <FormattedMessage id="form_input_required" />}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle color="secondary" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            rules={{
                                required: true, pattern: {
                                    value: /^\+[1-9]{1}[0-9]{3,14}$/i,
                                    message: <FormattedMessage id="reg_phone_error" />,
                                },
                            }}
                            name="phone"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={value}
                                    onChange={onChange}
                                    id="phone"
                                    name="phone"
                                    label={<FormattedMessage id="profile_phone" />}
                                    error={Boolean(errors.phone)}
                                    helperText={errors?.phone && errors?.phone?.type === 'required' ? <FormattedMessage id="form_input_required" /> : errors?.phone?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Phone color="secondary" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            id="save"
                            label={<FormattedMessage id="profile_save" />}
                            variant="contained"
                        >
                            <FormattedMessage id="save" />
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default UserProfile
