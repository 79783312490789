import React from 'react'
import MaterialTable from 'material-table'
import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { tableLocalization, tableIcons } from '../TableConfig'

const HolidaysTable = (props) => {
    const intl = useIntl()

    const {
        holidays,
        addHoliday,
        updateHoliday,
        deleteHoliday,
    } = props

    return (
        <div>
            <MaterialTable
                icons={tableIcons}
                localization={tableLocalization}
                columns={[
                    { title: intl.formatMessage({ id: 'pricing_holiday_name_fin' }), field: 'nameFin', initialEditValue: '' },
                    { title: intl.formatMessage({ id: 'pricing_holiday_name_eng' }), field: 'nameEng', initialEditValue: '' },
                    { title: intl.formatMessage({ id: 'pricing_holiday_start_date' }), field: 'date', type: 'date' },
                ]}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 50, 100],
                }}
                data={holidays}
                title={<FormattedMessage id="pricing_holidays_title" />}
                editable={{
                    onRowUpdate: (newData, oldData) => new Promise((resolve) => {
                            setTimeout(() => {
                                updateHoliday(newData)
                                resolve()
                            }, 100)
                        }),
                    onRowDelete: (oldData) => new Promise((resolve) => {
                            setTimeout(() => {
                                deleteHoliday(oldData)
                                resolve()
                            }, 100)
                        }),
                    onRowAdd: (newData) => new Promise((resolve) => {
                            setTimeout(() => {
                                addHoliday(newData)
                                resolve()
                            }, 100)
                        }),
                }}
            />
        </div>
    )
}

HolidaysTable.propTypes = {
    holidays: PropTypes.arrayOf(PropTypes.shape({
        nameFin: PropTypes.string.isRequired,
        nameEng: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
    })).isRequired,
    addHoliday: PropTypes.func.isRequired,
    updateHoliday: PropTypes.func.isRequired,
    deleteHoliday: PropTypes.func.isRequired,
}

export default HolidaysTable
