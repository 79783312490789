import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import partnerGroupsContext from './PartnerGroupsContext'

const PartnerGroupsProvider = (props) => {
  const [partnerGroups, setPartnerGroups] = useState([])
  const { oidcUser } = React.useContext(AuthenticationContext)

  const refreshPartners = () => {
    fetch('/api/partnergroups')
      .then((results) => {
        if (!results.ok) {
          throw Error(results.status)
        }
        return results.json()
      }).then((data) => {
        setPartnerGroups(data)
      }).catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    // Load partner groups only for Admin & Organizer
    if (oidcUser?.profile?.userid == null ||
      !['Admin', 'Organizer'].includes(oidcUser?.profile?.role)) {
      return
    }
    refreshPartners()
  }, [oidcUser])

  return (
    <partnerGroupsContext.Provider value={{ partnerGroups, refreshPartners }}>
      {props.children}
    </partnerGroupsContext.Provider>
  )
}

PartnerGroupsProvider.propTypes = {
  children: PropTypes.node,
}

export default PartnerGroupsProvider
