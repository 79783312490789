import {utcToZonedTime, zonedTimeToUtc} from "date-fns-tz";
import {FinnishTime} from "./constants";
import {format} from "date-fns";

export const formatEstimateMinutes = (minutes) => (minutes < 60
     ? `${minutes}min`
     : `${Math.floor(minutes / 60)}h ${minutes % 60}min`)

export const zonedToFinnishTime = zonedDate => zonedTimeToUtc(zonedDate, FinnishTime)
export const utcToFinnishTime = utcDate => utcToZonedTime(utcDate, FinnishTime)
export const tuntiKikkulaSuomeksi = utcDate => format(utcToFinnishTime(utcDate), 'HH:mm')
export const tuntiPaivamaaraKikkulaSuomeksi = utcDate => format(utcToFinnishTime(utcDate), 'dd.MM.yyyy HH:mm')
