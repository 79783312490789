/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SeverityLevel } from '@microsoft/applicationinsights-web'

import { FormattedMessage } from 'react-intl'
import { trackException } from '../utils/ApplicationInsights/telemetryService'

class ErrorBoundary extends Component {

    state = {
        hasError: false,
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error) {
        trackException({ error: new Error(error), severityLevel: SeverityLevel.Error })
        this.setState({ hasError: true })
    }

    render() {
        const { hasError } = this.state
        const { children } = this.props
        if (hasError) {
            return (
                <p>
                    <FormattedMessage id="error_boundary_msg" />
                </p>
            )
        }
        return children
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ErrorBoundary
