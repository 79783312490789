import React, { useContext } from 'react'
import MaterialTable from 'material-table'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import { Select, MenuItem } from '@material-ui/core'
import { tableLocalization, tableIcons } from './TableConfig'

const OrganizationsTable = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { oidcUser } = useContext(AuthenticationContext)

  const updateOrg = (org) => {
    props.updateOrg(org)
  }

  return (
      <div>
          <MaterialTable
            icons={tableIcons}
            localization={tableLocalization}
            columns={[
              { title: 'Nimi', field: 'name' },
              {
                title: 'Tyyppi',
                field: 'type',
                editable: () => oidcUser?.profile?.role==='Admin',
                lookup: { 0: 'Asiakas', 1: 'Palveluntarjoaja' }
              },
              { title: 'Ensisij. palveluntarjoaja',
                field: 'orderOwner.name',
                editable: () => oidcUser?.profile?.role==='Admin',
                editComponent: (t) => (
                  <Select
                    id="primaryServiceProvider"
                    value={t.value}
                    displayEmpty
                    onChange={ (e) => {
                      t.onChange(e.target.value)
                    }}
                    >
                    {props.serviceProviders.map((org) => (
                      <MenuItem value={org.name} key={org.id}>{org.name}</MenuItem>
                    ))}
                </Select>
                )
              }
            ]}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 50]
            }}
            data={props.orgs}
            title="Yritykset"
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(()=> {
                    {
                      const data = props.orgs
                      const index = data.indexOf(oldData)
                      data[index] = newData
                      updateOrg(newData)
                    }
                    resolve()
                  }, 1000)
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject)=>{
                  fetch(`/api/organizations/${oldData.id}`, {
                    method: 'DELETE',
                  }).then((results) => {
                    if(!results.ok) {
                      throw Error(results.status)
                    }
                    const data = props.orgs
                    const index = data.indexOf(oldData)
                    data.splice(index, 1)
                    props.removeOrg(oldData)
                    enqueueSnackbar(<FormattedMessage id="org_delete_ok" />, { variant: 'success' })
                    resolve()
                  }).catch((error) => {
                    console.log(error)
                    enqueueSnackbar(<FormattedMessage id="org_delete_failed" />, { variant: 'error' })
                    reject()
                  })
                }),
            }}
          />
      </div>
  )
}

OrganizationsTable.propTypes = {
  serviceProviders: PropTypes.array,
  orgs: PropTypes.array,
  updateOrg: PropTypes.func,
  removeOrg: PropTypes.func,
}

export default OrganizationsTable