import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
    Typography,
    Grid,
    makeStyles,
    Link,
    IconButton,
} from '@material-ui/core'
import { format, differenceInMinutes, parseISO } from 'date-fns'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import { FormattedMessage } from 'react-intl'
import StatusIcon from './StatusIcon'
import { statuses, views } from '../utils/constants'
import StatusIconText from './StatusIconText'
import RenderRouteSteps from './RenderRouteSteps'
import { ascendingRouteSteps } from '../utils/ascendingRouteSteps';

const useStyles = makeStyles((theme) => ({
    listItem: {
        width: '100%',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        background: '#F8F6F1 0% 0% no-repeat padding-box',
        '&:hover': {
            background: '#FBFBFB',
            cursor: 'pointer',
            textDecoration: 'none',
        },
    },
    assignedDriver: {
        background: '#FBFBFB 0% 0% no-repeat padding-box',
        borderRadius: '3px',
    },
    unassignedDriver: {
        background: '#F8F6F1 0% 0% no-repeat padding-box',
        borderRadius: '3px',
    },
    completed: {
        color: '#82A9F6',
    },
    pickupDate: {
        fontWeight: 'bold',
    },
    passengerInfo: {
        fontWeight: 'bold',
    },
    notCompleted: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    statusTimeContainerFlexDirection: {
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'row',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    vehicleInfoTextAligns: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'right',
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },
    marginTopSmallScreen: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '10px',
        },
    },
    noWrap: {
        flexWrap: 'nowrap',
    },
    noUnderline: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
}))

const RideListItem = ({ rideData, confirmOrder, openRideDetails, allowAssignmentConfirm }) => {
    const { oidcUser } = useContext(AuthenticationContext)
    const classes = useStyles()

    const decimalIntl = new Intl.NumberFormat('fi-FI')

    const formatSamedayHhMm = (dateStamp) => {
        let stamp = ''
        if (parseISO(rideData.actualStart) !== parseISO(dateStamp)) {
            stamp += `${format(dateStamp, 'dd.MM.')} `
        }
        stamp += format(dateStamp, 'HH:mm')
        return stamp
    }

    return (
        <div
            role="button"
            tabIndex={0}
            onKeyDown={() => openRideDetails(rideData)}
            onClick={() => openRideDetails(rideData)}
            className={classes.noUnderline}
            data-test-id={rideData.order.number}
        >
            <Grid
                container
                alignItems="center"
                className={`${classes.listItem} ${rideData.order.status === statuses.Ordered && classes.unassignedDriver} ${rideData.order.status === statuses.Assigned && classes.assignedDriver}`}
            >
                <Grid item xs={4} sm={4}>
                    <Grid container spacing={1} alignItems="center" justify="center" wrap="nowrap" className={classes.statusTimeContainerFlexDirection}>
                        <Grid item sm={6} container alignItems="center" justify="center" direction="column">
                            {['Organizer', 'Admin'].includes(oidcUser?.profile?.role) &&
                                rideData.status === statuses.Ordered &&
                                <>
                                    <IconButton
                                        data-test-id={'orderConfirmButton' + rideData.id}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            confirmOrder(rideData)
                                        }}
                                    >
                                        <StatusIcon status={rideData.status} view={views.organizer} />
                                    </IconButton>
                                    <StatusIconText status={rideData.status} />
                                </>
                            }

                            {['Organizer', 'Admin', 'Driver'].includes(oidcUser?.profile?.role) &&
                                rideData.status === statuses.Assigned &&
                                allowAssignmentConfirm &&
                                <>
                                    <IconButton
                                        data-test-id={'driverConfirmButton' + rideData.id}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            confirmOrder(rideData)
                                        }}
                                    >
                                        <StatusIcon status={rideData.status} view={views.driver} />
                                    </IconButton>
                                    <StatusIconText status={rideData.status} />
                                </>
                            }

                            {['Organizer', 'Admin', 'Driver'].includes(oidcUser?.profile?.role) &&
                                (
                                    (rideData.status !== statuses.Ordered &&
                                        rideData.status !== statuses.Assigned) ||
                                    (rideData.status === statuses.Assigned &&
                                        !allowAssignmentConfirm)
                                )
                                &&
                                <>
                                    <StatusIcon status={rideData.status} view={views.driver} />
                                    <StatusIconText status={rideData.status} />
                                </>
                            }
                        </Grid>
                        <Grid item sm={6} container justify="center" alignItems="center">
                            <Typography variant="subtitle1" className={classes.pickupDate} align="center">
                                {format(new Date(rideData.plannedPickupDate), 'HH:mm')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={8} sm={8}>
                    <Grid container sm item direction="column" className={classes.noWrap}>
                        <Typography variant="subtitle2" className={classes.passengerInfo}>
                            {rideData.order.passengerUser ? rideData.order.passengerUser.fullName : rideData.order.otherPassengerName}, {rideData.order.subscriberCompany.name}
                        </Typography>
                        <Typography noWrap variant="subtitle2">
                            <RenderRouteSteps routeSteps={ascendingRouteSteps(rideData.routeSteps)} />
                        </Typography>
                        {(rideData.status !== statuses.RideEnded) &&
                            <Typography variant="subtitle2">
                                <FormattedMessage id="estimate" />: {differenceInMinutes(new Date(rideData.plannedDropoffDate), new Date(rideData.plannedPickupDate))} min ({rideData.estimatedTripLengthKm} km)
                            </Typography>
                        }
                        {(rideData.status === statuses.RideEnded) &&
                            <>
                                <Typography variant="subtitle2">
                                    <FormattedMessage id="ride_length" />: {decimalIntl.format(rideData.approvedTripLengthKm)} km<br />
                                </Typography>
                                <Typography variant="subtitle2">
                                    <FormattedMessage id="ride_duration" />: {format(new Date(rideData.actualStart), 'HH:mm')} - {formatSamedayHhMm(new Date(rideData.actualEnd))}
                                </Typography>
                            </>}
                    </Grid>
                    <Grid container sm item direction="column" justify="center" className={classes.marginTopSmallScreen}>
                        <Typography variant="subtitle2" className={classes.vehicleInfoTextAligns}>
                            {rideData.order.numberOfPassengers}
                            {' '}<FormattedMessage id="pers" />
                        </Typography>
                        <Typography color="secondary" variant="subtitle2" className={classes.vehicleInfoTextAligns}>
                            {rideData.vehicleLicensePlate || '-'}
                        </Typography>
                        <Typography color="secondary" variant="subtitle2" className={classes.vehicleInfoTextAligns}>
                            {rideData.driverName || '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

RideListItem.propTypes = {
    rideData: PropTypes.object,
    confirmOrder: PropTypes.func,
    openRideDetails: PropTypes.func,
    allowAssignmentConfirm: PropTypes.bool,
}

export default RideListItem
