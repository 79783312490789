import React, { useState, useEffect, useContext } from 'react'
import {
  makeStyles, Grid, Typography,
  InputLabel, Link, Box,
} from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton, } from '@material-ui/lab'
import { FormattedMessage } from 'react-intl'
import PhoneIcon from '@material-ui/icons/Phone'
import { format, getDate } from 'date-fns'
import { IntlContext } from '../../utils/IntlProviderWrapper'
import { statuses } from '../../utils/constants'

const useStyles = makeStyles((theme) => ({
  page: {
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      margin: '0 !important',
      left: '0',
      minWidth: '320px',
      width: '100%',
      top: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      left: '50%',
      minWidth: '380px',
      maxWidth: '512px',
      transform: 'translateX(-50%)',
      top: '80px',
      marginBottom: '16px',
    },
  },
  rideInfo: {
    backgroundColor: '#F8F6F1',
    //margin: theme.spacing(1),
    /* Width and margin below relates to: https://github.com/mui-org/material-ui/issues/7466*/
    width: '100% !important',
    margin: '0 !important',
    padding: theme.spacing(2),
  },
  container: {
    backgroundColor: '#FFF',
    color: '#000',
    borderRadius: '2px',
    boxShadow: '0px 3px 6px #00000029',
    padding: '1em',
    width: '100% !important',
    margin: '0 !important',
  },
  pageTitle: {
    textTransform: 'uppercase',
  },
  map: {
    width: '100% !important',
    margin: '0 !important',
  },
  car: {
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
  }
}))

export const TravelInfo = (props) => {
  const classes = useStyles()
  const [ride, setRide] = useState({})
  const localeData = useContext(IntlContext)

  const loadTravelInfo = () => {
    console.log(JSON.stringify(props.match))
    let id = props?.match?.params?.id

    if(!id) {
      return
    }

    fetch(`/api/rides/${id}/travelinfo`, {
      method: "GET"
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
    }).then(data => {
      if(data) {
        setRide(data)
      }
    })
  }

  const formatSamedayHhMm = (dateStamp) => {   
    let stamp = ""
    let today = new Date()
    
    if(getDate(today)!==getDate(dateStamp)) {
      stamp += `${format(dateStamp, "dd.MM.")} `
    }
    stamp += format(dateStamp, "HH:mm")
    return stamp
  }  

  useEffect(() => {
    loadTravelInfo()
  }, [])

  return (
    <div className={classes.page}>
      <Grid container spacing={2} justify="center" direction="column" className={classes.container}>
      {ride.fetchTime &&
        <>
          <Grid container spacing={2} justify="center" direction="column" className={classes.rideInfo}>
            <Grid item container xs={12} justify="flex-end" alignItems="flex-end" direction="row">
              <ToggleButtonGroup value={localeData.locale}>
                <ToggleButton value="fi" onClick={localeData.toggleFi} size="small">FI</ToggleButton>
                <ToggleButton value="en" onClick={localeData.toggleEn} size="small">EN</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item container xs={12} justify="center" alignItems="center">
              <Typography variant="h4" align="center" className={classes.pageTitle}>
                {(ride.status === statuses.DriverStarted || ride.status === statuses.RideStarted) &&
                  <FormattedMessage id="info_car_is_on_its_way"/>
                }
                {(ride.status !== statuses.DriverStarted && ride.status !== statuses.RideStarted) &&
                  <FormattedMessage id="info_ride_information"/>
                }
              </Typography>
              <img src={ride.vehicleCategoryImagePath ? ride.vehicleCategoryImagePath : "/images/cars/blank.png"} className={classes.car} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel><FormattedMessage id="info_pickup_time" /></InputLabel>
              <Typography variant="subtitle1">
                {ride.fetchTime && formatSamedayHhMm(new Date(ride.fetchTime))}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel><FormattedMessage id="info_pickup_address" /></InputLabel>
              <Typography variant="subtitle1">{ride.fetchAddress}</Typography>
            </Grid>
            {ride.additionalInfo &&
            <Grid item xs={12}>
              <InputLabel><FormattedMessage id="info_pickup_info" /></InputLabel>
              <Typography variant="subtitle1">{ride.additionalInfo}</Typography>
            </Grid>
            }
          </Grid>
          <Box m={2} />
          <Grid container spacing={4} justify="center" direction="column">
            <Grid item xs={12}>
              <InputLabel><FormattedMessage id="info_driver" /></InputLabel>
              <Typography variant="subtitle1">{ride.driverName}</Typography>
              <div><PhoneIcon /> <Link href={"tel:" + ride.driverPhone} variant="subtitle1">{ride.driverPhone}</Link></div>
            </Grid>
            <Grid item xs={12}>
              <InputLabel><FormattedMessage id="info_vehicle" /></InputLabel>
              <Typography variant="subtitle1"><FormattedMessage id="info_licenseplate" />: {ride.vehicleLicensePlate}</Typography>
              <Typography variant="subtitle1">{ride.vehicleName}</Typography>
            </Grid>
            {ride.mapLink &&
            <Grid item xs={12}>
              <Link href={"https://maps.google.com?q=" + encodeURIComponent(ride.fetchAddress)}>
                <img src={ride.mapLink} className={classes.map} />
              </Link>
            </Grid>
            }
            <Grid item xs={12}>
              <Grid item xs={12}>
                <InputLabel><FormattedMessage id="info_infophone" /></InputLabel>
                <div><Typography variant="subtitle1"><PhoneIcon /> {ride.dutyOfficerPhone}</Typography></div>
              </Grid>
            </Grid>
          </Grid>
        </> }
        {!ride.fetchTime &&
        <>
          <Grid container spacing={2} justify="center" direction="column" className={classes.rideInfo}>
            <Grid item container xs={12} justify="center" alignItems="center">
              <Typography variant="subtitle1"><FormattedMessage id="info_not_available" /></Typography>
            </Grid>
          </Grid>
        </>
        }
      </Grid>
    </div>
  )

}
