import React from 'react'
import { makeStyles, Grid, Typography, } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  page: {
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      margin: '0 !important',
      left: '32px',
    },
    [theme.breakpoints.up('sm')]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
    top: '100px',
  },
  container: {
      backgroundColor: '#FFF',
      color: '#000',
      borderRadius: '2px',
      padding: '1em',
  },
}))

const UsagePolicy = () => {
  const classes = useStyles()

  return (
    <div className={classes.page}>
        <Grid container xs={12} spacing={4} justify="center" direction="column" className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h4">Käyttöehdot</Typography>
            <p>
            <b>Määrittelyt:</b>
                {' '}
                ECS-Polar Applications Oy tarjoaa digitaalisia ratkaisuja loppukäyttäjien ja palveluntarjoajien välille.
            </p>
            <p>
                <b>Tilauksen vastaanottaminen</b>
                {' '}
                – Palvelun loppukäyttäjä tekee tilauksen ECS-Polar Applications Oy:n portaalin kautta joka toimittaa tilauksen palveluntarjoajalle. 
            </p>
            <p>
                <b>Toimitus</b>
                {' '}
                – Tilaus toimitetaan välittömästi palveluntarjoajalle joka hyväksyy tai hylkää tilauksen omien ehtojen mukaisesti. Vastuu tilauksen suorituksesta siirtyy palvelun tarjoajalle. Jos tilausta ei pystytä toimittamaan siitä lähtee välittömästi tieto asiakkaalle, viimeistään kuitenkin palvelun tarjoajan ehtojen mukaisesti. Palvelun tarjoaja suorittaa tilauksen asiakkaan kanssa sopimien ehtojen mukaisesti. 
            </p>
            <p>
                <b>Maksu</b>
                {' '}
                – ECS-Polar Applications Oy tilittää toteutuneet veloitukset keskenään sovitun maksuaikataulun mukaan.
            </p>
            <p>
                <b>Tilauksen peruutus</b>
                {' '}
                – Peruutusehdot sovitaan palveluntarjoajan ja loppukäyttäjän kesken
            </p>
            <p>
                <b>Reklamaatio</b>
                {' '}
                – Jos tilauksen suorituksessa ilmenee tarvetta reklamoida, palvelun loppukäyttäjä on suoraan yhteydessä palvelun tarjoajaan. Maksuliikenteeseen liittyvät ongelmat käsittelee ECS-Polar Applications Oy. 
            </p>
            <p>
            <b>Henkilötiedot</b>
                {' '}
                – ECS-Polar Applications Oy käsittelee henkilötietoja henkilötietolain mukaisesti.
            </p>
            <p>
               Tiedot, jotka voidaan liittää sinuun henkilönä, eivät ole koskaan muiden yritysten käytettävissä, eikä niitä liitetä toisiin ulkoisiin rekistereihin.
            </p>
            <p>
                <b>Korttitiedot</b>
                {' '}
                - Kun teet ostoksia ECS-Polar Applications OY:n kautta korttitietoja käsittelee NETS A/S, joka on turvallinen sähköinen Visa- ja MasterCard/Eurocard-maksuratkaisu. Kaikki korttitiedot käsitellään korttiorganisaation säännöstön mukaisesti.
            </p>
            <p>
                <b>Tilauksen peruutus</b>
                {' '}
                – Peruutus suoritetaan ECS-Polar Applications oy:n portaalin kautta palveluntarjoajan ehtojen mukaisesti
            </p>
            <Typography variant="h4">Yritystiedot</Typography>
            <p>ECS-Polar Applications Oy, Y-tunnus 3154639-9</p>
            <Typography variant="h4">Yhteystiedot</Typography>
            <p>Immolantie 40 00780 Helsinki. Puh: +358503551588, jari@ecs-polarapp.fi</p>
            <Typography variant="h4">Tuotteen tai palvelun tiedot</Typography>
            <p>Eri maakuljetuksia loppukäyttäjien tarpeiden mukaisesti.</p>
            Palveluntarjoajat:
            <br />
            Polarbus Oy
            <br />
            Edustusautotaksit J&M Oy
            <br />
            ECS Minivan Service Oy
            <br />
            Businessbus Oy
            <Typography variant="h4">Hinta</Typography>
            <p>Hinta määräytyy palveluntarjoajan ja loppukäyttäjän sopiman hinnaston mukaisesti</p>
          </Grid>
        </Grid>
    </div>
  )
}

export default UsagePolicy
