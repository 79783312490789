import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Button, makeStyles, Box } from '@material-ui/core'
import { isBefore } from 'date-fns'
import { addHours } from 'date-fns/esm'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
    }
}))

const CancelModal = (props) => {

    const classes = useStyles()
    const chargingOfCanceling = isBefore(new Date(props.plannedPickupDate), addHours(new Date(), props.timeLimitHoursForOrderCancellation))

    return (
        <Grid container direction="column" justify="space-between" className={classes.container}>
            <Grid item>
                <Box fontWeight="fontWeightBold">
                    <FormattedMessage id="cancel_modal_cancel_order" />
                </Box>
            </Grid>
            <Grid item>
                {chargingOfCanceling ?
                    <Typography>
                        <FormattedMessage id="cancel_modal_cancel_order_overdue_part1" /> {props.timeLimitHoursForOrderCancellation} <FormattedMessage id="cancel_modal_cancel_order_overdue_part2" />
                    </Typography>
                    :
                    <Typography>
                        <FormattedMessage id="cancel_modal_cancel_order_not_overdue_part1" /> {props.timeLimitHoursForOrderCancellation} <FormattedMessage id="cancel_modal_cancel_order_overdue_part2" />
                    </Typography>
                }
            </Grid>
            <Grid item container justify="flex-end">
                <Button data-test-id="confirmCancelOrderButton" color="secondary" onClick={props.onCancelClick}><FormattedMessage id="cancel_modal_cancel_order_button" /></Button>
                <Button data-test-id="cancelCancelOrderButton" color="secondary" onClick={props.onCloseClick}><FormattedMessage id="back" /></Button>
            </Grid>
        </Grid>
    )
}

CancelModal.propTypes = {
    timeLimitHoursForOrderCancellation: PropTypes.number,
    pannedPickupDate: PropTypes.instanceOf(Date),
    onCloseClick: PropTypes.func,
    onCancelClick: PropTypes.func,
}

export default CancelModal