import 'bootstrap/dist/css/bootstrap.css'
import React from 'react'
import ReactDOM from 'react-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import fiLocale from 'date-fns/locale/fi'
import DateFnsUtils from '@date-io/date-fns'
import { SnackbarProvider } from 'notistack'

import { IntlProviderWrapper } from './utils/IntlProviderWrapper'
import App from './App'
import ErrorBoundary from './components/ErrorBoundary'
import ConfigurationProvider from './context/ConfigurationProvider'
import GoogleScriptLoader from './utils/GoogleScriptLoader'

const rootElement = document.getElementById('root')
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#222222',
        },
        secondary: {
            main: '#B7A355',
        },
        background: {
            default: '#FBFBFB',
        },
        text: {
            primary: '#222222',
            secondary: '#B7A355',
        },
    },
    appBar: {
        height: 60,
        background: {
            default: '#2C2933',
        },
        primary: {
            main: '#FFF',
        },
        text: {
            primary: '#FFF',
        },
    },
    typography: {
        h3: {
            textTransform: 'uppercase',
        },
        h5: {
            textTransform: 'uppercase',
        },
    },
    overrides: {
        MuiInputBase: {
            input: {
                color: '#000',
            },
        },
        MuiFormHelperText: {
            root: {
                marginBottom: '16px',
                color: '#00000089',
            },
        },
        MuiFormLabel: {
            root: {
                color: '#00000089',
            },
        },
    },
})

ReactDOM.render(
    <ConfigurationProvider>
        <GoogleScriptLoader>
            <IntlProviderWrapper>
                <ErrorBoundary>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider>
                                <CssBaseline />
                                <App />
                            </SnackbarProvider>
                        </ThemeProvider>
                    </MuiPickersUtilsProvider>
                </ErrorBoundary>
            </IntlProviderWrapper>
        </GoogleScriptLoader>
    </ConfigurationProvider>,
    rootElement,
)

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
// registerServiceWorker();
