import React from 'react'
import {
    Grid, Box, Button, Typography, makeStyles, useTheme,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { getISOWeek, getYear } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
    button: {
        borderRadius: '17px',
        boxShadow: '0px 3px 6px #00000029',
        border: 'none',
    },
})

const WeekBrowser = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const {
        handlePrevClick,
        handleNextClick,
        date,
        disableButtons,
    } = props

    const smallDevice = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Box mr={3} style={{order: smallDevice ? 1 : 0}}>
                <Button
                    className={classes.button}
                    color="secondary"
                    data-test-id="weekbrowser-prev-button"
                    variant="outlined"
                    disabled={disableButtons}
                    onClick={handlePrevClick}
                >
                    <FormattedMessage id="previous" />
                </Button>

            </Box>
            <Grid item style={{order: smallDevice ? 0 : 1, width: smallDevice ? '100%' : 'auto'}}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                >
                    <Grid item data-test-id="weekbrowser-week">
                        <Typography color="secondary">
                            <FormattedMessage id="week" />
              &nbsp;
              {getISOWeek(date) || '-'}
                        </Typography>
                    </Grid>
                    <Grid item data-test-id="weekbrowser-year">
                        <Typography>
                            {getYear(date) || '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Box ml={3} style={{order: smallDevice ? 2 : 1}}>
                <Button
                    className={classes.button}
                    color="secondary"
                    data-test-id="weekbrowser-next-button"
                    variant="outlined"
                    disabled={disableButtons}
                    onClick={handleNextClick}
                >
                    <FormattedMessage id="next" />
                </Button>
            </Box>
        </Grid>
    )
}

export default WeekBrowser

WeekBrowser.propTypes = {
    handlePrevClick: PropTypes.func,
    handleNextClick: PropTypes.func,
    date: PropTypes.instanceOf(Date),
    disableButtons: PropTypes.bool,
}

WeekBrowser.defaultProps = {
    handlePrevClick: null,
    handleNextClick: null,
    date: new Date(),
    disableButtons: false,
}
