import React, { Component } from 'react'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ai, getAppInsights } from './telemetryService'
import ConfigurationContext from '../../context/ConfigurationContext'

// NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
class ApplicationInsights extends Component {
    static contextType = ConfigurationContext

    state = {
        initialized: false,
        instrumentationKey: null,
    }

    componentDidMount() {
        const { context } = this
        this.setState({ instrumentationKey: context.insightsKey })
    }

    UNSAFE_componentWillReceiveProps() {
        const { history } = this.props
        const { initialized, instrumentationKey } = this.state

        if (!instrumentationKey) {
            return
        }

        if (!initialized && Boolean(instrumentationKey) && Boolean(history)) {
            ai.initialize(instrumentationKey, history)
            this.setState({ initialized: true })
            getAppInsights()
        }
    }

    render() {
        const { children } = this.props
        return <>{children}</>
    }
}

ApplicationInsights.propTypes = {
    children: PropTypes.node.isRequired,
    history: PropTypes.node.isRequired,
}

export default withRouter(withAITracking(ai.reactPlugin, ApplicationInsights))
