import React, { useState, useEffect } from 'react'
import {
    InputLabel, Select, MenuItem, Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { emptyGuid } from '../utils/constants'

const OrganizationsDropdown = (props) => {
  const [orgs, setOrgs] = useState([])
  const [org, setOrg] = useState('')

  const loadData = () => {
    let queryParams = ''
    if (props.orgType?.length) {
      queryParams = `?organizationType=${props.orgType}`
    }

    fetch(`/api/organizations${queryParams}`)
      .then((results) => {
        return results.json()
      }).then((data) => {
        if (props.showEmptyOption) {
            data.unshift({
                id: emptyGuid,
                name: '-',
            })
        }

        const filteredData = data.filter((organization) => {
            return (props.hideOtherServiceProviders === false ||
                (props.hideOtherServiceProviders === true && organization.type === 0) ||
                organization.id === props.defaultOrg)
        })

        setOrgs(filteredData)
      })
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (orgs.length === 1) {
      setOrg(orgs[0].id)
    } else if (props.defaultOrg) {
      setOrg(props.defaultOrg)
    } else if (props.showEmptyOption) {
      setOrg(emptyGuid)
    }
  }, [orgs])

  useEffect(() => {
    const selectedOrg = orgs.find((o) => o.id === org)
    if (selectedOrg) {
      props.handleOrgChange(selectedOrg)
    }
  }, [org, props])

  const orgItems = orgs.map((organization) => <MenuItem value={organization.id} key={organization.id}>
                                                {organization.name}
                                              </MenuItem>)

  const handleChange = (event) => {
    setOrg(event.target.value)
  }

  return (
    <>
      {orgs?.length !== 1 &&
        <>
          <InputLabel id="organizations-select-label" shrink>
            <FormattedMessage id={props.customLabel || "organization"} />
          </InputLabel>
          <Select
            labelId="organizations-select-label"
            id="organizations-select"
            value={org}
            onChange={handleChange}
            readOnly={props.readOnly}
            fullWidth
          >
            {!props.showEmptyOption &&
            <MenuItem value="" key="">
              <FormattedMessage id="choose" />
            </MenuItem>
            }
            {orgItems}
          </Select>
        </>
      }
      {orgs?.length === 1 &&
        <Typography variant="subtitle1">{orgs[0].name}</Typography>
      }
    </>
  );

}

OrganizationsDropdown.propTypes = {
  handleOrgChange: PropTypes.func.isRequired,
  orgType: PropTypes.string,
  defaultOrg: PropTypes.string,
  customLabel: PropTypes.string,
  readOnly: PropTypes.bool,
  showEmptyOption: PropTypes.bool,
  hideOtherServiceProviders: PropTypes.bool,
}

OrganizationsDropdown.defaultProps = {
  readOnly: false,
  showEmptyOption: false,
  hideOtherServiceProviders: false,
  customLabel: null,
  defaultOrg: null,
  orgType: null,
}

export default OrganizationsDropdown
