import React from 'react'
import PropTypes from 'prop-types'
import { Typography, makeStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { statuses } from '../utils/constants'

const useStyles = makeStyles(() => ({
    completed: {
        color: '#82A9F6',
        whiteSpace: 'nowrap',
    },
    notCompleted: {
        color: 'rgba(0, 0, 0, 0.54)',
        whiteSpace: 'nowrap',
    },
}))

const StatusIconText = ({ status }) => {
    const classes = useStyles()
    if (status === statuses.Ordered) {
        return (
            <>
                <Typography variant="subtitle2" className={classes.notCompleted}><FormattedMessage id="status_not_completed" /></Typography>
            </>)
    }
    if (status === statuses.Confirmed) {
        return (
            <>
                <Typography variant="subtitle2" className={classes.completed}><FormattedMessage id="status_confirmed" /></Typography>
                <Typography variant="subtitle2" className={classes.notCompleted}><FormattedMessage id="status_not_handled" /></Typography>
            </>)
    }
    if (status === statuses.Assigned) {
        return (
            <>
                <Typography variant="subtitle2" className={classes.completed}><FormattedMessage id="status_confirmed" /></Typography>
                <Typography variant="subtitle2" className={classes.completed}><FormattedMessage id="status_handled" /></Typography>
                <Typography align="center" variant="subtitle2" className={classes.notCompleted}><FormattedMessage id="status_not_signed" /></Typography>
            </>)
    }
    if (status === statuses.DriverConfirmed) {
        return (
            <>
                <Typography variant="subtitle2" className={classes.completed}><FormattedMessage id="status_confirmed" /></Typography>
                <Typography variant="subtitle2" className={classes.completed}><FormattedMessage id="status_handled" /></Typography>
                <Typography variant="subtitle2" className={classes.completed}><FormattedMessage id="status_signed" /></Typography>
            </>)
    }
        return null
}

StatusIconText.propTypes = {
    status: PropTypes.oneOf(
        [
            statuses.Ordered,
            statuses.Confirmed,
            statuses.Assigned,
            statuses.DriverConfirmed,
            statuses.DriverStarted,
            statuses.RideStarted,
            statuses.RideEnded,
            statuses.Cancelled,
            888,
            999,
        ],
    ).isRequired,
}

export default StatusIconText
