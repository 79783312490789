import React from 'react'
import {
    Button, makeStyles, Grid, Typography,
    TextField, InputAdornment, Box,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl'
import Lock from '@material-ui/icons/Lock'

const useStyles = makeStyles((theme) => ({
    page: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
            left: '0px',
            minWidth: '320px',
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
    container: {
        backgroundColor: '#FFF',
        color: '#000',
        borderRadius: '2px',
        boxShadow: '0px 3px 6px #00000029',
        padding: '1em',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: '320px',
            margin: '0 !important',
        },
        [theme.breakpoints.up('sm')]: {
            width: '480px',
        },
        maxWidth: '640px',
    },
    errorRequired: {
        color: '#f00',
    },
    textAlignRight: {
        textAlign: 'right',
    },
}))
const ChangePassword = () => {

    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        mode: 'onBlur',
    })

    const onPasswordSubmit = (data, evt) => {
        evt.preventDefault()

        if (data.newPassword !== data.newPasswordCheck) {
            enqueueSnackbar(<FormattedMessage id="profile_password_mismatch" />, { variant: 'warning' })
            return
        }

        const newPassword = {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword,
        }

        fetch('/api/UserProfile/password', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(newPassword),
        }).then(response => {
            if (!response.ok) {
                throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="profile_password_changed" />, { variant: 'success' })
            reset()
        }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: 'error' })
        })
    }
    return (
        <div className={classes.page}>
            <form onSubmit={handleSubmit(onPasswordSubmit)}>
                <Grid container spacing={4} justify="center" direction="column" className={classes.container}>
                    <Grid item xs={12}>
                        <Box width={300} />
                        <Typography variant="h5"><FormattedMessage id="profile_change_password" /></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            <FormattedMessage id="profile_change_password_instructions" />
                            &nbsp;
                            <FormattedMessage id="reg_password_complexity" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...register('currentPassword', { required: true })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            id="currentPassword"
                            name="currentPassword"
                            type="password"
                            label={<FormattedMessage id="profile_old_password" />}
                            error={Boolean(errors.currentPassword)}
                            helperText={errors.currentPassword && errors.currentPassword.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock color="secondary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...register('newPassword', {
                                required: true,
                                pattern: {
                                    value: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/i,
                                    message: <FormattedMessage id="reg_password_complexity" />,
                                },
                            })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            label={<FormattedMessage id="profile_new_password" />}
                            error={Boolean(errors.newPassword)}
                            helperText={errors.newPassword && errors.newPassword.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock color="secondary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...register('newPasswordCheck', { required: true })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            id="newPasswordCheck"
                            name="newPasswordCheck"
                            type="password"
                            label={<FormattedMessage id="profile_new_password_check" />}
                            error={Boolean(errors.newPasswordCheck)}
                            helperText={errors.newPasswordCheck && errors.newPasswordCheck.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock color="secondary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            id="save"
                            label="Change password"
                            variant="contained">
                            <FormattedMessage id="profile_change_password" />
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default ChangePassword