import React from 'react'

export const Ordered = () =>
  <svg id="IconStatus_OrderOK" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <circle id="ellipse" cx="20" cy="20" r="20" fill="#fff" />
    <g id="assignment-24px" transform="translate(8 8)">
      <path id="Path_157" data-name="Path 157" d="M0,0H24V24H0Z" fill="none" />
      <path id="Path_158" data-name="Path 158" d="M19,3H14.82A2.988,2.988,0,0,0,9.18,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM12,3a1,1,0,1,1-1,1A1,1,0,0,1,12,3Zm2,14H7V15h7Zm3-4H7V11H17Zm0-4H7V7H17Z" fill="#82a9f6" />
    </g>
  </svg>

export const Confirmed = () =>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="48" height="48">
    <g data-name="invisible background">
      <rect width="48" height="48" opacity="0" />
    </g>
    <circle cx="24" cy="24" r="20" fill="#fff" />
    <path d="M12,12H36V36H12Z" fill="none" data-name="Path 157" />
    <path d="M31,15H26.82a3,3,0,0,0-5.64,0H17a2,2,0,0,0-2,2V31a2,2,0,0,0,2,2H31a2,2,0,0,0,2-2V17A2,2,0,0,0,31,15Zm-7,0a1,1,0,1,1-1,1A1,1,0,0,1,24,15Zm2,14H19V27h7Zm3-4H19V23H29Zm0-4H19V19H29Z" fill="#82a9f6" data-name="Path 158" />
    <g data-name="IconStatus BadgeProcessed">
      <circle cx="38" cy="10" r="10" fill="#00db00" data-name="Ellipse 5" />
      <path d="m45.5 5.8-10 10-4.7-4.6 1.5-1.4 3.2 3.2 8.6-8.6z" fill="#fff" data-name="Path 153" />
    </g>
    <circle cx="38" cy="10" r="10" fill="#00db00" data-name="Ellipse 5" />
    <path d="m45.5 5.8-10 10-4.7-4.6 1.5-1.4 3.2 3.2 8.6-8.6z" fill="#fff" data-name="Path 153" />
  </svg>

export const DriverConfirmedOrganizerAndDriver = () =>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="48" height="48">
    <g data-name="invisible background">
      <rect width="48" height="48" opacity="0" />
    </g>
    <path d="M24,4A20,20,0,1,1,4,24,20,20,0,0,1,24,4Z" fill="#fff" />
    <path d="M30.92,18a1.5,1.5,0,0,0-1.42-1h-11a1.5,1.5,0,0,0-1.42,1L15,24v8a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V31H30v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V24ZM18.5,28A1.5,1.5,0,1,1,20,26.5,1.5,1.5,0,0,1,18.5,28Zm11,0A1.5,1.5,0,1,1,31,26.5,1.5,1.5,0,0,1,29.5,28ZM17,23l1.5-4.5h11L31,23Z" fill="#82a9f6" data-name="Path 127" />
    <path d="M12,12H36V36H12Z" fill="none" data-name="Path 128" />
    <circle cx="38" cy="10" r="10" fill="#00db00" data-name="Ellipse 5" />
    <path d="m45.5 5.8-10 10-4.7-4.6 1.5-1.4 3.2 3.2 8.6-8.6z" fill="#fff" data-name="Path 153" />
  </svg>

export const DriverConfirmedOrderer = () =>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="48" height="48">
    <g data-name="invisible background">
      <rect width="48" height="48" opacity="0" />
    </g>
    <circle cx="24" cy="24" r="20" fill="#fff" />
    <path d="M12,12H36V36H12Z" fill="none" data-name="Path 157" />
    <path d="M31,15H26.82a3,3,0,0,0-5.64,0H17a2,2,0,0,0-2,2V31a2,2,0,0,0,2,2H31a2,2,0,0,0,2-2V17A2,2,0,0,0,31,15Zm-7,0a1,1,0,1,1-1,1A1,1,0,0,1,24,15Zm2,14H19V27h7Zm3-4H19V23H29Zm0-4H19V19H29Z" fill="#82a9f6" data-name="Path 158" />
    <circle cx="38" cy="10" r="10" fill="#00db00" data-name="Ellipse 5" />
    <path d="m45.5 5.8-10 10-4.7-4.6 1.5-1.4 3.2 3.2 8.6-8.6z" fill="#fff" data-name="Path 153" />
  </svg>

export const DriverStarted = () =>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="48" height="48">
    <g data-name="invisible background">
      <rect width="48" height="48" opacity="0" />
    </g>
    <circle cx="24" cy="24" r="20" fill="#82a9f6" />
    <path d="M30.92,18a1.5,1.5,0,0,0-1.42-1h-11a1.5,1.5,0,0,0-1.42,1L15,24v8a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V31H30v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V24ZM18.5,28A1.5,1.5,0,1,1,20,26.5,1.5,1.5,0,0,1,18.5,28Zm11,0A1.5,1.5,0,1,1,31,26.5,1.5,1.5,0,0,1,29.5,28ZM17,23l1.5-4.5h11L31,23Z" fill="#fff" data-name="Path 127" />
    <path d="M12,12H36V36H12Z" fill="none" data-name="Path 128" />
  </svg>

export const RideStarted = () =>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="48" height="48">
    <g data-name="invisible background">
      <rect width="48" height="48" opacity="0" />
    </g>
    <circle cx="24" cy="24" r="20" fill="#82a9f6" />
    <path d="M30.92,18a1.5,1.5,0,0,0-1.42-1h-11a1.5,1.5,0,0,0-1.42,1L15,24v8a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V31H30v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V24ZM18.5,28A1.5,1.5,0,1,1,20,26.5,1.5,1.5,0,0,1,18.5,28Zm11,0A1.5,1.5,0,1,1,31,26.5,1.5,1.5,0,0,1,29.5,28ZM17,23l1.5-4.5h11L31,23Z" fill="#fff" data-name="Path 127" />
    <path d="M12,12H36V36H12Z" fill="none" data-name="Path 128" />
  </svg>

export const AssignedOrderer = () =>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="48" height="48">
    <g data-name="invisible background">
      <rect width="48" height="48" opacity="0" />
    </g>
    <circle cx="24" cy="24" r="20" fill="#fff" />
    <path d="M12,12H36V36H12Z" fill="none" data-name="Path 157" />
    <path d="M31,15H26.82a3,3,0,0,0-5.64,0H17a2,2,0,0,0-2,2V31a2,2,0,0,0,2,2H31a2,2,0,0,0,2-2V17A2,2,0,0,0,31,15Zm-7,0a1,1,0,1,1-1,1A1,1,0,0,1,24,15Zm2,14H19V27h7Zm3-4H19V23H29Zm0-4H19V19H29Z" fill="#82a9f6" data-name="Path 158" />
    <circle cx="38" cy="10" r="10" fill="#00db00" data-name="Ellipse 5" />
    <path d="m45.5 5.8-10 10-4.7-4.6 1.5-1.4 3.2 3.2 8.6-8.6z" fill="#fff" data-name="Path 153" />
  </svg>

export const AssignedDriver = () =>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="48" height="48">
    <g data-name="invisible background">
      <rect width="48" height="48" opacity="0" />
    </g>
    <path d="M24,4A20,20,0,1,1,4,24,20,20,0,0,1,24,4Z" fill="#fff" />
    <path d="M30.92,18a1.5,1.5,0,0,0-1.42-1h-11a1.5,1.5,0,0,0-1.42,1L15,24v8a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V31H30v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V24ZM18.5,28A1.5,1.5,0,1,1,20,26.5,1.5,1.5,0,0,1,18.5,28Zm11,0A1.5,1.5,0,1,1,31,26.5,1.5,1.5,0,0,1,29.5,28ZM17,23l1.5-4.5h11L31,23Z" fill="#82a9f6" data-name="Path 127" />
    <path d="M12,12H36V36H12Z" fill="none" data-name="Path 128" />
    <circle cx="38" cy="10" r="10" fill="#c2c2c2" data-name="Ellipse 5" />
    <path d="m45.5 5.8-10 10-4.7-4.6 1.5-1.4 3.2 3.2 8.6-8.6z" fill="#fff" data-name="Path 153" />
  </svg>

export const AssignedOrganizer = () =>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="48" height="48">
    <g data-name="invisible background">
      <rect width="48" height="48" opacity="0" />
    </g>
    <path d="M24,4A20,20,0,1,1,4,24,20,20,0,0,1,24,4Z" fill="#fff" />
    <path d="M30.92,18a1.5,1.5,0,0,0-1.42-1h-11a1.5,1.5,0,0,0-1.42,1L15,24v8a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V31H30v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V24ZM18.5,28A1.5,1.5,0,1,1,20,26.5,1.5,1.5,0,0,1,18.5,28Zm11,0A1.5,1.5,0,1,1,31,26.5,1.5,1.5,0,0,1,29.5,28ZM17,23l1.5-4.5h11L31,23Z" fill="#82a9f6" data-name="Path 127" />
    <path d="M12,12H36V36H12Z" fill="none" data-name="Path 128" />
  </svg>

export const RideEnded = () =>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="48" height="48">
    <g data-name="invisible background">
      <rect width="48" height="48" opacity="0" />
    </g>
    <circle cx="24" cy="24" r="18" fill="#fff" />
    <path d="M24,4A20,20,0,1,0,44,24,20,20,0,0,0,24,4ZM20,34,10,24l2.82-2.82L20,28.34,35.18,13.16,38,16Z" fill="#00db00" data-name="Path 95" />
  </svg>

export const Cancelled = () =>
  <svg id="IconStatus_OrderCancelled" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <circle id="ellipse" cx="20" cy="20" r="20" fill="#fff" />
    <g id="cancel-24px" transform="translate(8 8)">
      <path id="Path_109" data-name="Path 109" d="M0,0H24V24H0Z" fill="none" opacity="0.87" />
      <path id="Path_110" data-name="Path 110" d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20ZM15.59,7,12,10.59,8.41,7,7,8.41,10.59,12,7,15.59,8.41,17,12,13.41,15.59,17,17,15.59,13.41,12,17,8.41Z" fill="#ff003d" />
    </g>
  </svg>
