import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Grid, Typography, Button, makeStyles,
    Box, Checkbox, FormControlLabel, Link,
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
    }
}))

const ConfirmOrderModal = (props) => {
    const [requestEmailConfirmation, setRequestEmailConfirmation] = useState(false)
    const classes = useStyles()

    return (
        <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="space-between"
            className={classes.container}
        >
            <Grid item>
                <Box fontWeight="fontWeightBold">
                    <FormattedMessage id="confirm_modal_confirm_order" />
                </Box>
            </Grid>
            <Grid item>
                <Typography>
                    <FormattedMessage id="confirm_modal_cancel_text_part1" />
                    &nbsp;
                    {props.timeLimitHoursForOrderCancellation}
                    &nbsp;
                    <FormattedMessage id="confirm_modal_cancel_text_part2" />
                    &nbsp;
                    {props.timeLimitHoursForOrderModification}
                    &nbsp;
                    <FormattedMessage id="confirm_modal_cancel_text_part3" />
                </Typography>
            </Grid>
            <Grid item>
                <Typography>
                    <FormattedMessage id="confirm_modal_handle_orders_fast" />
                </Typography>
            </Grid>
            <Grid item>
                <Typography>
                    <FormattedMessage id="confirm_accept_policy_on_send_partial" />
                    &nbsp;
                    <Link href="/usagepolicy" underline="always" target="_blank">
                        <FormattedMessage id="confirm_usage_policy_link_title" />
                    </Link>
                    .
                </Typography>
            </Grid>
            <Grid item>
                <FormControlLabel
                    value="requestEmailConf"
                    checked={requestEmailConfirmation}
                    value={requestEmailConfirmation}
                    control={<Checkbox />}
                    data-test-id="requestEmailConfirmationCheckbox"
                    label={<FormattedMessage id="confirm_modal_email_checbox_label" />}
                    labelPlacement="end"
                    onChange={(e) => setRequestEmailConfirmation(e.target.checked)}
                />
            </Grid>
            <Grid item container justify="flex-end">
                <Button
                    data-test-id="sendOrderButton"
                    color="secondary"
                    onClick={() => props.onConfirmClick(requestEmailConfirmation)}
                >
                    <FormattedMessage id="confirm_modal_order_button" />
                </Button>
                <Button
                    data-test-id="cancelSendOrderButton"
                    color="secondary"
                    onClick={props.onCloseClick}
                >
                    <FormattedMessage id="back" />
                </Button>
            </Grid>
        </Grid>
    )
}

ConfirmOrderModal.propTypes = {
    timeLimitHoursForOrderModification: PropTypes.number,
    timeLimitHoursForOrderCancellation: PropTypes.number,
    onCloseClick: PropTypes.func,
    onConfirmClick: PropTypes.func,
}

export default ConfirmOrderModal