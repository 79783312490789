import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { fi } from 'date-fns/locale'
import { enUS } from 'date-fns/locale'
import { IntlContext } from '../utils/IntlProviderWrapper';
import { useContext } from 'react'
const capitalizeFirstLetter = (value) => value.charAt(0).toUpperCase() + value.substring(1)

const formatDateReadableFI = (date, currentLocale) => format(new Date(date), 'EEEEEE dd.MM.yyyy', { locale: currentLocale === 'fi' ? fi : enUS })

const DayDateTitle = ({ date }) => {
    const currentLocale = useContext(IntlContext).locale;
    return (
        <Grid item xs={12}>
            <Typography color="secondary">
                <b>{capitalizeFirstLetter(formatDateReadableFI(date, currentLocale))}</b>
            </Typography>
        </Grid>
    )
}

export default DayDateTitle

DayDateTitle.propTypes = {
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
}