import React, { useState } from 'react'
import { InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { format } from 'date-fns'

const useStyles = makeStyles(() => ({
    dropdown: {
        minWidth: '200px',
    },
}))

const PricingListsDropdown = ({ pricingLists, onChange }) => {
    const classes = useStyles()
    const [selectedItem, setSelectedItem] = useState('')

    const items = pricingLists.map((list) => {
        const {
            id,
            name,
            startDate,
            endDate,
        } = list
        return (
            <MenuItem
                value={id}
                key={id}
            >
                {name}
                &nbsp;
                {format(new Date(startDate), 'dd.MM.yyyy')}
                &nbsp;-&nbsp;
                {format(new Date(endDate), 'dd.MM.yyyy')}
            </MenuItem>)
    })

    return (
        <>
            <InputLabel id="price-list-label" shrink><FormattedMessage id="pricing_select_list" /></InputLabel>
            <Select
                className={classes.dropdown}
                labelId="price-list-label"
                id="price-list-select"
                value={selectedItem}
                onChange={(event) => {
                    setSelectedItem(event.target.value)
                    onChange(event.target.value)
                }}
            >
                {items}
            </Select>
        </>
    )
}

PricingListsDropdown.propTypes = {
    pricingLists: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        vatPercent: PropTypes.number.isRequired,
    })),
    onChange: PropTypes.func.isRequired,
}

PricingListsDropdown.defaultProps = {
    pricingLists: [],
}

export default PricingListsDropdown
