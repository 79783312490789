import {
    useEffect, useContext,
} from 'react'
import PropTypes from 'prop-types'
import ConfigurationContext from '../context/ConfigurationContext'

const GoogleScriptLoader = ({ children }) => {
    const gmapsKey = useContext(ConfigurationContext)?.gmapsKey;

    const loadGoogleApiScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src =
            `https://maps.googleapis.com/maps/api/js?key=${gmapsKey}&libraries=places`
        document.body.appendChild(script)
    }

    useEffect(() => {
        if (gmapsKey) {
            loadGoogleApiScript()
        }
    }, [gmapsKey])

    return (
        gmapsKey && children
    )
}

GoogleScriptLoader.propTypes = {
    children: PropTypes.node,
}

GoogleScriptLoader.defaultProps = {
    children: undefined,
}

export default GoogleScriptLoader
