import React, { useState, useEffect } from 'react'
import {
    makeStyles, Grid, Box, Typography, Tabs, Tab,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl'
import HolidaysTable from './HolidaysTable'
import PricingTable from './PricingTable'
import PricingTester from './PricingTester'
import PriceListsTable from './PriceListsTable'
import PricingListsDropdown from './PricingListsDropdown'
import { emptyGuid } from '../../utils/constants'

const useStyles = makeStyles((theme) => ({
    page: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
            left: '0',
            minWidth: '320px',
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            width: '100%',
            transform: 'translateX(-50%)',
        },
        top: '60px',
    },
    container: {
        borderRadius: '2px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: '320px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        backgroundColor: '#FFF',
        margin: '0 !important',
        boxShadow: '0px 3px 6px #00000029',
        padding: '1em',
    },
    items: {
        color: '#000',
        width: '100%',
    },
    form: {
        margin: '0 !important',
    },

}))

const emptyReceipt = {
    items: [],
    total: 0.0,
    minimumFee: 0.0,
}

const PricingComponents = () => {
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()
    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedList, setSelectedList] = useState()
    const [holidays, setHolidays] = useState([])
    const [customers, setCustomers] = useState([])
    const [serviceProviders, setServiceProviders] = useState([])
    const [priceComponents, setPriceComponents] = useState([])
    const [priceLists, setPriceLists] = useState([])
    const [receipt, setReceipt] = useState(emptyReceipt)

    const loadHolidays = () => {
        fetch('api/holidays')
            .then((results) => {
                if (!results.ok) {
                    throw Error(results.status)
                }
                return results.json()
            }).then((data) => {
                setHolidays(data)
            }).catch(() => {
                enqueueSnackbar(<FormattedMessage id="error_occurred" />, { variant: 'error' })
            })
    }

    const loadOrganizations = () => {
        fetch('api/organizations')
            .then((results) => {
                if (!results.ok) {
                    throw Error(results.status)
                }
                return results.json()
            }).then((data) => {
                const providerNames = {}
                const customerNames = {}
                providerNames[emptyGuid] = '-'
                customerNames[emptyGuid] = '-'
                data.map((org) => {
                    if (parseInt(org.type, 10) === 1) {
                        providerNames[org.id] = org.name
                    } else {
                        customerNames[org.id] = org.name
                    }
                })
                setServiceProviders(providerNames)
                setCustomers(customerNames)
            }).catch(() => {
                enqueueSnackbar(<FormattedMessage id="error_occurred" />, { variant: 'error' })
            })
    }

    const loadPriceComponents = (listId) => {
        fetch(`api/pricelists/${listId}/pricecomponents`)
            .then((results) => {
                if (!results.ok) {
                    throw Error(results.status)
                }
                return results.json()
            }).then((data) => {
                setPriceComponents(data)
            }).catch(() => {
                enqueueSnackbar(<FormattedMessage id="error_occurred" />, { variant: 'error' })
            })
    }

    const loadPriceLists = () => {
        fetch('api/pricelists')
            .then((results) => {
                if (!results.ok) {
                    throw Error(results.status)
                }
                return results.json()
            }).then((data) => {
                setPriceLists(data)
            }).catch(() => {
                enqueueSnackbar(<FormattedMessage id="error_occurred" />, { variant: 'error' })
            })
    }

    useEffect(() => {
        loadHolidays()
        loadPriceLists()
        loadOrganizations()
    }, [])

    const addHoliday = (holiday) => {
        fetch('/api/holidays/', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
            body: JSON.stringify(holiday),
          }).then((response) => {
            if (!response.ok) {
              throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="save_succeed" />, { variant: 'success' })
            loadHolidays()
          }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: 'error' })
          })
    }

    const updateHoliday = (holiday) => {
        fetch(`/api/holidays/${holiday.id}`, {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
            body: JSON.stringify(holiday),
          }).then((response) => {
            if (!response.ok) {
              throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="save_succeed" />, { variant: 'success' })
            loadHolidays()
          }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: 'error' })
          })
    }

    const deleteHoliday = (holiday) => {
        fetch(`/api/holidays/${holiday.id}`, {
            method: 'DELETE',
          }).then((response) => {
            if (!response.ok) {
              throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="delete_succeed" />, { variant: 'success' })
            loadHolidays()
          }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="delete_failed" />, { variant: 'error' })
          })
    }

    const addPriceComponent = (price) => {
        fetch(`/api/pricelists/${selectedList}/pricecomponents/`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
            body: JSON.stringify(price),
          }).then((response) => {
            if (!response.ok) {
              throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="save_succeed" />, { variant: 'success' })
            loadPriceComponents(selectedList)
          }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: 'error' })
          })
    }

    const updatePriceComponent = (price) => {
        fetch(`/api/pricelists/${selectedList}/pricecomponents/${price.id}`, {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
            body: JSON.stringify(price),
          }).then((response) => {
            if (!response.ok) {
              throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="save_succeed" />, { variant: 'success' })
            loadPriceComponents(selectedList)
          }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: 'error' })
          })
    }

    const deletePriceComponent = (price) => {
        fetch(`/api/pricelists/${selectedList}/pricecomponents/${price.id}`, {
            method: 'DELETE',
          }).then((response) => {
            if (!response.ok) {
              throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="delete_succeed" />, { variant: 'success' })
            loadPriceComponents(selectedList)
          }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="delete_failed" />, { variant: 'error' })
          })
    }

    const calculateReceipt = (
            categoryId,
            startDate,
            endDate,
            rideLength,
            waitingMinutes,
            isHourlyDrive,
            terminalPickup,
            serviceProviderId,
            customerId,
    ) => {
        if (categoryId === emptyGuid || categoryId === '') {
            enqueueSnackbar(<FormattedMessage id="pricing_category_not_set" />, { variant: 'warning' })
            return
        }

        // Remove seconds & millis from dates
        const start = startDate
        start.setSeconds(0, 0)
        const end = endDate
        end.setSeconds(0, 0)

        const queryString = `vehicleCategoryId=${categoryId}&` +
        `startTime=${start.toISOString()}&` +
        `endTime=${end.toISOString()}&` +
        `rideLengthKm=${rideLength}&` +
        `waitingMinutes=${waitingMinutes}&` +
        `isHourDrive=${isHourlyDrive}&` +
        `terminalPickup=${terminalPickup}&` +
        `serviceProviderId=${serviceProviderId}&` +
        `customerId=${customerId}`

        fetch(`api/pricing/estimate?${queryString}`)
        .then((results) => {
            if (!results.ok) {
                throw Error(results.status)
            }
            if (results.status === 204) {
                enqueueSnackbar(<FormattedMessage id="pricing_not_found" />, { variant: 'warning' })
                return emptyReceipt
            }
            return results.json()
        }).then((data) => {
            setReceipt(data)
        }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="error_occurred" />, { variant: 'error' })
        })
    }

    const addPriceList = (price) => {
        fetch('/api/pricelists/', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
            body: JSON.stringify(price),
          }).then((response) => {
            if (!response.ok) {
              throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="save_succeed" />, { variant: 'success' })
            loadPriceLists()
          }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: 'error' })
          })
    }

    const updatePriceList = (price) => {
        fetch(`/api/pricelists/${price.id}`, {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
            body: JSON.stringify(price),
          }).then((response) => {
            if (!response.ok) {
              throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="save_succeed" />, { variant: 'success' })
            loadPriceLists()
          }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: 'error' })
          })
    }

    const deletePriceList = (price) => {
        fetch(`/api/pricelists/${price.id}`, {
            method: 'DELETE',
          }).then((response) => {
            if (!response.ok) {
              throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="delete_succeed" />, { variant: 'success' })
            loadPriceLists()
          }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="delete_failed" />, { variant: 'error' })
          })
    }

    const duplicatePriceList = (copiedLists) => {
        copiedLists.map((price) => {
            fetch(`/api/pricelists/${price.id}/duplicate`, {
                method: 'PUT',
            }).then((response) => {
                if (!response.ok) {
                throw Error(response.status)
                }
                enqueueSnackbar(<FormattedMessage id="duplicate_succeed" />, { variant: 'success' })
                loadPriceLists()
            }).catch(() => {
                enqueueSnackbar(<FormattedMessage id="error_occurred" />, { variant: 'error' })
            })
        })
    }

    return (
        <div className={classes.page}>
            <Grid container justify="center" alignContent="center" alignItems="center" direction="column" spacing={4} className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant="h3"><FormattedMessage id="pricing_title" /></Typography>
                    <Box m={1} />
                </Grid>
                <Grid item xs={12}>
                    <Tabs
                        value={selectedTab}
                        onChange={(event, newValue) => { setSelectedTab(newValue) }}
                    >
                        <Tab label={<FormattedMessage id="pricing_tab_pricing_lists" />} />
                        <Tab label={<FormattedMessage id="pricing_tab_price_components" />} />
                        <Tab label={<FormattedMessage id="pricing_tab_holidays" />} />
                        <Tab label={<FormattedMessage id="pricing_tab_tester" />} />
                    </Tabs>
                </Grid>
                { selectedTab === 0 &&
                    <Grid item xs={12} className={classes.items}>
                        <PriceListsTable
                            priceLists={priceLists}
                            serviceProviders={serviceProviders}
                            customers={customers}
                            addPriceList={(priceList) => addPriceList(priceList)}
                            updatePriceList={(priceList) => updatePriceList(priceList)}
                            deletePriceList={(priceList) => deletePriceList(priceList)}
                            duplicatePriceList={(priceList) => duplicatePriceList(priceList)}
                        />
                    </Grid> }
                { selectedTab === 1 &&
                    <Grid item container xs={12} spacing={4} className={classes.items} alignItems="center" direction="column" alignContent="center">
                        <PricingListsDropdown
                            pricingLists={priceLists}
                            onChange={(id) => {
                                setSelectedList(id)
                                loadPriceComponents(id)
                            }}
                        />
                        <Box m={2} />
                        <PricingTable
                            priceComponents={priceComponents}
                            addPriceComponent={(price) => addPriceComponent(price)}
                            updatePriceComponent={(price) => updatePriceComponent(price)}
                            deletePriceComponent={(price) => deletePriceComponent(price)}
                        />
                    </Grid> }
                { selectedTab === 2 &&
                    <Grid item xs={12} className={classes.items}>
                        <HolidaysTable
                            holidays={holidays}
                            addHoliday={(holiday) => addHoliday(holiday)}
                            updateHoliday={(holiday) => updateHoliday(holiday)}
                            deleteHoliday={(holiday) => deleteHoliday(holiday)}
                        />
                    </Grid> }
                { selectedTab === 3 &&
                    <Grid item xs={12}>
                        <PricingTester
                            calculateReceipt={(
                                categoryId,
                                startDate,
                                endDate,
                                rideLength,
                                waitingMinutes,
                                isHourlyDrive,
                                terminalPickup,
                                serviceProviderId,
                                customerId,
                                ) => calculateReceipt(
                                    categoryId,
                                    startDate,
                                    endDate,
                                    rideLength,
                                    waitingMinutes,
                                    isHourlyDrive,
                                    terminalPickup,
                                    serviceProviderId,
                                    customerId,
                                )}
                            receipt={receipt}
                        />
                    </Grid> }
            </Grid>
        </div>
    )
}

export default PricingComponents
