import React, {
    useState, useContext, useEffect, useCallback,
} from 'react'
import {
    makeStyles, Grid, Box, Button, Typography,
    TextField, InputLabel, Select, MenuItem,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import OrganizationsDropdown from './OrganizationDropdown'
import UsersList from './UsersList'

const useStyles = makeStyles((theme) => ({
  page: {
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      margin: '0 !important',
      left: '0',
      minWidth: '320px',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
    top: '60px',
  },
  container: {
    borderRadius: '2px',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: '320px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
    backgroundColor: '#FFF',
    margin: '0 !important',
    boxShadow: '0px 3px 6px #00000029',
    padding: '1em',
  },
  invitationForm: {
    padding: '1em',
    borderRadius: '2px',
    backgroundColor: '#F8F6F1',
    margin: '0 !important',
    boxShadow: '0px 3px 6px #00000029',
  },
  items: {
    color: '#000',
    width: '100%',
  },
  form: {
    margin: '0 !important',
  },

}))

const Users = () => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const { register, handleSubmit } = useForm()
  const { register: register2, handleSubmit: handleSubmit2 } = useForm()
  const [users, setUsers] = useState([])
  const [selectedOrgId, setSelectedOrgId] = useState(null)
  const [selectedOrgType, setSelectedOrgType] = useState(null)
  const [showInvitationForm, setShowInvitationForm] = useState(false)
  const [role, setRole] = useState(undefined)
  const { oidcUser } = useContext(AuthenticationContext)
  const [billingEmailAddress, setBillingEmailAddress] = useState('')
  const [billingAddress, setBillingAddress] = useState('')
  const [billingCompanyIdentifier, setBillingCompanyIdentifier] = useState('')

  const fetchOrgUsers = useCallback((org) => {
    fetch(`api/organizations/${org.id}/users`)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.status)
        }
        return results.json()
      }).then((data) => {
        setUsers(data)
      }).catch(() => {
        enqueueSnackbar(<FormattedMessage id="users_load_failed" />, { variant: 'error' })
      })
  })

  const fetchOrgBillingInfo = useCallback((org) => {
      fetch(`api/organizations/${org.id}/billinginfo`)
      .then((results) => {
          if (!results.ok) {
              throw Error(results.status)
          }
          return results.json()
      }).then((data) => {
        setBillingAddress(data.billingAddress || '')
        setBillingEmailAddress(data.billingEmailAddress || '')
        setBillingCompanyIdentifier(data.billingCompanyIdentifier || '')
      }).catch(() => {
          enqueueSnackbar(<FormattedMessage id="error_occurred" />, { variant: 'error' })
      })
  })

  const handleOrganizationChange = (org) => {
    if (org?.id?.length && org?.id !== selectedOrgId) {
      setSelectedOrgId(org.id)
      setSelectedOrgType(org.type)
      fetchOrgUsers(org)
      if (org.type === 0) {
        fetchOrgBillingInfo(org)
      }
    }
  }

  const getOrgType = () => {
    return ['Customer', 'CustomerAdmin'].includes(oidcUser?.profile?.role) ? 0 : 1
  }

  useEffect(() => {
    //initialize selectedOrgType
    setSelectedOrgType(getOrgType())
  }, [])
  useEffect(() => {
    const orgType = getOrgType()
    fetchOrgUsers({ id: oidcUser.profile.org, type: orgType })
    if (orgType === 0) {
      setRole('Customer')
    } else {
      setRole('Driver')
    }
  }, [])

  const showInvite = () => {
    setShowInvitationForm(true)
  }

  const hideInvite = () => {
    setShowInvitationForm(false)
  }

  const onSubmit = (data, evt) => {
    evt.preventDefault()
    const invitation = {
      InvitedUserEmail: data.inviteEmail,
      InvitedUserRole: role,
    }

    evt.target.reset()

    fetch(`/api/organizations/${selectedOrgId}/users/invite`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(invitation),
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.status)
      }
      let alreadyInList = false
      for (let i = 0; i < users.length; i++) {
        if (users[i].email && users[i].email === invitation.InvitedUserEmail) {
          alreadyInList = true
          break
        }
      }
      if (!alreadyInList) {
        fetchOrgUsers({ id: selectedOrgId, type: selectedOrgType })
      }
      hideInvite()
      enqueueSnackbar(<FormattedMessage id="sent" />, { variant: 'success' })
    }).catch(() => {
      enqueueSnackbar(<FormattedMessage id="error_occurred" />, { variant: 'error' })
    })
  }

  const onBillingSubmit = (data, evt) => {
    evt.preventDefault()
    const orgData = {
        billingEmailAddress: data.billingEmailAddress,
        billingAddress: data.billingAddress,
        billingCompanyIdentifier: data.billingCompanyIdentifier,
    }
    fetch(`/api/organizations/${selectedOrgId}/billinginfo`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(orgData),
    }).then((response) => {
        if (!response.ok) {
            throw Error(response.status)
        }
        enqueueSnackbar(<FormattedMessage id="save_succeed" />, { variant: 'success' })
    }).catch(() => {
        enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: 'error' })
    })
  }

  return (
    <div className={classes.page}>
      <Grid container justify="center" direction="column" spacing={4} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h5"><FormattedMessage id="users_management_title" /></Typography>
          <Box m={4} />
          <OrganizationsDropdown
            handleOrgChange={(org) => handleOrganizationChange({ id: org.id, type: org.type })}
            defaultOrg={oidcUser?.profile?.org}
            hideOtherServiceProviders={oidcUser?.profile?.role === 'Organizer'}
          />
        </Grid>
        {(selectedOrgType === 0) &&
        <>
        <div className={classes.invitationForm}>
        <Grid item xs={12}>
            <Typography variant="h6"><FormattedMessage id="users_invoicing_info" /></Typography>
        </Grid>
        <form onSubmit={handleSubmit2(onBillingSubmit)}>
            <Grid container direction="column" spacing={4}>
                <Grid item xs={12}>
                    <TextField
                        {...register2('billingEmailAddress', { required: true })}
                        fullWidth
                        id="billingEmailAddress"
                        name="billingEmailAddress"
                        type="email"
                        value={billingEmailAddress}
                        onChange={(e) => setBillingEmailAddress(e.target.value)}
                        label={<FormattedMessage id="users_billing_email" />}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register2('billingAddress', { required: false })}
                        rows={4}
                        rowsMax={8}
                        fullWidth
                        multiline
                        id="billingAddress"
                        name="billingAddress"
                        value={billingAddress}
                        onChange={(e) => setBillingAddress(e.target.value)}
                        label={<FormattedMessage id="users_billing_street_address" />}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register2('billingCompanyIdentifier', { required: false })}
                        fullWidth
                        id="billingCompanyIdentifier"
                        name="billingCompanyIdentifier"
                        value={billingCompanyIdentifier}
                        onChange={(e) => setBillingCompanyIdentifier(e.target.value)}
                        label={<FormattedMessage id="users_billing_company_identifier" />}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        fullWidth
                        label={<FormattedMessage id="save" />}
                        color="secondary"
                        variant="outlined"
                    >
                        <FormattedMessage id="save" />
                    </Button>
                    <Box m={4} />
                </Grid>
            </Grid>
        </form>
        </div>
        </> }

        <Grid item xs={12}>
            <Typography variant="h6"><FormattedMessage id="users_users" /></Typography>
        </Grid>
        <Grid hidden={users.length === 0} item xs={12} className={classes.items}>
          <UsersList selectedOrgType={selectedOrgType} users={users} />
        </Grid>
        <Grid item xs={12}>
          <Button id="inviteUserButton" hidden={showInvitationForm || !selectedOrgId} onClick={showInvite} variant="outlined"><FormattedMessage id="users_invite_new_user" /></Button>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Grid container direction="column" hidden={!showInvitationForm} className={classes.invitationForm}>
            <Grid item xs={12}>
              <TextField
                {...register('inviteEmail', { required: true })}
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="inviteEmail"
                name="inviteEmail"
                type="email"
                label={<FormattedMessage id="users_invitation_email" />}
                helperText=""
              />
            </Grid>
            <Grid item xs={5}>
              <Box m={2} />
              <InputLabel id="role-select-label" shrink>
                <FormattedMessage id="users_role" />
              </InputLabel>
              {(selectedOrgType === 0) &&
                <Select
                  labelId="role-select-label"
                  id="role"
                  defaultValue="Customer"
                  value={role}
                  onChange={(event) => setRole(event.target.value)}
                  fullWidth
                >
                  <MenuItem value="Customer" key="Customer"><FormattedMessage id="users_role_customer" /></MenuItem>
                  <MenuItem value="CustomerAdmin" key="CustomerAdmin"><FormattedMessage id="users_role_customeradmin" /></MenuItem>
                </Select> }
              {(selectedOrgType === 1) &&
                <Select
                  labelId="role-select-label"
                  id="role"
                  defaultValue="Driver"
                  value={role}
                  onChange={(event) => setRole(event.target.value)}
                  fullWidth
                >
                  <MenuItem value="Driver" key="Driver"><FormattedMessage id="users_role_driver" /></MenuItem>
                  <MenuItem value="Organizer" key="Organizer"><FormattedMessage id="users_role_organizer" /></MenuItem>
                  <MenuItem value="Admin" key="Admin"><FormattedMessage id="users_role_admin" /></MenuItem>
                </Select> }
            </Grid>
            <Grid item xs={5}>
                <Box m={2} />
                <Button
                    type="submit"
                    fullWidth
                    id="save"
                    label={<FormattedMessage id="users_send_invite" />}
                    color="secondary"
                    variant="outlined"
                >
                    <FormattedMessage id="users_send_invite" />
                </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  )
}

export default Users
