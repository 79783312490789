import React, { useState } from "react";
import { makeStyles, TextField, Grid, Button, Box, CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme)=>({
  page: {
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      margin: '0 !important',
      left: '0',
      minWidth: '320px',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {      
      left: '50%',
      transform: 'translateX(-50%)',
    },
    top: '80px',
  }
}));

export default function ForgotPassword(props) {
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const classes = useStyles();

  const onSubmit = (data, evt) => {
    evt.preventDefault();

    let details = {
      emailAddress: data.emailAddress,
    }

    setSendingEmail(true);
    fetch("/api/users/forgot-password",{
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json"
      },
      body: JSON.stringify(details),
    }).then(results => {
      if (results.redirected) {
        window.location.href = results.url;
      }      
      if(!results.ok) {
          setSendingEmail(false);
          throw Error(results.status);
      }
      setEmailSent(true);
      setSendingEmail(false);
      enqueueSnackbar(<FormattedMessage id="login_forgot_email_sent"/>, {variant: "success"});
    }).catch(error => {
      setSendingEmail(false);
      setEmailSent(false);
      console.log(error);
    });

  }  

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box m={6}/>
        <Grid 
            container 
            direction="column"
            justify="center"
            alignItems="center" 
            spacing={4}
            className={classes.page}
            >
          <Grid item xs={12} hidden={emailSent}>
            <h2><FormattedMessage id="login_forgot_password_title" /></h2>
            <Box m={4} />
          </Grid>
          <Grid item xs={12} hidden={emailSent}>
            <FormattedMessage id="login_forgot_instructions" />
          </Grid>
          <Grid item xs={12} hidden={emailSent}>
            <Box width={300}/>
            <TextField 
                {...register('emailAddress', { 
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: <FormattedMessage id="login_invalid_email"/>
                  }
                })}
                InputLabelProps={{shrink:true}}
                fullWidth={true} 
                id="emailAddress"
                name="emailAddress"
                error={Boolean(errors.emailAddress)}
                helperText={errors.emailAddress && errors.emailAddress.message}
                label={<FormattedMessage id="login_username"/>}
                inputProps={{"data-test-id":"login-username"}}></TextField>
          </Grid>
          <Grid item xs={12}>
            <Box width={300}/>
            {(!sendingEmail && !emailSent) &&
              <Button 
                type="submit"
                fullWidth={true}
                id="send-email-button"
                data-test-id="send-email-button"                
                variant="outlined">
                  <FormattedMessage id="login_forgot_send_email"/>
              </Button>
            }
            {(sendingEmail && !emailSent) && <CircularProgress size={24}/>}
          </Grid>
          <Grid item xs={12}>            
            {emailSent && <FormattedMessage id="login_forgot_check_inbox"/>}
          </Grid>
        </Grid>
      </form>        
    </div>
  );
}
