import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
    Grid, TextField, Checkbox, FormControlLabel, InputAdornment,
    Typography, Button, makeStyles, CircularProgress,
} from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { FormattedMessage } from 'react-intl'
import VehicleCategoriesDropdown from '../VehicleCategoriesDropdown'
import OrganizationsDropdown from '../OrganizationDropdown'
import { emptyGuid } from '../../utils/constants'

const useStyles = makeStyles(() => ({
    headerRow: {
        borderBottom: '1px solid black',
    },
    minimumFeeRow: {
        color: 'darkgray',
    },
    totalRow: {
        borderTop: '1px solid black',
        fontWeight: 'bold',
    },
}))

const PricingTester = (props) => {
    const classes = useStyles()
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [rideLength, setRideLength] = useState(0)
    const [waitingMinutes, setWaitingMinutes] = useState(0)
    const [categoryId, setCategoryId] = useState('')
    const [isHourlyDrive, setIsHourlyDrive] = useState(false)
    const [terminalPickup, setTerminalPickup] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [customerId, setCustomerId] = useState(emptyGuid)
    const [serviceProviderId, setServiceProviderId] = useState(emptyGuid)

    const refreshTimer = useRef(false)
    const currencyIntl = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' })

    const refreshReceipt = () => {
        setIsLoading(true)
        clearInterval(refreshTimer.current)

        refreshTimer.current = setInterval(
            () => {
                props.calculateReceipt(
                    categoryId,
                    startDate,
                    endDate,
                    rideLength,
                    waitingMinutes,
                    isHourlyDrive,
                    terminalPickup,
                    serviceProviderId,
                    customerId,
                )
                setIsLoading(false)
                clearInterval(refreshTimer.current)
            },
            1000,
        )
    }

    const handleCategoryChange = (catId) => {
        setCategoryId(catId)
    }

    const { receipt } = props

    return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <Typography variant="h4"><FormattedMessage id="pricing_test_ride" /></Typography>

                <VehicleCategoriesDropdown
                    handleCategoryChange={(id) => handleCategoryChange(id)}
                />

                <KeyboardDateTimePicker
                    value={startDate}
                    label={<FormattedMessage id="schedules_start" />}
                    fullWidth
                    ampm={false}
                    onChange={(value) => setStartDate(value)}
                    format="dd.MM.yyyy HH:mm"
                />
                <KeyboardDateTimePicker
                    value={endDate}
                    fullWidth
                    ampm={false}
                    format="dd.MM.yyyy HH:mm"
                    onChange={(value) => setEndDate(value)}
                    label={<FormattedMessage id="schedules_end" />}
                />
                <TextField
                    label={<FormattedMessage id="pricing_ride_length" />}
                    value={rideLength}
                    fullWidth
                    onChange={(e) => setRideLength(e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">km</InputAdornment>,
                    }}
                />
                <TextField
                    label={<FormattedMessage id="pricing_waiting_time" />}
                    value={waitingMinutes}
                    fullWidth
                    onChange={(e) => setWaitingMinutes(e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">min</InputAdornment>,
                    }}
                />
                <FormControlLabel
                    label={<FormattedMessage id="priceitem_hourly_drive" />}
                    defaultChecked={isHourlyDrive}
                    onChange={(e) => setIsHourlyDrive(e.target.checked)}
                    control={<Checkbox />}
                />
                <FormControlLabel
                    label={<FormattedMessage id="priceitem_terminal_pickup_fee" />}
                    defaultChecked={terminalPickup}
                    onChange={(e) => setTerminalPickup(e.target.checked)}
                    control={<Checkbox />}
                />
                <OrganizationsDropdown
                      id="orderOwner"
                      orgType="ServiceProvider"
                      customLabel="org_customer_order_owner"
                      showEmptyOption
                      handleOrgChange={(org) => setServiceProviderId(org.id)}
                />
                <OrganizationsDropdown
                      id="customer"
                      orgType="Customer"
                      customLabel="pricing_customer"
                      showEmptyOption
                      handleOrgChange={(org) => setCustomerId(org.id)}
                />

            </Grid>
            <Grid container item xs={6} alignItems="center" direction="column" alignContent="center">
                <Typography variant="h4"><FormattedMessage id="pricing_test_invoice" /></Typography>
                <Button
                    onClick={() => refreshReceipt()}
                    variant="outlined"
                >
                    <FormattedMessage id="update" />
                </Button>
                <CircularProgress hidden={!isLoading} size={24} />
                <div>
                    <table>
                        <thead>
                            <tr className={classes.headerRow}>
                                <th colSpan={4}>
                                    {receipt.pricingDayType === 0 && <FormattedMessage id="pricing_weekday_ride" />}
                                    {receipt.pricingDayType === 1 && <FormattedMessage id="pricing_weekend_or_holiday_ride" />}
                                    <br />
                                    <FormattedMessage id="pricing_start_time" />
                                    &nbsp;
                                    {receipt.priceListStartHour}-{receipt.priceListEndHour}
                                </th>
                            </tr>
                            <tr className={classes.headerRow}>
                                <th><FormattedMessage id="pricing_item" /></th>
                                <th><FormattedMessage id="pricing_quantity" /></th>
                                <th><FormattedMessage id="pricing_unit_price" /></th>
                                <th><FormattedMessage id="pricing_total" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {receipt &&
                            <>
                            {receipt.rows?.map((item) => {
                                const nameCodes = [
                                    'priceitem_base_fee',
                                    'priceitem_hourlydrivebase_fee',
                                    'priceitem_km_fee',
                                    'priceitem_waiting_fee',
                                    'priceitem_hourly_drive',
                                    'priceitem_terminal_pickup_fee',
                                ]
                                if(item.chargeType === 5) {
                                    return
                                }
                                return (
                                    <tr key={item.id}>
                                        <td><FormattedMessage id={nameCodes[item.chargeType]} /></td>
                                        <td>
                                            {Math.round(item.quantity * 100) / 100}
                                            &nbsp;
                                            {(item.unitOfMeasure.length > 0) && item.unitOfMeasure}
                                        </td>
                                        <td align="right">
                                            {currencyIntl.format(item.unitPrice.toFixed(2))}
                                            {(item.unitOfMeasure.length > 0) &&
                                            <>
                                                /
                                                {item.unitOfMeasure}
                                            </>}
                                        </td>
                                        <td align="right">{currencyIntl.format(Math.round(item.total * 100) / 100)}</td>
                                    </tr>
                                )
                                })}
                                <tr className={classes.minimumFeeRow}>
                                    <td>
                                        <FormattedMessage id="pricing_minimal_fee" />
                                        &nbsp;
                                        {currencyIntl.format(receipt.minimumPrice)}
                                    </td>
                                    <td colSpan={3} />
                                </tr>
                                {receipt.rows?.map((item) => {
                                    const nameCodes = [
                                        'priceitem_base_fee',
                                        'priceitem_hourlydrivebase_fee',
                                        'priceitem_km_fee',
                                        'priceitem_waiting_fee',
                                        'priceitem_hourly_drive',
                                        'priceitem_terminal_pickup_fee',
                                    ]
                                    if(item.chargeType === 5) {
                                        return(
                                            <tr key={item.id}>
                                                <td><FormattedMessage id={nameCodes[item.chargeType]} /></td>
                                                <td>
                                                    {Math.round(item.quantity * 100) / 100}
                                                    &nbsp;
                                                    {(item.unitOfMeasure.length > 0) && item.unitOfMeasure}
                                                </td>
                                                <td align="right">
                                                    {currencyIntl.format(item.unitPrice.toFixed(2))}
                                                    {(item.unitOfMeasure.length > 0) &&
                                                    <>
                                                        /
                                                        {item.unitOfMeasure}
                                                    </>}
                                                </td>
                                                <td align="right">{currencyIntl.format(Math.round(item.total * 100) / 100)}</td>
                                            </tr>
                                        )
                                    } else {
                                        return
                                    }
                                })}

                                <tr className={classes.totalRow}>
                                    <td><FormattedMessage id="pricing_total" /></td>
                                    <td colSpan={2} />
                                    <td align="right">{currencyIntl.format(receipt.total)}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage id="pricing_total_vat" />
                                        &nbsp;({receipt.vatPercent}%)
                                    </td>
                                    <td colSpan={2} />
                                    <td align="right">{currencyIntl.format(receipt.totalVat)}</td>
                                </tr>
                            </>
                            }

                        </tbody>
                    </table>
                </div>

            </Grid>
        </Grid>
    )
}

PricingTester.propTypes = {
    calculateReceipt: PropTypes.func.isRequired,
    receipt: PropTypes.objectOf(PropTypes.shape({
        id: PropTypes.number,
        rows: PropTypes.arrayOf({
            chargeType: PropTypes.string.isRequired,
            quantity: PropTypes.number.isRequired,
            unitPrice: PropTypes.number.isRequired,
            unitOfMeasure: PropTypes.string.isRequired,
            total: PropTypes.number.isRequired,
        }),
        priceListStartHour: PropTypes.number.isRequired,
        priceListEndHour: PropTypes.number.isRequired,
        pricingDayType: PropTypes.number.isRequired,
        pricingDayTypeText: PropTypes.string.isRequired,
        minimumPrice: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        totalExclVat: PropTypes.number.isRequired,
        totalVat: PropTypes.number.isRequired,
        vatPercent: PropTypes.number.isRequired,
    })).isRequired,
}

export default PricingTester
