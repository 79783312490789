import React from 'react'
import { SvgIcon } from '@material-ui/core'
import * as statusIcon from '../Icons/Statuses'
import PropTypes from 'prop-types'
import {
    statuses,
    views
} from '../utils/constants';

const StatusIcon = ({ status, view }) => {
    const iconSwitch = (status) => ({
        [statuses.Ordered]: statusIcon.Ordered,
        [statuses.Confirmed]: statusIcon.Confirmed,
        [statuses.Assigned]: (view === views.orderer && statusIcon.AssignedOrderer) || (view === views.organizer && statusIcon.AssignedOrganizer) || (view === views.driver && statusIcon.AssignedDriver),
        [statuses.DriverConfirmed]: (view === views.orderer && statusIcon.DriverConfirmedOrderer) || ((view === views.organizer || view === views.driver) && statusIcon.DriverConfirmedOrganizerAndDriver),
        [statuses.DriverStarted]: statusIcon.DriverStarted,
        [statuses.RideStarted]: statusIcon.RideStarted,
        [statuses.RideEnded]: statusIcon.RideEnded,
        [statuses.Cancelled]: statusIcon.Cancelled,
    })[status] || 'svg'

    return (
        <SvgIcon component={iconSwitch(status)} />
    )
}

StatusIcon.propTypes = {
    status: PropTypes.oneOf(
        [
            statuses.Ordered,
            statuses.Confirmed,
            statuses.Assigned,
            statuses.DriverConfirmed,
            statuses.DriverStarted,
            statuses.RideStarted,
            statuses.RideEnded,
            statuses.Cancelled,
            888,
            999,
        ]
    ),
    view: PropTypes.oneOf([views.orderer, views.organizer, views.driver])
}

export default StatusIcon