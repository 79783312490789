import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react'
import {
  makeStyles, List, ListItem, ListItemAvatar, Avatar, ListItemText,
  Box, Divider, MenuItem, Select, CircularProgress, Grid,
} from '@material-ui/core'
import Person from '@material-ui/icons/Person'
import HourglassEmpty from '@material-ui/icons/HourglassEmpty'
import Email from '@material-ui/icons/Email'
import Phone from '@material-ui/icons/Phone'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSnackbar } from 'notistack'
import uuid from 'uuid/v4'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'

const useStyles = makeStyles({
  container: {
    backgroundColor: '#FFF',
    color: '#000',
    borderRadius: '2px',
    minWidth: '320px',
    maxWidth: '720px',
    margin: '0 !important',
  },
  divider: {
    backgroundColor: '#aaa',
  },
})


const UsersList = (props) => {
  const classes = useStyles()
  const [availableRoles, setAvailableRoles] = useState([])
  const [roleData, setRoleData] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const { oidcUser } = useContext(AuthenticationContext)

  const fetchUserRoles = useCallback(() => {
    fetch(`/api/UserRoles`)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        return enqueueSnackbar('errör', { variant: "error" })
      })
      .then((roles) => {
        setRoleData(roles)
      })
  }, [])

  useEffect(() => {
    fetchUserRoles()
  }, [])

  useEffect(() => {
    const roleDataByType = sortRolesByOrgType(props.selectedOrgType, roleData)
    setAvailableRoles(roleDataByType)
  }, [props.selectedOrgType, roleData])

  const localizeRole = (role) =>
    role === 'Admin' && <FormattedMessage id="role_admin" /> ||
    role === 'Customer' && <FormattedMessage id="role_customer" /> ||
    role === 'CustomerAdmin' && <FormattedMessage id="role_organization_admin" /> ||
    role === 'Driver' && <FormattedMessage id="role_driver" /> ||
    role === 'Organizer' && <FormattedMessage id="role_organizer" /> ||
    role === 'Disabled' && <FormattedMessage id="role_disabled" />


  const sortRolesByOrgType = (selectedOrgType, roleData) => {
    if (selectedOrgType === 0) {
      return roleData.filter(role => role === 'Customer' || role === 'CustomerAdmin')
    } else if (selectedOrgType === 1) {
      return roleData.filter(role => role === 'Admin' || role === 'Driver' || role === 'Organizer')
    }
    return []
  }

  const disableAdminRole = (role) => {
    if (role === 'Admin') {
      if (oidcUser.profile.role === 'Driver' || oidcUser.profile.role === 'Organizer') {
        return true
      }
    }
    return false
  }

  const userIcon = (user) => {
    if (!user.registered) {
      return <HourglassEmpty titleAccess="Kutsuttu" />
    }
    return <Person />
  }

  const userContactInfo = (user) => {
    let phone = <></>
    if (user.phone.trim() !== "") {
      phone = <a href={'tel:' + user.phone}><Phone /> {user.phone}</a>
    }

    return (
      <>
        <Box xs={1} />
        <a href={'mailto:' + user.email}>
          <Email /> {user.email}
        </a>
        <Box xs={1} />
        {phone}
      </>
    )
  }

  const divider = (create) => {
    if (create) {
      return <Divider variant="middle" component="li" className={classes.divider} />
    } else {
      return <></>
    }
  }

  const handleRoleChange = (userId, event) => {
    const newRoleValue = event.target.value
    setIsLoading(true)

    if (newRoleValue === 'Disabled') {
      fetch(`/api/Users/${userId}`, {
        method: 'DELETE'
      })
        .then((response) => {
          if (response.ok) {
            return enqueueSnackbar(<FormattedMessage id="users_user_disabled_success" />, { variant: "success" })
          } else {
            return enqueueSnackbar(<FormattedMessage id="users_user_disabled_error" />, { variant: "error" })
          }
        })
        .finally(() => setIsLoading(false))
    } else {
      fetch(`/api/Users/${userId}/role?role=${newRoleValue}`, {
        method: 'PUT'
      })
        .then((response) => {
          if (response.ok) {
            return enqueueSnackbar(<FormattedMessage id="users_role_changed_success" />, { variant: "success" })
          } else {
            return enqueueSnackbar(<FormattedMessage id="users_role_changed_error" />, { variant: "error" })
          }
        })
        .finally(() => setIsLoading(false))
    }
  }

  const userItems = props.users?.map((user, i) =>
    <Fragment key={user.id}>
      <Grid container alignItems="center">
        <Grid item>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                {userIcon(user)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.fullName} disableTypography secondary={userContactInfo(user)} />
          </ListItem>
        </Grid>
        <Grid item>
          {availableRoles.length && <Select
            defaultValue={user.role ? user.role : 'Disabled'}
            onChange={(event) => handleRoleChange(user.id, event)}
          >
            <MenuItem key={uuid()} value={'Disabled'}>{localizeRole('Disabled')}</MenuItem>
            {availableRoles.map(role => <MenuItem disabled={disableAdminRole(role)} key={uuid()} value={role}>{localizeRole(role)}</MenuItem>)}
          </Select>
          }
        </Grid>
      </Grid>
      {divider(props.users[i + 1])}
    </Fragment>
  )

  return (
    <>
      {isLoading && <CircularProgress />}
      <List>
        {userItems}
      </List>
    </>
  )
}

UsersList.propTypes = {
  users: PropTypes.array,
  selectedOrgType: PropTypes.number,
}

export default UsersList