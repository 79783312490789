import React, { useEffect, useRef } from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import PropTypes from 'prop-types'
import { Button, Grid, RootRef, withStyles } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import EventIcon from '@material-ui/icons/Event'
import { FormattedMessage, useIntl } from 'react-intl'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import GooglePlacesAutocompleteInput from './GooglePlacesAutocompleteInput'
import uuid from 'uuid/v4'
import CancelIcon from '@material-ui/icons/Cancel';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { createPortal } from 'react-dom'

const StyledTimelineItem = withStyles({
    root: {
        paddingLeft: '0px',
        paddingRight: '0px',
    },
    missingOppositeContent: {
        '&:before': {
            display: 'none',
        },
    },
})(TimelineItem)

const StyledTimeline = withStyles({
    root: {
        paddingLeft: '0px',
        paddingRight: '0px',
        marginTop: '20px',
        marginBottom: '25px',
        overflowY: 'unset'
    },
})(Timeline)

const StyledTimelineContent = withStyles({
    root: {
        paddingRight: '0px',
    },
})(TimelineContent)

const StyledKeyboardDateTimePicker = withStyles({
    root: {
        width: '100%',
    },
})(KeyboardDateTimePicker)

export default function RideTimeLine({ disabled }) {

    const intl = useIntl()
    const emptyRouteStep = (stepOrder) => ({
        address: '',
        stepOrder,
        plannedPickupDate: null,
        id: uuid(),
    })

    const {
        append,
        control,
        errors,
        remove,
        move,
        fields,
        setValue,
        watch,
    } = useFormContext()

    const appendNewRouteStep = () => append(emptyRouteStep(fields.length + 1))
    const renderDatepicker = (index) => fields.length > 2 && index !== 0 && (index + 1 !== fields.length)
    const renderTimeLineConnector = (index) => fields.length > (index + 1)

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const FIRST_FIELD_ITEM_INDEX = 0
        const LAST_FIELD_ITEM_INDEX = (fields.length - 1)

        const MOVING_FROM_INDEX = result.source.index
        const MOVING_TO_INDEX = result.destination.index

        move(MOVING_FROM_INDEX, MOVING_TO_INDEX)

        if(MOVING_TO_INDEX === LAST_FIELD_ITEM_INDEX || MOVING_TO_INDEX === FIRST_FIELD_ITEM_INDEX) {
            setValue(`routeSteps.${result.destination.index}.plannedPickupDate`, null)
        }
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        ...(isDragging && {
            background: '#e2dabb',
        })
    });

    const useDraggableInPortal = () => {
        const self = useRef({}).current;

        useEffect(() => {
            const div = document.createElement('div');
            div.style.position = 'absolute';
            div.style.pointerEvents = 'none';
            div.style.top = '0';
            div.style.width = '100%';
            div.style.height = '100%';
            self.elt = div;
            document.body.appendChild(div);
            return () => {
                document.body.removeChild(div);
            };
        }, [self]);

        return (render) => (provided, ...args) => {
            const element = render(provided, ...args);
            if (provided.draggableProps.style.position === 'fixed') {
                return createPortal(element, self.elt);
            }
            return element;
        };
    };

    const renderDraggable = useDraggableInPortal();
    
    const watchFieldArray = watch("routeSteps");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    const watchPlannedPickupDate = watch('plannedPickupDate')
    
    return (
        <>
            <StyledTimeline>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable isDropDisabled={disabled} droppableId="droppable">
                        {(provided, snapshot) => (
                            <RootRef rootRef={provided.innerRef}>
                                <div style={{ backgroundColor: snapshot.isDraggingOver ? '#f3f0e3' : 'white' }}>
                                    {controlledFields && controlledFields.map((routeStep, index) => (
                                        <Draggable
                                            key={routeStep.id}
                                            draggableId={routeStep.id}
                                            index={index}>
                                            {renderDraggable((provided, snapshot) => (
                                                <StyledTimelineItem
                                                    key={routeStep.stepId}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <TimelineSeparator>
                                                        <Tippy content={intl.formatMessage({ id: 'ride_drag_sort' })}>
                                                            <TimelineDot color="secondary" {...provided.dragHandleProps} />
                                                        </Tippy>
                                                        {renderTimeLineConnector(index) && <TimelineConnector />}
                                                    </TimelineSeparator>
                                                    <StyledTimelineContent  >
                                                        <Grid container alignContent="center" alignItems="center"   >
                                                            <Grid item xs={11}>
                                                                <Controller
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <GooglePlacesAutocompleteInput
                                                                            defaultValue={routeStep.address}
                                                                            disabled={disabled}
                                                                            error={Boolean(errors && errors.routeSteps && errors.routeSteps[index] && errors.routeSteps[index].address)}
                                                                            name={`routeSteps.${index}.address`}
                                                                            label={index === 0 ? <FormattedMessage id="order_start" /> : <FormattedMessage id="ride_destination" />}
                                                                            onSelect={(googleAddress) => onChange(googleAddress)}
                                                                            value={value}
                                                                            onChange={(googleAddress) => onChange(googleAddress)}
                                                                        />
                                                                    )}
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    control={control}
                                                                    name={`routeSteps.${index}.address`}
                                                                    defaultValue={routeStep.address}
                                                                />
                                                            </Grid>
                                                            {(fields.length >= 3 || index > 1) && <Grid item container xs={1} justify="center">
                                                                <CancelIcon color="secondary" onClick={() => remove(index)} />
                                                            </Grid>}
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            {renderDatepicker(index) && <Controller
                                                                control={control}
                                                                name={`routeSteps.${index}.plannedPickupDate`}
                                                                defaultValue={routeStep.plannedPickupDate}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <StyledKeyboardDateTimePicker
                                                                        initialFocusedDate={watchPlannedPickupDate}
                                                                        minDate={watchPlannedPickupDate}
                                                                        label={intl.formatMessage({ id: 'ride_start_time_from_destination' })}
                                                                        ampm={false}
                                                                        id={`routeSteps.${index}.plannedPickupDate`}
                                                                        disablePast={!disabled}
                                                                        disabled={disabled}
                                                                        format="dd.MM.yyyy HH:mm"
                                                                        minDateMessage={<FormattedMessage id="datepicker_error_minDateMessage" />}
                                                                        keyboardIcon={<EventIcon color="secondary" />}
                                                                        openTo="hours"
                                                                        onChange={(newDate) => onChange(newDate)}
                                                                        value={value}
                                                                    />)}
                                                            />}
                                                        </Grid>
                                                    </StyledTimelineContent>
                                                </StyledTimelineItem>
                                            ))}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            </RootRef>)}
                    </Droppable>
                </DragDropContext>
            </StyledTimeline>
            <Button disabled={disabled} onClick={() => appendNewRouteStep()}><FormattedMessage id="ride_add_new_destination" /></Button>
        </>
    )
}

RideTimeLine.defaultProps = {
    disabled: false,
}

RideTimeLine.propTypes = {
    disabled: PropTypes.bool,
}
