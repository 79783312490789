import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import UserProfile from './UserProfile'
import CreditCardDetails from './CreditCardDetails'
import ChangePassword from './ChangePassword'
import ConfigurationContext from '../context/ConfigurationContext'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    page: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
            left: '0',
            minWidth: '320px',
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            transform: 'translateX(-50%)',
            width: '480px',
        },
        top: '80px',
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tab: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '10px',
        },
    },
}))

const tabRoutes = ['/profile/details', '/profile/change-password', '/profile/cards']

const getRouteFromPath = () => {
    for (let i in tabRoutes) {
        if(window.location.pathname === tabRoutes[i]) {
            return parseInt(i)
        }
    }
    return tabRoutes[0]
}

export default function UserProfileNavigation() {
    const classes = useStyles()
    const [activeTab, setActiveTab] = React.useState(getRouteFromPath())
    const { oidcUser } = useReactOidc()
    const configs = React.useContext(ConfigurationContext)

    const handleChange = (event, newValue) => {
        setActiveTab(newValue)
    }

    const showCardDetailsTab = () => (
        (oidcUser.profile.role === 'CustomerAdmin' || oidcUser.profile.role === 'Customer') &&
        configs?.featureFlags?.cardPayments
    )

    return (
        <div className={classes.page}>
            <AppBar position="static">
                <Tabs value={activeTab} onChange={handleChange} aria-label="simple tabs example" centered>
                    <Tab
                        className={classes.tab}
                        label={<FormattedMessage id="profile_profile" />}
                        component={Link}
                        to={tabRoutes[0]}
                        {...a11yProps(0)} 
                    />
                    <Tab
                        className={classes.tab}
                        label={<FormattedMessage id="profile_change_password" />}
                        component={Link}
                        to={tabRoutes[1]}
                        {...a11yProps(2)}
                    />
                    {showCardDetailsTab() &&
                    <Tab
                        className={classes.tab}
                        label={<FormattedMessage id="profile_card_details" />}
                        component={Link}
                        to={tabRoutes[2]}
                        {...a11yProps(1)}
                    />}
                </Tabs>
            </AppBar>
            <TabPanel value={activeTab} index={0}>
                <UserProfile />
            </TabPanel>

            <TabPanel value={activeTab} index={1}>
                <ChangePassword />
            </TabPanel>

            {showCardDetailsTab() &&
            <TabPanel value={activeTab} index={2}>
                <CreditCardDetails />
            </TabPanel>}
        </div>
    )
}
