import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import configurationContext from './ConfigurationContext'

const ConfigurationProvider = (props) => {
    const [configs, setConfigs] = useState()

    const loadConfigs = () => {
        fetch('api/configuration')
        .then((response) => {
            if (response.ok) {
                return response.json()
            }
            throw new Error('Could not fetch configurations')
        })
        .then((data) => {
            setConfigs(data)
        })
    }

    useEffect(() => {
        loadConfigs()
    }, [])

    return (
        <configurationContext.Provider value={configs}>
            {configs && props.children}
        </configurationContext.Provider>
    )
}

ConfigurationProvider.propTypes = {
    children: PropTypes.node,
}

export default ConfigurationProvider
