import React from 'react'
import PropTypes from 'prop-types'

import {
    InputLabel, FormHelperText, TextField,
    Grid, Button, makeStyles,
} from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { statuses } from '../../utils/constants'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    item: {
        marginTop: theme.spacing(2),
    },
}))

const EndRideDetails = (props) => {
    const {
        rideActualStartDate,
        rideActualEndDate,
        rideApprovedLength,
        rideApprovedWaitingMinutes,
        rideApprovedDriverNotes,
        rideEstimatedTripLengthKm,
        rideStatus,
    } = props

    const {
        control, register,
        handleSubmit, getValues, formState: { isValid, errors },
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })
    const classes = useStyles()
    console.log(getValues())
    return (
        <form onSubmit={handleSubmit((data) => props.onSubmitRideDetails(data))}>
            <Grid container className={classes.container}>
                <Grid container item direction="column" className={classes.item}>
                    <InputLabel><FormattedMessage id="ride_start_time_hh_mm" /></InputLabel>
                    <Controller
                        render={({ field: { onChange, value } }) => (
                            <KeyboardDateTimePicker
                                fullWidth={false}
                                format="dd.MM HH:mm"
                                ampm={false}
                                views={['hours', 'minutes']}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                        rules={{ required: true }}
                        control={control}
                        defaultValue={new Date(rideActualStartDate)}
                        name="actualStart"
                    />
                    {errors &&
                        errors.actualStart &&
                        <FormHelperText error={Boolean(errors.actualStart)}>
                            <FormattedMessage id="form_input_required" />
                        </FormHelperText>}
                </Grid>
                <Grid container item direction="column" className={classes.item}>
                    <InputLabel><FormattedMessage id="ride_ending_time_hh_mm" /></InputLabel>
                    <Controller
                        render={({ field: { onChange, value } }) => (
                            <KeyboardDateTimePicker
                                fullWidth={false}
                                format="dd.MM HH:mm"
                                ampm={false}
                                views={['hours', 'minutes']}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                        rules={{ required: true }}
                        control={control}
                        defaultValue={rideActualEndDate ? new Date(rideActualEndDate) : new Date()}
                        name="actualEnd"
                    />
                    {errors &&
                        errors.actualEnd &&
                        <FormHelperText error={Boolean(errors.actualEnd)}>
                            <FormattedMessage id="form_input_required" />
                        </FormHelperText>}
                </Grid>

                <Grid container item direction="column" className={classes.item}>
                    <InputLabel>
                        <FormattedMessage id="ride_actual_ride_length" /> (km) - <i><FormattedMessage id="google_estimate" /> {rideEstimatedTripLengthKm} km</i>
                    </InputLabel>
                    <Controller
                        control={control}
                        name="approvedTripLengthKm"
                        defaultValue={rideStatus === statuses.RideEnded ? rideApprovedLength : 0}
                        rules={{
                            required: true,
                            validate: {
                                minLength: (value) => parseFloat(value) >= 0,
                            },
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                data-test-id="approvedRideLengthKmInput"
                                type="number"
                                onChange={onChange}
                                value={value}
                                inputProps={{
                                    step: 0.1,
                                    min: 0.00,
                                    max: 9999.99,
                                }}
                            />)}
                    />
                    {errors && errors.approvedTripLengthKm?.type === 'required' && <FormHelperText error={Boolean(errors.approvedTripLengthKm)}><FormattedMessage id="form_input_required" /></FormHelperText>}
                    {errors && errors.approvedTripLengthKm?.type === 'minLength' && <FormHelperText error={Boolean(errors.approvedTripLengthKm)}><FormattedMessage id="ride_endride_error_minApprovedTripLength" /></FormHelperText>}
                </Grid>

                <Grid container item direction="column" className={classes.item}>
                    <InputLabel><FormattedMessage id="ride_waiting_time_mm" /></InputLabel>
                    <Controller
                        control={control}
                        name="approvedWaitingMinutes"
                        defaultValue={rideApprovedWaitingMinutes || 0}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                data-test-id="approvedWaitingMinutes"
                                type="number"
                                onChange={onChange}
                                value={value}
                            />)}
                    />
                    {errors && errors.approvedWaitingMinutes?.type === 'required' &&
                        <FormHelperText error={Boolean(errors.approvedWaitingMinutes)}>
                            <FormattedMessage id="form_input_required" />
                        </FormHelperText>}
                </Grid>

                <Grid container item direction="column" className={classes.item}>
                    <InputLabel><FormattedMessage id="ride_other_notes" /></InputLabel>
                    <Controller
                        control={control}
                        name="approvedDriverNotes"
                        defaultValue={rideApprovedDriverNotes || ''}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                data-test-id="approvedDriverNotes"
                                multiline
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Grid>
                <Grid className={classes.item}>
                    <Button data-test-id="completeRideButton" color="primary" type="submit" disabled={!isValid}><FormattedMessage id="complete_button" /></Button>
                </Grid>
            </Grid>
        </form>

    )
}

EndRideDetails.propTypes = {
    onSubmitRideDetails: PropTypes.func.isRequired,
    rideActualStartDate: PropTypes.string.isRequired,
    rideActualEndDate: PropTypes.string,
    rideApprovedDriverNotes: PropTypes.string,
    rideApprovedLength: PropTypes.number.isRequired,
    rideApprovedWaitingMinutes: PropTypes.number,
    rideEstimatedTripLengthKm: PropTypes.number,
    rideStatus: PropTypes.number.isRequired,
}

EndRideDetails.defaultProps = {
    rideActualEndDate: '',
    rideApprovedDriverNotes: '',
    rideApprovedWaitingMinutes: 0,
    rideEstimatedTripLengthKm: 0,
}

export default EndRideDetails
