import React, { useCallback, useEffect, useContext, useState } from 'react'
import { subDays, startOfDay, endOfDay } from 'date-fns'
import { addDays, } from 'date-fns/esm'

import { Grid, Typography, Box, makeStyles, Dialog, CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import { useSnackbar } from 'notistack'

import RideListItem from '../RideListItem'
import ConfirmationDialog from '../ConfirmationDialog'
import OrderFilterer from '../Organizer/OrderFilterer'
import { paymentTypes, statuses } from '../../utils/constants'
import DayBrowser from '../DayBrowser'
import RideDetails from './RideDetails'
import RidesStatusContext from '../../context/RidesStatusContext'

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
    },

    page: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
            left: '0',
            minWidth: '320px',
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            transform: 'translateX(-50%)',
        },
        top: '60px',
    },

    listContainer: {
        borderRadius: '2px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: '320px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '600px',
        },
        backgroundColor: '#FFF',
        margin: '0 !important',
        boxShadow: '0px 3px 6px #00000029',
        padding: '2em',
    },

    item: {
        width: '100%',
    },

    tab: {
        width: '100%',
        maxWidth: '720px',
        marginBottom: theme.spacing(1),
    },
    dayDateTitle: {
        width: '100%',
        maxWidth: '720px',
    },
    contactNameSelect: {
        width: '100%',
        maxWidth: '720px',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    loadingSpinner: {
        margin: theme.spacing(4),
    },
}))

const emptyGuid = '00000000-0000-0000-0000-000000000000'

const Rides = () => {
    const { oidcUser } = useContext(AuthenticationContext)
    const { refreshCounts } = React.useContext(RidesStatusContext)
    const classes = useStyles()
    const [activeDate, setActiveDate] = useState(new Date())
    const [showRideDetails, setShowRideDetails] = useState(false)
    const [selectedRideId, setSelectedRideId] = useState()
    const { enqueueSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = useState(false)
    const [rideData, setRideData] = useState([])
    const [statusCounts, setStatusCounts] = useState({})
    const [confirmModal, setConfirmModal] = useState({
        modalOpen: false,
        modalConfirmButtonFunc: () => { },
        modalCancelButtonFunc: () => setConfirmModal({ ...confirmModal, modalOpen: false }),
        modalConfirmButtonText: '',
        headerText: "",
        typography1: "",
        typography2: "",
    })
    const [filters, setFilters] = useState({
        incompleteRide: true,
        rideEnded: false,
    })

    const loadOrderData = useCallback(() => {
        const startDateParam = startOfDay(activeDate).toISOString()
        const endDateParam = endOfDay(activeDate).toISOString()
        const url = `/api/Rides?page=1&pageSize=50&startDate=${startDateParam}&endDate=${endDateParam}&assignedDriverId=${oidcUser?.profile?.userid}`
        setIsLoading(true)
        fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                enqueueSnackbar(<FormattedMessage id="order_get_rides_error" />, { variant: "error" })
            }).then(data => {
                if (data && data.totalCountPerStatus) {
                    setStatusCounts(data.totalCountPerStatus)
                } else {
                    setStatusCounts({})
                }                
                if (data && data.results && data.results.length) {
                    setRideData(data.results)
                } else {
                    setRideData(null)
                }
            })
            .catch(() => enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: "error" }))
            .finally(() => setIsLoading(false))
    }, [activeDate, enqueueSnackbar, oidcUser])

    useEffect(() => {
        loadOrderData()
    }, [activeDate, loadOrderData])

    const handleDateNextClick = () => {
        setActiveDate(addDays(activeDate, 1))
    }

    const handleDatePrevClick = () => {
        setActiveDate(subDays(activeDate, 1))
    }

    const closeConfirmModal = () => {
        setConfirmModal({
            ...confirmModal,
            modalOpen: false
        })
    }

    const openOrderConfirmation = (rideId) => {
        setSelectedRideId(rideId)
        changeRideStatus(statuses.DriverConfirmed, rideId)
    }

    const handleOpenRideDetails = (rideId) => {
        setSelectedRideId(rideId)
        setShowRideDetails(true)
    }

    const closeRideDetails = () => {
        setShowRideDetails(false)
        setSelectedRideId(null)
    }

    const changeRideStatus = (nextStatus, rideId) => {
        let headerText, typography1, confirmFunc, confirmBtntext

        if (nextStatus === statuses.DriverConfirmed) {
            headerText = <FormattedMessage id="ride_confirm_ride" />
            typography1 = <FormattedMessage id="ride_sign_ride" />
            confirmFunc = () => confirmChangeRideStatus({ nextStatus, rideId })
            confirmBtntext = <FormattedMessage id="ride_confirm" />
        }

        if (nextStatus === statuses.DriverStarted) {
            headerText = ''
            typography1 = <FormattedMessage id="ride_driver_started_text" />
            confirmFunc = () => confirmChangeRideStatus({ nextStatus })
            confirmBtntext = <FormattedMessage id="ride_driver_move_to_location" />
        }

        if (nextStatus === statuses.RideStarted) {
            headerText = ''
            typography1 = <FormattedMessage id="ride_driver_starting_ride" />
            confirmFunc = () => confirmChangeRideStatus({ nextStatus })
            confirmBtntext = <FormattedMessage id="ride_driver_start_ride" />
        }

        setConfirmModal({
            ...confirmModal,
            modalOpen: true,
            modalConfirmButtonFunc: confirmFunc,
            modalConfirmButtonText: confirmBtntext,
            headerText: headerText,
            typography1: typography1,
        })
    }

    const confirmChangeRideStatus = (rideStatusUpdate) => {
        const fetchUrl = (rideStatusUpdate.rideId && rideStatusUpdate.nextStatus) ?
            `/api/Rides/${rideStatusUpdate.rideId}/status?newStatus=${rideStatusUpdate.nextStatus}` :
            `/api/Rides/${selectedRideId}/status?newStatus=${rideStatusUpdate.nextStatus}`

        fetch(`${fetchUrl}`, {
            method: "PUT",
        }).then(response => {
            if (!response.ok) {
                throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="ride_save_approved_ride_success" />, { variant: "success" })
            closeConfirmModal()
            loadOrderData()
            // Refresh unapproved status count if driver confirmed a ride
            if (rideStatusUpdate.nextStatus === statuses.DriverConfirmed) {
                refreshCounts()
            }
            return
        })
            .catch(() => {
                enqueueSnackbar(<FormattedMessage id="ride_save_approved_ride_failed" />, { variant: "error" })
            })
    }

    const filtersList = [
        { name: <FormattedMessage id="ride_not_completed_rides" />, filterName: 'incompleteRide', defaultChecked: filters.incompleteRide },
        { name: <FormattedMessage id="ride_completed_rides" />, filterName: 'rideEnded', defaultChecked: filters.rideEnded },
    ]

    const handleSelectingFilter = (name, value) => {
        setFilters(
            {
                ...filters,
                [name]: value
            }
        )
    }

    const filterRideData = (rideData) => {
        return rideData.filter(ride => {
            var filterStatuses = []
            if (filters.incompleteRide) {
                filterStatuses.push(statuses.Ordered)
                filterStatuses.push(statuses.Confirmed)
                filterStatuses.push(statuses.Assigned)
                filterStatuses.push(statuses.DriverConfirmed)
                filterStatuses.push(statuses.DriverStarted)
                filterStatuses.push(statuses.RideStarted)
            }
            if (filters.rideEnded) {
                filterStatuses.push(statuses.RideEnded)
            }
            return (filterStatuses.includes(ride.status))
        })
    }

    const submitRideDetails = (rideId, data) => {
        fetch(`/api/rides/${rideId}/drivernotes?actualStart=${data.actualStart.toISOString()}&actualEnd=${data.actualEnd.toISOString()}&approvedTripLengthKm=${data.approvedTripLengthKm}&approvedWaitingMinutes=${data.approvedWaitingMinutes}&approvedDriverNotes=${data.approvedDriverNotes}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json"
            },
        })
            .then((response) => {
                if (!response.ok) {
                    return enqueueSnackbar(<FormattedMessage id="ride_save_approved_ride_failed" />, { variant: "error" })
                }
                // If payment type is card for this order then
                // don't close ride details yet
                const openRide = rideData.find((ride) => ride.id === rideId)
                if (openRide?.order?.paymentType === paymentTypes.Invoice) {
                    setShowRideDetails(false)
                }
                loadOrderData()
                enqueueSnackbar(<FormattedMessage id="ride_save_approved_ride_success" />, { variant: "success" })
            })
            .catch(() => enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: "error" }))
    }

    const freeUpRide = (rideId) => {
        fetch(`/api/rides/${rideId}/assignment?driverId=${emptyGuid}&vehicleId=${emptyGuid}`, {
            method: 'PUT',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="ride_free_up_success" />, { variant: 'success' })
            loadOrderData()
        }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="ride_free_up_failed" />, { variant: 'error' })
        })
    }

    return (
        <div className={classes.page}>
            <div className={classes.listContainer}>
                <Grid container justify="center" alignItems="center" direction="column" className={classes.container}>
                    <Grid item xs={12}>
                        <Box m={2} />
                        <Typography variant="h5"><FormattedMessage id="ride_rides_header" /></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box m={3} />
                        <DayBrowser date={activeDate} handlePrevClick={handleDatePrevClick} handleNextClick={handleDateNextClick} totalResultCount={rideData && rideData.length} />
                    </Grid>
                    <Grid item className={classes.item}>
                        <OrderFilterer onSelectFilter={handleSelectingFilter} filtersList={filtersList} />
                    </Grid>
                    <Grid item className={classes.item}>
                        {isLoading ? <CircularProgress /> :
                            rideData ? filterRideData(rideData)
                                .sort((a, b) => new Date(a.plannedPickupDate) - new Date(b.plannedPickupDate))
                                .map(ride =>
                                    <RideListItem
                                        key={ride.id}
                                        rideData={ride}
                                        openRideDetails={(ride) => handleOpenRideDetails(ride.id)}
                                        confirmOrder={(ride) => openOrderConfirmation(ride.id)}
                                        allowAssignmentConfirm={true} />)
                                : <div><FormattedMessage id="ride_no_rides_today" /></div>
                        }
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth='md'
                    open={confirmModal.modalOpen}
                    onClose={closeConfirmModal}
                >
                    <ConfirmationDialog
                        onCloseClick={closeConfirmModal}
                        onConfirmClick={confirmModal.modalConfirmButtonFunc}
                        modalConfirmButtonText={confirmModal.modalConfirmButtonText}
                        headerText={confirmModal.headerText}
                        typography1={confirmModal.typography1}
                        typography2={confirmModal.typography2}
                    />
                </Dialog>

                {selectedRideId && rideData &&
                    <Dialog
                        fullWidth
                        maxWidth="md"
                        open={showRideDetails}
                    >
                        <RideDetails
                            submitRideDetails={submitRideDetails}
                            rides={rideData}
                            rideId={selectedRideId}
                            changeStatus={(nextStatus) => changeRideStatus(nextStatus, selectedRideId)}
                            freeUpRide={(rideId) => freeUpRide(rideId)}
                            onCloseClick={() => closeRideDetails()}
                        />
                    </Dialog>
                }
            </div>
        </div>
    )
}

export default Rides