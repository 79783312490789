import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
    container: {
        height: '80px',
        backgroundColor: '#F8F6F1',
        color: '#000',
        borderRadius: '2px',
        /* Width and margin below relates to: https://github.com/mui-org/material-ui/issues/7466*/
        width: '100% !important',
        margin: '0 !important',
        '&:hover': {
            border: '1px solid #B7A355',
        },
    },
});

const NewOrderTab = ({onTabClick}) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.container} justify="center" alignItems="center" onClick={onTabClick}>
            <AddIcon />
        </Grid>
    )
}

NewOrderTab.propTypes = {
    onTabClick: PropTypes.func,
}

export default NewOrderTab