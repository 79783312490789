import React, { useCallback, useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem, Grid, Typography, Button, makeStyles, Box, DialogContent, InputLabel, IconButton, Hidden, Dialog, Table, TableBody, TableRow, TableCell, TableHead, DialogTitle, DialogActions } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CarIcon from '@material-ui/icons/DriveEta'
import DeleteIcon from '@material-ui/icons/Delete'
import PersonIcon from '@material-ui/icons/Person'
import WarningIcon from '@material-ui/icons/Warning'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { format, addHours } from 'date-fns'
import { addMinutes } from 'date-fns/esm'
import { useSnackbar } from 'notistack'
import { FormattedMessage } from 'react-intl'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import StatusIcon from '../StatusIcon'
import { statuses, views } from '../../utils/constants'
import vehicleCategoriesContext from '../../context/VehicleCategoriesContext'
import partnerGroupsContext from '../../context/PartnerGroupsContext'
import PartnerGroupsDialog from './PartnerGroupsDialog'
import RideDriverDialog from './RideDriverDialog'
import StatusIconText from '../StatusIconText'
import RideInvoicePanel from '../RideInvoicePanel'
import RenderRouteSteps from '../RenderRouteSteps';
import { ascendingRouteSteps } from '../../utils/ascendingRouteSteps';
import EditRideDialog from './EditRideDialog';
import useSWR from 'swr'
import ConfirmationDialog from '../ConfirmationDialog';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        margin: '0 !important',
        width: '100%',
    },
    statusIcon: {
        margin: '0 !important',
        padding: '0 !important',
        width: '100%',
        paddingTop: theme.spacing(4),
        textAlign: 'center',
        height: '200px',
        marginLeft: 'auto',
    },
    dialogPage: {
        padding: theme.spacing(1),
    },
    rideInfo: {
        backgroundColor: '#F8F6F1',
        //margin: theme.spacing(1),
        /* Width and margin below relates to: https://github.com/mui-org/material-ui/issues/7466*/
        width: '100% !important',
        margin: '0 !important',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    menuButton: {
        position: 'absolute',
        right: theme.spacing(8),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    editDriverIcon: {
        float: 'right',
        display: 'block',
    },
    driverInfo: {
        width: '240px',
        display: 'flex',
    },
    infoFieldTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    driverInfoField: {
        display: 'flex',
    },
    driverIcons: {
        marginRight: theme.spacing(1),
    },
    category: {
        width: '240px',
    },
    partners: {
        width: '240px',
    },
    editPartnerIcon: {
        float: 'left',
        display: 'block',
    },
    table: {
        width: '100%',
        boxShadow: '0px 3px 6px #00000029',
        '& td': {
            padding: '4px',
        },
    },
    currentRideRow: {
        backgroundColor: '#F8F6F1',
    },
    dividerRow: {
        borderBottom: '1px solid #00000029',
    },
    cellRight: {
        textAlign: 'right',
    },
    noWrapField: {
        whiteSpace: 'nowrap',
    },
}))

const Attachment = (props) => {
    const { id, fileUrl, fileName } = props
    return (
        <Grid key={id} container item direction="row">
            <a href={fileUrl}>
                <Typography>
                    <AttachmentIcon fontSize="small" />
                    {fileName}
                </Typography>
            </a>
        </Grid>)
}

const emptyGuid = '00000000-0000-0000-0000-000000000000';

const RideAssignmentDetails = ({ rideId, onAssignDriver, onCloseClick }) => {
    const [attachments, setAttachments] = useState([])
    const { partnerGroups } = React.useContext(partnerGroupsContext)
    const { categories } = React.useContext(vehicleCategoriesContext)
    const [showRideDriverDialog, setShowRideDriverDialog] = useState(false)
    const [showRemoveDriverDialog, setShowRemoveDriverDialog] = useState(false)
    const [showCancelRideDialog, setShowCancelRideDialog] = useState(false)
    const [showEditRideDialog, setShowEditRideDialog] = useState(false)
    const [driver, setDriver] = useState()
    const [vehicle, setVehicle] = useState()
    const [driverRides, setDriverRides] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const [selectedRide, setSelectedRide] = useState(null)
    const [orderPartnerGroups, setOrderPartnerGroups] = useState([])
    const classes = useStyles()
    const [showPartnerDialog, setShowPartnerDialog] = useState(false)
    const { oidcUser } = useContext(AuthenticationContext)
    const [anchorEl, setAnchorEl] = useState(null)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)

    const closeConfirmModal = () => {
        setConfirmModalOpen(false)
    }

    const handleOrderConfirmation = () => {
        setConfirmModalOpen(true)
    }

    const confirmRide = () => {
        fetch(`/api/Orders/${selectedRide.id}/confirmation`, {
            method: 'PUT',
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.status)
            }
            enqueueSnackbar(<FormattedMessage id="organizer_order_confirmation_success" />, { variant: 'success' })
            mutate()
            closeConfirmModal()
        }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="organizer_order_confirmation_failed" />, { variant: 'error' })
        })
    }

    const setAssignment = (driver, vehicle) => {
        setDriver(driver)
        setVehicle(vehicle)
        setShowRideDriverDialog(false)
        loadDriverSchedules(driver.id)
    }

    const loadRideDataFetcher = async url => {
        const res = await fetch(url)
    
        if (!res.ok) {
            enqueueSnackbar(<FormattedMessage id="order_get_rides_error" />, { variant: 'error' })
        }
    
        return res.json()
    }

    const { data, mutate } = useSWR(`/api/Rides/${rideId}`, loadRideDataFetcher)

    useEffect(() => {
        if(data) {
            setSelectedRide(data)
        }
    }, [data])

    const loadAttachments = useCallback(() => {
        if (!selectedRide?.order?.id) {
            return;
        }
        fetch(`/api/Orders/${selectedRide.order.id}/attachments`, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
            }).then(res => {
                if (res && res.length > 0) {
                    setAttachments(res[0])
                } else {
                    setAttachments([])
                }
            })

    }, [selectedRide])

    useEffect(() => {
        loadAttachments()
    }, [loadAttachments])

    const loadOrderPartnerGroups = useCallback(() => {
        if (!selectedRide?.order?.id) {
            return;
        }
        fetch(`/api/Orders/${selectedRide.order.id}/partnergroups`, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            }).then(res => {
                if (res && res.length > 0) {
                    setOrderPartnerGroups(res)
                } else {
                    setOrderPartnerGroups([])
                }
            }).catch(() => {
                setOrderPartnerGroups([]);
            })
    }, [selectedRide])

    useEffect(() => {
        loadOrderPartnerGroups()
    }, [loadOrderPartnerGroups])

    const loadDriverSchedules = useCallback((driverId) => {
        if (!driverId || driverId === emptyGuid) {
            return
        }

        const startDate = selectedRide.plannedPickupDate
        const start = addHours(new Date(startDate), -5)
        const end = addHours(new Date(startDate), 5)

        fetch(`/api/rides?startDate=${start.toISOString()}&endDate=${end.toISOString()}&assignedDriverId=${driverId}&page=1&pageSize=50`)
            .then((results) => {
                if (!results.ok) {
                    throw Error(results.status)
                }
                return results.json()
            }).then((rideData) => {
                setDriverRides(rideData.results)
            }).catch(() => {
                enqueueSnackbar(<FormattedMessage id="schedules_load_error" />, { variant: 'error' })
            })
    }, [enqueueSnackbar, selectedRide])

    useEffect(() => {
        if (selectedRide?.driverId) {
            loadDriverSchedules(selectedRide.driverId);
        }
    }, [selectedRide, loadDriverSchedules])

    const handlePartnerGroupSave = (groups) => {
        enqueueSnackbar(<FormattedMessage id="saving_changes" />, { variant: "info" });
        fetch(`/api/Orders/${selectedRide.order.id}/partnergroups`, {
            method: "PUT",
            body: JSON.stringify(groups),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                setShowPartnerDialog(false);
                loadOrderPartnerGroups();
                enqueueSnackbar(<FormattedMessage id="save_succeed" />, { variant: "success" });
                return;
            }
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: "error" });
        }).catch(() => {
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: "error" });
        });
    }

    const cancelRide = () => {
        enqueueSnackbar(<FormattedMessage id="saving_changes" />, { variant: "info" });
        fetch(`/api/Orders/${selectedRide.order.id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar(<FormattedMessage id="organizer_cancel_ride_success" />, { variant: "success" });
                setShowCancelRideDialog(false)
                onCloseClick()
                return;
            }
            setShowCancelRideDialog(false)
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: "error" });
        }).catch(() => {
            setShowCancelRideDialog(false)
            enqueueSnackbar(<FormattedMessage id="save_failed" />, { variant: "error" });
        });
    }

    const orderPartnerGroupNames = partnerGroups
        .filter((group) => orderPartnerGroups.find(groupid => groupid === group.id))
        .map((group, i) => <div key={i}><Box fontWeight="bold">{group.name}:</Box> {group.partnersNames}</div>);

    return (
        selectedRide &&
        <>
            <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem disabled={selectedRide.status === statuses.Cancelled} onClick={() => {setShowEditRideDialog(true); setAnchorEl(null)}}><FormattedMessage id="organizer_edit_ride" /></MenuItem>
                <MenuItem onClick={() => {setShowCancelRideDialog(true); setAnchorEl(null)}}><FormattedMessage id="organizer_cancel_ride" /></MenuItem>
            </Menu>
            <IconButton aria-label="open menu" className={classes.menuButton} onClick={(e) => setAnchorEl(e.currentTarget)}>
                <MoreVertIcon />
            </IconButton>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onCloseClick}>
                <CloseIcon />
            </IconButton>
            <DialogContent className={classes.dialogPage}>
                <div className={classes.rideInfo}>
                    <Grid container direction="row" spacing={4} className={classes.container}>
                        <Grid item xs={3} sm={2} className={classes.statusIcon}>
                            <Grid container item className={classes.statusIcon} alignItems="center" direction="column" justify="center">
                                {selectedRide.status === statuses.Ordered &&
                                    <>
                                        <IconButton onClick={(event) => {
                                            event.stopPropagation()
                                            handleOrderConfirmation()
                                        }}>
                                            <StatusIcon status={selectedRide.status} view={views.organizer} />
                                        </IconButton>
                                        <StatusIconText status={selectedRide.status} />
                                    </>
                                }
                                {selectedRide.status !== statuses.Ordered &&
                                    <>
                                        <StatusIcon status={selectedRide.status} view={views.organizer} />
                                        <StatusIconText status={selectedRide.status} />
                                    </>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={9} sm={10}>

                            <Grid container className={classes.container} direction="row">
                                <Grid item xs={12}>

                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {selectedRide.order.passengerUser && selectedRide.order.passengerUser?.fullName}{selectedRide.order.otherPassengerName && <>{selectedRide.order.otherPassengerName} {selectedRide.order.otherPassengerCompany}</>} / {selectedRide.order.numberOfPassengers} henk.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {selectedRide.order.subscriberCompany.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {format(new Date(selectedRide.plannedPickupDate), 'dd.MM.yyyy HH:mm')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Grid container direction="column" className={classes.container}>
                                        <Grid item>
                                            <Box mt={2} mb={2}>
                                                <Typography variant="subtitle2">
                                                    <RenderRouteSteps routeSteps={ascendingRouteSteps(selectedRide.routeSteps)} showRouteStepDate />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2">
                                                <FormattedMessage id="ride_expected_duration" /> {selectedRide.estimatedDurationMinutes} min.
                                        </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2">
                                                <FormattedMessage id="ride_estimated_trip_length_km" /> {selectedRide.estimatedTripLengthKm} km
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2">
                                                <FormattedMessage id="ride_expected_arrival_time" /> {format(addMinutes(new Date(selectedRide.plannedPickupDate), selectedRide.estimatedDurationMinutes), 'HH:mm')}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Box xs={12} />
                                            <Typography color="secondary" variant="subtitle2">
                                                <FormattedMessage id="car" />: {selectedRide.vehicleLicensePlate || '-'}
                                            </Typography>
                                            <Typography color="secondary" variant="subtitle2">
                                                <FormattedMessage id="driver" />: {selectedRide.driverName || '-'}
                                            </Typography>
                                            <Typography color="secondary" variant="subtitle2">
                                                <FormattedMessage id="organizer_order_owner" />: {selectedRide.order.orderOwner?.name || '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box xs={8} />
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" spacing={1}>
                                                <Hidden only="xs">
                                                    <Grid item xs={12} sm={4}>
                                                        <Typography variant="subtitle2" align="right" className={classes.infoFieldTitle}><FormattedMessage id="vehicle_class" /></Typography>
                                                    </Grid>
                                                </Hidden>
                                                <Grid item xs={12} sm={8}>
                                                    <Typography variant="subtitle2">{categories.filter(c => c.id === selectedRide.requestedVehicleClassGuid)[0]?.name}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" spacing={1}>
                                                <Hidden only="xs">
                                                    <Grid item xs={12} sm={4}>
                                                        <Typography variant="subtitle2" align="right" className={classes.infoFieldTitle}><FormattedMessage id="drive_type" /></Typography>
                                                    </Grid>
                                                </Hidden>
                                                <Grid item xs={12} sm={8}>
                                                    <Typography variant="subtitle2">
                                                        {selectedRide.order.driveType === 0 && <FormattedMessage id="ride_drive_type_one_way" />}
                                                        {selectedRide.order.driveType === 2 && <FormattedMessage id="ride_drive_type_hour" />}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" spacing={1}>
                                                <Hidden only="xs">
                                                    <Grid item xs={12} sm={4}>
                                                        <Typography variant="subtitle2" align="right"><FormattedMessage id="ride_additional_services" /></Typography>
                                                    </Grid>
                                                </Hidden>
                                                <Grid item xs={12} sm={8}>
                                                    <Hidden smUp>
                                                        <Typography variant="subtitle2"><FormattedMessage id="ride_additional_information" />:</Typography>
                                                    </Hidden>
                                                    {selectedRide.order.terminalPickup &&
                                                    <Typography variant="subtitle2"><FormattedMessage id="ride_terminal_pickup" /></Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row" spacing={1}>
                                                <Hidden only="xs">
                                                    <Grid item xs={12} sm={4}>
                                                        <Typography variant="subtitle2" align="right"><FormattedMessage id="ride_additional_information" /></Typography>
                                                    </Grid>
                                                </Hidden>
                                                <Grid item xs={12} sm={8}>
                                                    <Hidden smUp>
                                                        <Typography variant="subtitle2"><FormattedMessage id="ride_additional_information" />:</Typography>
                                                    </Hidden>
                                                    <Typography variant="subtitle2">{selectedRide.order.additionalInformation}</Typography>
                                                </Grid>
                                            </Grid>
                                            {(selectedRide.status === statuses.RideEnded && selectedRide.approvedDriverNotes) &&
                                                <Grid container direction="row" spacing={1}>
                                                    <Hidden only="xs">
                                                        <Grid item xs={12} sm={4}>
                                                            <Typography variant="subtitle2" align="right"><FormattedMessage id="ride_driver_note" /></Typography>
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid item xs={12} sm={8}>
                                                        <Hidden smUp>
                                                            <Typography variant="subtitle2"><FormattedMessage id="ride_driver_note" />:</Typography>
                                                        </Hidden>
                                                        <Typography variant="subtitle2">{selectedRide.approvedDriverNotes}</Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                {(selectedRide.status === statuses.RideEnded) &&
                    <div>
                        <RideInvoicePanel rideId={selectedRide.order?.id} />
                    </div>}
                <div>
                    <Grid container className={classes.container} direction="column" justify="center" alignItems="center" spacing={4}>
                        <Grid item xs={12} className={classes.container}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                <Grid container item xs={6} direction="column" alignItems="flex-start">
                                    <div><Typography variant="subtitle2"><FormattedMessage id="order_order_number" />: {selectedRide.order.number}</Typography></div>
                                    <div><Typography variant="subtitle2"><FormattedMessage id="ride_ride_number" />: {selectedRide.number}</Typography></div>
                                    <div><Typography variant="subtitle2"><FormattedMessage id="customer" />: {selectedRide.order.subscriberCompany.name}</Typography></div>
                                    <div><Typography variant="subtitle2"><FormattedMessage id="orderer" />: <span className={classes.noWrapField}><a href={'mailto:' + selectedRide.order.subscriberUser?.email}>{selectedRide.order.subscriberUser?.fullName}</a></span> / <span className={classes.noWrapField}>tel. <a href={"tel:" + selectedRide.order.subscriberUser.phone}>{selectedRide.order.subscriberUser.phone}</a></span></Typography></div>
                                    <div><Typography variant="subtitle2"><FormattedMessage id="passenger" />: <span className={classes.noWrapField}><a href={'mailto:' + (selectedRide.order.otherPassengerEmail || selectedRide.order.passengerUser?.email)}>{selectedRide.order.otherPassengerName || selectedRide.order.passengerUser?.fullName}</a></span> {selectedRide.order.otherPassengerCompany && <span className={classes.noWrapField}>({selectedRide.order.otherPassengerCompany})</span>} / <span className={classes.noWrapField}>tel. <a href={"tel:" + (selectedRide.order.otherPassengerPhone?.length ? selectedRide.order.otherPassengerPhone : selectedRide.order.passengerUser.phone)}>{selectedRide.order.otherPassengerPhone?.length ? selectedRide.order.otherPassengerPhone : selectedRide.order.passengerUser?.phone}</a></span></Typography></div>
                                    {selectedRide.dateCancelled &&
                                        <div>
                                            <Typography variant="subtitle2">
                                                <FormattedMessage id="order_date_cancelled" />
                                                :&nbsp;
                                                {format(new Date(selectedRide.dateCancelled), 'dd.MM.yyyy HH:mm')}
                                            </Typography>
                                        </div>}
                                </Grid>
                                <Grid item xs={6}>
                                    <div><Typography variant="subtitle2"><FormattedMessage id="attachments" /></Typography></div>
                                    {attachments && attachments.map(attachment => <Attachment key={attachment.id} id={attachment.id} fileUrl={attachment.fileUrl} fileName={attachment.fileName} ></Attachment>)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink className={classes.category}><FormattedMessage id="ride_car_and_driver" /></InputLabel>
                            {(selectedRide.status === statuses.Ordered) &&
                                <Typography variant="subtitle1" color="error">
                                    <FormattedMessage id="ride_confirm_order_to_define_driver_and_car" />
                                </Typography>
                            }
                            {(selectedRide.status !== statuses.Ordered) &&
                                <div className={classes.driverInfo}>
                                    <div>
                                        <div className={classes.driverInfoField}>
                                            <CarIcon fontSize="small" color="disabled" className={classes.driverIcons} />
                                            <Typography variant="body1">
                                                {vehicle && <>{vehicle.name}</>}
                                                {!vehicle && <>{selectedRide.vehicleLicensePlate}</>}
                                            </Typography>
                                        </div>
                                        <div className={classes.driverInfoField}>
                                            <PersonIcon fontSize="small" color="disabled" className={classes.driverIcons} />
                                            <Typography variant="body1">
                                                {driver && <>{driver.name}</>}
                                                {!driver && <>{selectedRide.driverName}</>}
                                                {(driver && driver.name !== selectedRide.driverName) && <><br /><FormattedMessage id="ride_remember_to_save" /></>}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={classes.editDriverIcon}>
                                        <IconButton
                                            data-test-id="showDriverSelectionButton"
                                            onClick={() => setShowRideDriverDialog(true)}
                                            color="secondary"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        {(driver || selectedRide.driverName) &&
                                            <IconButton
                                                data-test-id="removeDriverSelectionButton"
                                                onClick={() => setShowRemoveDriverDialog(true)}
                                                color="secondary"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    </div>
                                </div>
                            }
                        </Grid>

                        {
                            // show driver's other rides if driver is selected
                            (driver || selectedRide?.driverName) &&
                            <Grid item xs={12}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <Box fontWeight="fontWeightBold">
                                                    <FormattedMessage id="ride_handled_orders" />Käsitellyt tilaukset (+-5h):
                                                    {vehicle && <>{vehicle.name}</>}
                                                    {!vehicle && <>{selectedRide.vehicleLicensePlate}</>}&nbsp;/&nbsp;
                                                    {driver && <>{driver.name}</>}
                                                    {!driver && <>{selectedRide.driverName}</>}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <>
                                            {driverRides?.filter(r => r.plannedPickupDate <= selectedRide.plannedPickupDate && r.id !== selectedRide.id).map((beforeRide) =>
                                                <TableRow key={beforeRide.id}>
                                                    <TableCell>
                                                        {format(new Date(beforeRide.plannedPickupDate), 'HH:mm')}<br />
                                                        {beforeRide.driverName}<br />
                                                        {beforeRide.subscriberCompany?.name}
                                                    </TableCell>
                                                    <TableCell className={classes.cellRight}>
                                                        <RenderRouteSteps routeSteps={ascendingRouteSteps(beforeRide.routeSteps)} />
                                                        <FormattedMessage id="ride_expected_duration" /> {beforeRide.estimatedDurationMinutes} min
                                                        {(new Date(selectedRide.plannedPickupDate) <= addMinutes(new Date(beforeRide.plannedPickupDate), beforeRide.estimatedDurationMinutes)) &&
                                                            <WarningIcon fontSize="small" color="error" />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow className={classes.currentRideRow}>
                                                <TableCell>
                                                    {format(new Date(selectedRide.plannedPickupDate), 'HH:mm')} <FormattedMessage id="ride_this_ride" /> <br />
                                                    {selectedRide.driverName}<br />
                                                    {selectedRide.subscriberCompany?.name}
                                                </TableCell>
                                                <TableCell className={classes.cellRight}>
                                                    <RenderRouteSteps routeSteps={ascendingRouteSteps(selectedRide.routeSteps)} />
                                                    <FormattedMessage id="ride_expected_duration" /> {selectedRide.estimatedDurationMinutes} min
                                                </TableCell>
                                            </TableRow>

                                            {driverRides?.filter(r => r.plannedPickupDate > selectedRide.plannedPickupDate).map((afterRide) =>
                                                <TableRow key={afterRide.id}>
                                                    <TableCell>
                                                        <div>{format(new Date(afterRide.plannedPickupDate), 'HH:mm')}
                                                            {(new Date(afterRide.plannedPickupDate) < addMinutes(new Date(selectedRide.plannedPickupDate), selectedRide.estimatedDurationMinutes)) &&
                                                                <WarningIcon fontSize="small" color="error" />
                                                            }
                                                        </div>
                                                        <div>{afterRide.driverName}</div>
                                                        <div>{afterRide.subscriberCompany?.name}</div>
                                                    </TableCell>
                                                    <TableCell className={classes.cellRight}>
                                                        <RenderRouteSteps routeSteps={ascendingRouteSteps(afterRide.routeSteps)} />
                                                        <div><FormattedMessage id="ride_expected_duration" /> {afterRide.estimatedDurationMinutes} min</div>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </>
                                    </TableBody>
                                </Table>
                            </Grid>
                        }

                        {(selectedRide.order.orderOwner?.id === oidcUser?.profile?.org) &&
                            <>
                                <Grid item xs={12} container direction="column" justify="center" alignItems="center">
                                    <div className={classes.partners}>
                                        <Typography variant="subtitle2">
                                            <FormattedMessage id="organizer_show_to_partners" />:
                                            </Typography>
                                        {orderPartnerGroupNames}
                                    </div>
                                    <Button
                                        disabled={Boolean(driver || selectedRide?.driverName)}
                                        onClick={() => setShowPartnerDialog(true)}
                                        id="selectPartnersButton"
                                        data-test-id="selectPartnersButton"
                                        endIcon={<ArrowDropDownIcon />}
                                    >
                                        <FormattedMessage id="organizer_partners" />
                                    </Button>
                                </Grid>
                            </>
                        }

                        <Grid item xs={12}>
                            <Button
                                color="secondary"
                                id="saveDriverToRide"
                                data-test-id="saveDriverToRide"
                                disabled={!driver}
                                onClick={() => onAssignDriver(selectedRide.id, driver.id, vehicle.id)}><FormattedMessage id="ride_save_driver_button" /></Button>
                        </Grid>
                    </Grid>

                </div>
            </DialogContent>

            <PartnerGroupsDialog
                handlePartnerGroupSave={(groups) => handlePartnerGroupSave(groups)}
                orderPartnerGroups={orderPartnerGroups}
                open={showPartnerDialog}
                handleClose={() => setShowPartnerDialog(false)}
            />

            <Dialog
                open={showRideDriverDialog}
                onClose={() => setShowRideDriverDialog(false)}
            >
                <RideDriverDialog
                    handleClose={() => setShowRideDriverDialog(false)}
                    category={selectedRide.requestedVehicleClassGuid}
                    handleChange={(driver, vehicle) => setAssignment(driver, vehicle)}
                    startDate={selectedRide.plannedPickupDate}
                />
            </Dialog>

            <Dialog
                open={showRemoveDriverDialog}
            >
                <DialogTitle><FormattedMessage id="are_you_sure" /></DialogTitle>
                <DialogActions>
                    <Button
                        data-test-id="confirmRemoveDriverButton"
                        onClick={() => {
                            onAssignDriver(selectedRide.id, emptyGuid, emptyGuid)
                            setShowRemoveDriverDialog(false)
                        }}
                    >
                        <FormattedMessage id="remove" />
                    </Button>
                    <Button
                        data-test-id="cancelRemoveDriverButton"
                        onClick={() => setShowRemoveDriverDialog(false)}
                    >
                        <FormattedMessage id="cancel" />
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showCancelRideDialog}
            >
                <DialogTitle><FormattedMessage id="organizer_cancel_ride_confirmation" /></DialogTitle>
                <DialogActions>
                    <Button
                        data-test-id="confirmCancelRideButton"
                        onClick={() => cancelRide()}
                    >
                        <FormattedMessage id="yes" />
                    </Button>
                    <Button
                        data-test-id="cancelCancelRideButton"
                        onClick={() => setShowCancelRideDialog(false)}
                    >
                        <FormattedMessage id="no" />
                    </Button>
                </DialogActions>
            </Dialog>

            <EditRideDialog showEditRideDialog={showEditRideDialog} closeEditRideDialog={() => {setShowEditRideDialog(false); loadAttachments(); mutate()}} ride={selectedRide} />
            
            <Dialog
                    fullWidth
                    maxWidth="md"
                    open={confirmModalOpen}
                    onClose={closeConfirmModal}
                >
                    <ConfirmationDialog
                        onCloseClick={closeConfirmModal}
                        onConfirmClick={confirmRide}
                        modalConfirmButtonText={<FormattedMessage id="organizer_dialog_modalConfirmButtonText" />}
                        headerText={<FormattedMessage id="organizer_dialog_headerText" />}
                        typography1={<FormattedMessage id="organizer_dialog_typo1" />}
                        typography2={<FormattedMessage id="organizer_dialog_typo2" />}
                    />
                </Dialog>
        </>
    )
}

RideAssignmentDetails.propTypes = {
    rideId: PropTypes.string,
    onAssignDriver: PropTypes.func,
    onCloseClick: PropTypes.func,
}

export default RideAssignmentDetails