import React from 'react'
import { createGoogleMapsRouteUrl } from '../utils/createGoogleMapsRouteUrl';
import { FormattedMessage } from 'react-intl';
import { ascendingRouteSteps } from '../utils/ascendingRouteSteps';
import { Link, Grid, Typography } from '@material-ui/core'
import DirectionsIcon from '@material-ui/icons/Directions'

const RenderGoogleMapsRouteLink = ({routeSteps}) => {
    return (
        <Grid item>
            <Link target="_blank" rel="noopener" href={createGoogleMapsRouteUrl(ascendingRouteSteps(routeSteps))}>
                <Typography color="secondary"><DirectionsIcon color="secondary" /><FormattedMessage id="order_show_trip_google_maps" /></Typography>
            </Link>
        </Grid>
    )
}

export default RenderGoogleMapsRouteLink