import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

const useStyles = makeStyles(() => ({
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}))

const RenderRouteSteps = ({routeSteps, showRouteStepDate}) => {
    const classes = useStyles();

    if(routeSteps === null) return null
    if(routeSteps.length == 1) return null

    if (routeSteps.length === 2) return (
        <>
            <Grid data-test-id={`route-step-order-${routeSteps[0].stepOrder}`} item className={classes.ellipsis}>{routeSteps[0].address}</Grid>
            <Grid data-test-id={`route-step-order-${routeSteps[1].stepOrder}`} item className={classes.ellipsis}>{routeSteps[1].address}</Grid>
        </>
    )

    return routeSteps.map((routeStep, index) => {
        if(index === 0 || index + 1 === routeSteps.length) { // First or last item
            return <Grid data-test-id={`route-step-${routeSteps[index].stepOrder}`} key={index} item className={classes.ellipsis}>{routeStep.address}</Grid>
        }

        if(showRouteStepDate) {
            return <Fragment key={index}>
                <Grid data-test-id={`route-step-${routeSteps[index].stepOrder}`} key={index} item className={classes.ellipsis}>{routeStep.address}</Grid>
                {routeStep.plannedPickupDate && <i><Grid data-test-id={`route-step-order-${routeSteps[index].stepOrder}`} key={index} item className={classes.ellipsis}>(<FormattedMessage id="ride_start_time" />: {routeStep.plannedPickupDate ? format(new Date(routeStep.plannedPickupDate), 'dd.MM.yyyy HH:mm') : '-'})</Grid></i>}
                </Fragment>
        } else {
            if(index === 1) { // Second (middle) item
                const waypoints_label_id = routeSteps.length - 2 === 1 ? 'ride_waypoint' : 'ride_waypoints'
                return (
                    <Grid 
                        data-test-id={`route-step-total-waypoints-${routeSteps.length - 2}`}
                        key={index}
                        item
                        className={classes.ellipsis}>
                            <i>
                                (<FormattedMessage id="ride_route_has" /> {routeSteps.length - 2} <FormattedMessage id={waypoints_label_id} />)
                            </i>
                    </Grid>
                )
            }
        }
    })
}

RenderRouteSteps.propTypes = {
    routeSteps: PropTypes.arrayOf(PropTypes.shape({
        address: PropTypes.string,
        stepOrder: PropTypes.number,
    })),
    showRouteStepDate: PropTypes.bool,
}

export default RenderRouteSteps