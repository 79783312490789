import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles, Divider } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
    invoicePanel: {
        backgroundColor: '#FBFBFB',
        padding: theme.spacing(2),
    },
    invoiceTable: {
        width: '100%',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    minimumFeeRow: {
        fontWeight: 'bold',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    totalRow: {
        fontWeight: 'bold',
    },
}))

const RideInvoicePanel = (props) => {
    const classes = useStyles()
    const [invoice, setInvoice] = useState()
    const currencyIntl = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' })
    const decimalIntl = new Intl.NumberFormat('fi-FI')
    const { enqueueSnackbar } = useSnackbar()

    const loadRideInvoice = (rideId) => {
        fetch(`/api/orders/${rideId}/invoice`)
        .then((response) => {
            if (!response.ok) {
                if(response.status === 404) {
                    enqueueSnackbar(<FormattedMessage id="ride_invoice_not_found" />, { variant: 'error' })
                    return null;
                } else {
                    throw new Error('Invoice not found')
                }
            }
            return response.json()
        })
        .then((data) => {
            setInvoice(data)
        })
        .catch(() => {
            enqueueSnackbar(<FormattedMessage id="ride_invoice_load_error" />, { variant: 'error' })
        })
    }

    useEffect(() => {
        if (props.rideId != null) {
            loadRideInvoice(props.rideId)
        }
    }, [props.rideId])

    return (
        <>
        {invoice &&
        <div className={classes.invoicePanel}>
            <table className={classes.invoiceTable}>
                <thead>
                    <tr>
                        <th colSpan={4}>
                            {invoice.pricingDayType === 0 && <FormattedMessage id="pricing_weekday_ride" />}
                            {invoice.pricingDayType === 1 && <FormattedMessage id="pricing_weekend_or_holiday_ride" />}
                        </th>
                    </tr>
                    <tr className={classes.headerRow}>
                        <th
                            aria-label="item and unit price"
                            colSpan={2}
                            className={classes.paddingAfter}
                        >
                            <FormattedMessage id="pricing_start_time" />
                            &nbsp;
                            {invoice.priceListStartHour}
                            -
                            {invoice.priceListEndHour}
                        </th>
                        <th width="15%" aria-label="unit price" align="left"><FormattedMessage id="pricing_quantity" /></th>
                        <th width="15%" aria-label="total"><FormattedMessage id="price_total_abbrv" /></th>
                    </tr>
                </thead>
            </table>
            <Divider />
            <table className={classes.invoiceTable}>
                <tbody>
                    <>
                    {invoice.rows?.map((item, index) => {
                        const nameCodes = [
                            'priceitem_base_fee',
                            'priceitem_hourlydrivebase_fee',
                            'priceitem_km_fee',
                            'priceitem_waiting_fee',
                            'priceitem_hourly_drive',
                            'priceitem_terminal_pickup',
                        ]
                        if(item.chargeType === 5) {
                            return
                        } else {
                            return (
                                <tr key={item.id}>
                                    <td><FormattedMessage id={nameCodes[item.chargeType]} /></td>
                                    <td>
                                        {currencyIntl.format(item.unitPrice.toFixed(2))}
                                        {(item.unitOfMeasure.length > 0) &&
                                        <>
                                            /
                                            {item.unitOfMeasure}
                                        </>}
                                    </td>
                                    <td width="15%" align="left">
                                        {decimalIntl.format(Math.round(item.quantity * 100) / 100)}
                                        &nbsp;
                                        {(item.unitOfMeasure.length > 0) && item.unitOfMeasure}
                                    </td>
                                    <td width="15%" align="right">{currencyIntl.format(Math.round(item.total * 100) / 100)}</td>
                                </tr>
                            )
                        }
                    })}
                    </>
                </tbody>
            </table>
            <Divider />
            <table className={classes.invoiceTable}>
                <tbody>
                    <tr className={classes.minimumFeeRow}>
                        <td colSpan={3}>
                            <FormattedMessage id="pricing_minimal_fee" />
                        </td>
                        <td align="right" data-test-id="invoiceMinimumPrice">
                            {currencyIntl.format(invoice.minimumPrice)}
                        </td>
                    </tr>
                </tbody>
            </table>
            <Divider />
            {invoice.rows?.map((item, index) => {
                if(item.chargeType === 5) {
                    return (
                        <>
                            <table className={classes.invoiceTable}>
                                <tbody>
                                    <tr key={item.id}>
                                        <td colSpan={3}>
                                            <FormattedMessage id="priceitem_terminal_pickup_fee" />
                                        </td>
                                        <td width="15%" align="right">{currencyIntl.format(Math.round(item.total * 100) / 100)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Divider />
                        </>
                    )
                } else {
                    return
                }
            })}
            <table className={classes.invoiceTable}>
                <tbody>
                    <tr className={classes.totalRow}>
                        <td colSpan={3}><FormattedMessage id="pricing_total" /></td>
                        <td align="right" data-test-id="invoiceTotal">{currencyIntl.format(invoice.total)}</td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <FormattedMessage id="pricing_total_vat" />
                            &nbsp;({invoice.vatPercent}%)
                        </td>
                        <td align="right" data-test-id="invoiceTotalVat">{currencyIntl.format(invoice.totalVat)}</td>
                    </tr>
                </tbody>
            </table>
        </div> }
        </>
    )
}

export default RideInvoicePanel

RideInvoicePanel.propTypes = {
    rideId: PropTypes.string,
}

RideInvoicePanel.defaultProps = {
    rideId: null,
}
