import React from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete'
import PropTypes from 'prop-types'
import { TextField, Grid, Typography } from '@material-ui/core'
import parse from 'autosuggest-highlight/parse'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { FormattedMessage } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'

const GooglePlacesAutocompleteInput = (props) => {
    const {
        ready,
        suggestions: { data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: { componentRestrictions: { country: 'fi' } },
        debounce: 300,
    })

    const debouncedOnChange = useDebouncedCallback(
        (changedValue) => {
            props.onChange(changedValue);
        },
        // delay in ms
        1000
    )

    const handleInputChange = (description) => {
        debouncedOnChange(description)
        setValue(description)
    }

    const handleSelect = ({ description }) => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter as "false"
        setValue(description, false)
        props.onSelect(description)
        clearSuggestions()
    }

    const {
        label, name, error, disabled, value,
    } = props

    return (
            <Autocomplete
                id={`google-map-autocomplete${name}`}
                getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
                options={data}
                autoComplete
                freeSolo
                fullWidth
                disabled={disabled || !ready}
                disableClearable
                defaultValue={props.defaultValue}
                includeInputInList
                noOptionsText={<FormattedMessage id="no_search_results" />}
                onChange={(event, newValue) => handleSelect(newValue)}
                value={value}
                onInputChange={(event, newValue) => handleInputChange(newValue)}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        name={name}
                        error={Boolean(error)}
                        helperText={error && <FormattedMessage id="form_input_required" />}
                        fullWidth
                    />
                )}
                renderOption={option => {
                    let matches = option.structured_formatting.main_text_matched_substrings;

                    // Null check is required to prevent the app from crashing as
                    // some specific addresses may not have any matches
                    if(!matches) {
                        matches = [{ offset: 0, length: option.structured_formatting.main_text.length }]
                    }

                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map(match => [match.offset, match.offset + match.length]),
                    )

                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                <LocationOnIcon />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}

                                <Typography variant="body2" color="textSecondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }}
            />
    )
}

GooglePlacesAutocompleteInput.propTypes = {
    onSelect: PropTypes.func,
    label: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.any,
}

export default GooglePlacesAutocompleteInput