import React from 'react';
import MaterialTable from 'material-table';

import { tableLocalization, tableIcons } from './TableConfig';
import { makeStyles, } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  vehicleClassImage: {
    width: '320px'
  },
  vehicleClassRowImage: {
    width: '50px'
  },
});


export default function VehicleCategoriesTable(props) {
  const classes = useStyles();

  const updateVehicleCategory = (vehicleCategory) => {
    props.updateVehicleCategory(vehicleCategory);
  }
  const doUpdateVehicleCategory = (newData, oldData) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        {
          let data = props.data;
          const index = data.indexOf(oldData);
          data[index] = newData;
          updateVehicleCategory(newData);
        }
        resolve();
      }, 1000);
    })

  return (
    <MaterialTable
      icons={tableIcons}
      localization={tableLocalization}
      options={{
        paging: false,
        toolbar: false,
      }}
      columns={[
        {
          title: "",
          editable: "never",
          field: "imagePath",
          width: '56px',
          render: rowData => <img src={rowData.imageSmallPath} alt={`Vehicle class ${rowData.name}`} className={classes.vehicleClassRowImage} />
        },
        {
          title: <FormattedMessage id="name"/>,
          field: "name"
        }, {
          title: <FormattedMessage id="vehicle_category_max_passenger_count"/>,
          field: "maxPassengerCount",
          type: "numeric"
        }, {
          title: <FormattedMessage id="vehicle_category_max_baggage_count"/>,
          field: "maxBaggageCount",
          type: "numeric"
        }, {
          title: <><FormattedMessage id="vehicle_category_time_limit_create"/><br/>(<FormattedMessage id="vehicle_category_hours"/>)</>,
          field: "timeLimitHoursForOrderCreation",
          type: "numeric"
        }, {
          title: <><FormattedMessage id="vehicle_category_time_limit_modify"/><br/>(<FormattedMessage id="vehicle_category_hours"/>)</>,
          field: "timeLimitHoursForOrderModification",
          type: "numeric"
        }, {
          title: <><FormattedMessage id="vehicle_category_time_limit_cancel"/><br/>(<FormattedMessage id="vehicle_category_hours"/>)</>,
          field: "timeLimitHoursForOrderCancellation",
          type: "numeric"
        }
      ]}
      data={props.data}
      title={<FormattedMessage id="vehicle_categories" />}
      editable={{
        onRowUpdate: doUpdateVehicleCategory,
      }}
    >
    </MaterialTable>
  );

}
