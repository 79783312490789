import React, { useEffect, useState } from 'react'
import { Grid, Box } from '@material-ui/core'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Redirect } from 'react-router-dom'

const Home = (props) => {
  const [role, setRole] = useState('')
  const { oidcUser } = useReactOidc()
  const [stateUrl, setStateUrl] = useState()
  const invalidReturnUrls = [
      '/home',
      '/login',
      '/logout',
    ]

  useEffect(() => {
    if (oidcUser.profile && oidcUser.profile.role) {
      setRole(oidcUser.profile.role)
    }
    // State contains the user's previous URL what they tried
    // to reach before authentication. For example if user tried
    // to load /rides page as unauthenticated user then they
    // are redirected back to /rides after they have logged in
    if (oidcUser.state &&
        oidcUser.state?.url &&
        oidcUser.state?.url !== '/') {
        const url = oidcUser.state?.url
        let isValid = true
        if (invalidReturnUrls.some((u) => url.includes(u))) {
            isValid = false
        }
        if (isValid) {
            setStateUrl(oidcUser.state.url)
        }
    }
  }, [oidcUser])

  return (
    <div>
      <Grid container spacing={4}>
        <Box m={2} />
        <Grid item xs={12}>
          <Box m={4} />
          {stateUrl && <Redirect to={stateUrl} />}
          {!stateUrl &&
          <>
          {role === 'CustomerAdmin' && <Redirect to="/orders" />}
          {role === 'Customer' && <Redirect to="/orders" />}
          {role === 'Driver' && <Redirect to="/rides" />}
          {role === 'Organizer' && <Redirect to="/organizer" />}
          {role === 'Admin' && <Redirect to="/organizer" />}
          </> }
        </Grid>
      </Grid>
    </div>
  )
}

export default Home
