import React from 'react'
import { makeStyles, Grid, Input, Hidden } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PropTypes from 'prop-types'
import StatusIcon from './StatusIcon';
import { format } from 'date-fns'
import { views } from '../utils/constants';
import { FormattedMessage } from 'react-intl'
import RenderRouteSteps from './RenderRouteSteps';
import { ascendingRouteSteps } from '../utils/ascendingRouteSteps';
import {tuntiKikkulaSuomeksi, utcToFinnishTime, zonedToFinnishTime} from "../utils/orderUtils";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#F8F6F1',
        color: '#000',
        borderRadius: '2px',

        /* Width and margin below relates to: https://github.com/mui-org/material-ui/issues/7466*/
        width: '100% !important',
        margin: '0 !important',

        border: '1px solid #FFF',
        '&:hover': {
            border: '1px solid #B7A355',
        },
    },
    textAlignRight: {
        textAlign: 'right',
    },
    timePickerInput: {
        color: '#000',
        borderBottom: 'none',
        fontWeight: 'bold',
        '&>input': {
            textAlign: 'center'
        },
        '&:after': {
            border: 'none',
            borderBottomStyle: 'none',
        },
        '&:before': {
            border: 'none !important',
            borderBottomStyle: 'none',
        }
    },
    datePickerInput: {
        color: '#000',
        '&>input': {
            textAlign: 'center'
        },
        '&:after': {
            border: 'none'
        },
        '&:before': {
            border: 'none'
        }
    },
    statusTimeContainer: {
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'row'
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse'
        },
    },
    marginTop: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '10px',
        },
    },
    noWrap: {
        flexWrap: 'nowrap',
    },
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}))

const OrderTab = (props) => {
    const classes = useStyles();

    
    const {
        plannedPickupDate,
        requestedVehicleClassGuid,
        routeSteps,
        numberOfPassengers,
        passengerName,
        passengerOtherCompany,
    } = props.orderData
    const { status, rideId } = props

    const mapVehicleIdToName = (requestedVehicleClassGuid) => props.vehicleCategories && props.vehicleCategories.find(vehicle => vehicle.id === requestedVehicleClassGuid)?.name || '-autoa ei liitetty-'
    
    return (
        <Grid container alignItems="center" className={classes.container} spacing={2} onClick={props.onTabClick}>
            <Grid item xs={3} sm={3}>
                <Grid container alignItems="center" justify="center" wrap="nowrap" className={classes.statusTimeContainer}>
                    <Grid item>
                        <StatusIcon status={status} view={views.orderer} />
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Input
                                type="text"
                                disabled
                                className={classes.timePickerInput}
                                value={tuntiKikkulaSuomeksi(plannedPickupDate)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={9} sm={9}>
                <Grid container item sm={9}>
                    <Grid item xs={11}>
                        <Grid container direction="column" className={classes.noWrap}>
                            <Grid item><b>{passengerName}</b> {passengerOtherCompany && <>({passengerOtherCompany})</>}</Grid>
                            <RenderRouteSteps routeSteps={ascendingRouteSteps(routeSteps)} />
                            <Grid className={classes.marginTop} item><i>{mapVehicleIdToName(requestedVehicleClassGuid)}</i></Grid>
                        </Grid>
                    </Grid>
                    <Hidden smUp>
                        <Grid xs={1} item>
                            <Grid container direction="column">
                                {props.isOrderCancelable() && <DeleteIcon color="secondary" onClick={(e) => { e.stopPropagation(); props.cancelRide(rideId) }} />}
                                <FileCopyIcon color="secondary" onClick={(e) => { e.stopPropagation(); props.copyOrder(rideId) }} />
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
                <Grid container item xs={12} sm={3}>
                    <Grid container direction="row" spacing={1} justify="space-between" alignItems="center">
                        <Grid item>
                            <Grid item className={classes.textAlignRight}>{numberOfPassengers} <FormattedMessage id="pers" /></Grid>
                        </Grid>
                        <Hidden xsDown>
                            <Grid item>
                                <Grid container direction="column">
                                    {props.isOrderCancelable() && <DeleteIcon color="secondary" onClick={(e) => { e.stopPropagation(); props.cancelRide(rideId) }} />}
                                    <FileCopyIcon color="secondary" onClick={(e) => { e.stopPropagation(); props.copyOrder(rideId) }} />
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

OrderTab.propTypes = {
    orderData: PropTypes.shape({
        id: PropTypes.string,
        plannedPickupDate: PropTypes.string,
        subscriberUserName: PropTypes.string,
        requestedVehicleClassGuid: PropTypes.string,
        routeSteps: PropTypes.array,
        numberOfPassengers: PropTypes.number,
        additionalInfoDriver: PropTypes.bool,
        passengerName: PropTypes.string,
        passengerOtherCompany: PropTypes.string,
    }),
    status: PropTypes.number,
    vehicleCategories: PropTypes.array,
    rideId: PropTypes.string,
    cancelRide: PropTypes.func,
    copyOrder: PropTypes.func,
    onTabClick: PropTypes.func,
    isOrderCancelable: PropTypes.func,
}

export default OrderTab
