import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { format } from 'date-fns'

const DriveScheduleTypography = (props) => {
    const { derivedTime, driverName, id } = props

    return (
        <>
            <FormattedMessage id={id} />
            &nbsp;&#8594;&nbsp;
            {format(new Date(derivedTime), 'dd.MM HH:mm')}
            &nbsp;
            {`(${driverName})`}
        </>
    )
}

DriveScheduleTypography.propTypes = {
    id: PropTypes.string.isRequired,
    derivedTime: PropTypes.string.isRequired,
    driverName: PropTypes.string.isRequired,
}

export default DriveScheduleTypography
