import { WebStorageStateStore } from 'oidc-client'

const baseUrl = `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`
const applicationName = 'EdustusautotApp'
const storage = new WebStorageStateStore({
    prefix: applicationName,
})
const configuration = {
    client_id: 'ECSApp',
    redirect_uri: `${baseUrl}authentication/login-callback`,
    response_type: 'code',
    post_logout_redirect_uri: `${baseUrl}authentication/logout-callback`,
    scope: 'EdustusautotAppAPI openid profile',
    authority: baseUrl,
    silent_redirect_uri: `${baseUrl}authentication/login-callback`,
    automaticSilentRenew: false,
    loadUserInfo: true,
    triggerAuthFlow: true,
    UserStore: storage,
}

export default configuration
