import React, { useState, useEffect, } from 'react';
import { 
  makeStyles, Grid, Box, Button, Typography, Dialog, 
  DialogTitle, DialogContent,   DialogContentText, 
  DialogActions, } from '@material-ui/core';
import PartnersList from './PartnersList';

import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import PartnerDialog from './PartnerDialog';
import { useContext } from 'react';
import PartnerGroupsContext from '../../context/PartnerGroupsContext';

const useStyles = makeStyles((theme) => ({
  page: {
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      margin: '0 !important',
      left: '0',
      minWidth: '320px',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
    top: '60px',
  },
  container: {
    borderRadius: '2px',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: '320px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
    backgroundColor: '#FFF',
    margin: '0 !important',
    boxShadow: '0px 3px 6px #00000029',
    padding: '1em',
  },
  items: {
    color: '#000',
    width: '100%',
  },
  form: {
    margin: '0 !important',
  }

}));

export const Partners = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { refreshPartners } = useContext(PartnerGroupsContext);
  const [partners, setPartners] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [showPartnerList, setShowPartnerList] = useState(false);
  const [selectedPartnerList, setSelectedPartnerList] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const loadPartnerGroups = () => {
    fetch(`api/partnergroups`)
      .then(results => {
        if (!results.ok) {
          throw Error(results.status);
        }
        return results.json();
      }).then(data => {
        setPartners(data);
      }).catch(error => {
        enqueueSnackbar(<FormattedMessage id="partners_load_failed" />, { variant: "error" });
      });
  }

  const loadServiceProviders = () => {
    fetch(`api/organizations?organizationType=ServiceProvider`)
      .then(results => {
        if (!results.ok) {
          throw Error(results.status);
        }
        return results.json();
      }).then(data => {
        setServiceProviders(data);
      }).catch(error => {
        enqueueSnackbar(<FormattedMessage id="partners_load_failed" />, { variant: "error" });
      });
  }

  useEffect(() => {
    loadPartnerGroups();
    loadServiceProviders();
  }, []);

  const handleDialogOpen = () => {
    setSelectedPartnerList(null);
    setShowPartnerList(true);
  }

  const handleDialogClose = () => {
    setShowPartnerList(false);
  }

  const handleEdit = (partnerGroup) => {
    setSelectedPartnerList(partnerGroup);
    setShowPartnerList(true);
  }

  const handleDelete = (partnerGroup) => {
    setSelectedPartnerList(partnerGroup);
    setShowDeleteConfirmation(true);
  }

  const deleteSelected = () => {
    enqueueSnackbar(<FormattedMessage id="deleting" />, { variant: "info" });
    fetch((`/api/partnergroups/${selectedPartnerList?.id}`),{
      method: "DELETE"
    }).then(response => {
      if(!response.ok) {
        throw new Error(response.status);
      }
      enqueueSnackbar(<FormattedMessage id="delete_succeed"/>, {variant: "success"});
      loadPartnerGroups();
      setShowPartnerList(false);
      setShowDeleteConfirmation(false);
    }).catch(error => {
      enqueueSnackbar(<FormattedMessage id="delete_failed"/>, {variant: "warning"});
    });
  }

  const handleDialogSave = (listName, listPartners) => {
    enqueueSnackbar(<FormattedMessage id="saving_changes" />, { variant: "info" });
    const group = {
      id: selectedPartnerList?.id,
      name: listName,
      partners: listPartners
    };

    let isUpdate = selectedPartnerList?.id ? true : false;

    fetch((isUpdate ? `/api/partnergroups/${selectedPartnerList.id}` : "/api/partnergroups"),{
      method: (isUpdate ? "PUT" : "POST"),
      headers: {
        Accept: "application/json",
        "Content-type": "application/json"
      },
      body: JSON.stringify(group)
    }).then(response => {
      if(!response.ok) {
        throw new Error(response.status);
      }
      enqueueSnackbar(<FormattedMessage id="save_succeed"/>, {variant: "success"});
      loadPartnerGroups();
      refreshPartners();
      setShowPartnerList(false);
    }).catch(error => {
      console.log("virhe:" + error)
      enqueueSnackbar(<FormattedMessage id="save_failed"/>, {variant: "warning"});
    });
  }

  return (
    <div className={classes.page}>
      <Grid container justify="center" alignItems="center" direction="column" spacing={4} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3"><FormattedMessage id="partners_title" /></Typography>
          <Box m={1} />
        </Grid>
        <Grid item xs={12} className={classes.items}>
          <PartnersList 
            partners={partners}
            handleEdit={handleEdit} 
            handleDelete={handleDelete}
            handleNew={handleDialogOpen} />
        </Grid>
      </Grid>

      <PartnerDialog 
        open={showPartnerList}
        savedPartnerList={selectedPartnerList}
        serviceProviders={serviceProviders}
        handleClose={handleDialogClose}
        handleSave={handleDialogSave}        
        />

      <Dialog 
        open={showDeleteConfirmation}
        onClose={()=>setShowDeleteConfirmation(false)}
        >
        <DialogTitle>
          <FormattedMessage id="partners_delete_title"/>
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="primary">
            <FormattedMessage id="partners_delete_text" />
          </DialogContentText>
          <DialogActions>
            <Button id="deleteVerifyButton" onClick={deleteSelected} color="secondary">
              <FormattedMessage id="delete"/>
            </Button>
            <Button id="deleteCancelButton" onClick={()=>setShowDeleteConfirmation(false)} color="secondary">
              <FormattedMessage id="cancel"/>
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

    </div>
  );

}
