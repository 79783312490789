import React, { useContext } from 'react'
import MaterialTable from 'material-table'
import PropTypes from 'prop-types'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import { Select, MenuItem } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { tableLocalization, tableIcons } from './TableConfig'

export default function VehiclesTable(props) {
    const intl = useIntl()
    const { oidcUser } = useContext(AuthenticationContext)
    const {
        updateVehicle,
        deleteVehicle,
        categories,
        data,
     } = props

    const doUpdateVehicle = (newData) => new Promise((resolve) => {
      setTimeout(() => {
        updateVehicle(newData)
        resolve()
      }, 1000)
    })

    const doDeleteVehicle = (oldData) => new Promise((resolve) => {
      setTimeout(() => {
        deleteVehicle(oldData)
        resolve()
      }, 1000)
    })

    return (
        <div>
            <MaterialTable
              icons={tableIcons}
              localization={tableLocalization}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 20, 50],
              }}
              columns={[
                {
                  title: intl.formatMessage({ id: 'vehicle_license_plate' }),
                  field: 'licensePlate',
                  validate: (rowData) => rowData.licensePlate !== '',
                }, {
                  title: intl.formatMessage({ id: 'name' }),
                  field: 'name',
                  validate: (rowData) => rowData.name !== '',
                }, {
                  title: intl.formatMessage({ id: 'type' }),
                  field: 'category.name',
                  editComponent: (t) => (
                    <Select
                      id="categories"
                      value={t.value}
                      displayEmpty
                      onChange={(e) => {
                        t.onChange(e.target.value)
                      }}
                    >
                      {categories.map((category) => (
                        <MenuItem value={category.name} key={category.name}>{category.name}</MenuItem>
                      ))}
                    </Select>
                  ),
                }, {
                  title: intl.formatMessage({ id: 'description' }),
                  field: 'description',
                  validate: (rowData) => rowData.description !== '',
                }, {
                  title: intl.formatMessage({ id: 'vehicle_owner' }),
                  field: 'owner.name',
                  hidden: oidcUser?.profile?.role !== 'Admin',
                  editable: 'never',
                },
            ]}
              data={data}
              title="Ajoneuvot"
              editable={{
                onRowUpdate: doUpdateVehicle,
                onRowDelete: doDeleteVehicle,
              }}
            />
        </div>
    )
}

VehiclesTable.propTypes = {
    updateVehicle: PropTypes.func.isRequired,
    deleteVehicle: PropTypes.func.isRequired,
    categories: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
}

VehiclesTable.defaultProps = {
}
