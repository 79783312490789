import React, { useState, useEffect } from 'react'
import {
    Checkbox, makeStyles, DialogActions, Dialog,
    ListItem, ListItemIcon, ListItemText, DialogTitle,
    DialogContent, Button, IconButton, List, Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import partnerGroupsContext from '../../context/PartnerGroupsContext'

const useStyles = makeStyles((theme) => ({
    scheduleRow: {
        display: 'inline-flex',
        textTransform: 'none',
    },
    scheduleIcon: {
        marginRight: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogPaper: {
        minWidth: '280px',
    },
    customDialog: {
        minHeight: '100px',
        minWidth: '230px',
    },
    titlePanel: {
        backgroundColor: '#F8F6F1',
        margin: 0,
        padding: theme.spacing(2),
    },
}));

const PartnerGroupsDialog = (props) => {
    const classes = useStyles()
    const { partnerGroups } = React.useContext(partnerGroupsContext)
    const [checked, setChecked] = useState([])

    useEffect(() => {
        if (props.orderPartnerGroups) {
            // Set default values to checkboxes if editing existing list
            setChecked(props.orderPartnerGroups)
        } else {
            setChecked([])
        }
    }, [props.orderPartnerGroups])

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]
        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }
        setChecked(newChecked)
    };

    const handleSave = () => {
        props.handlePartnerGroupSave(checked)
    }

    return (
        <Dialog open={props.open} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle disableTypography className={classes.titlePanel}>
                <Typography variant="h6">
                    <FormattedMessage id="organizer_choose_partners" />
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.customDialog}>
                <List>
                    {partnerGroups?.length > 0 &&
                        partnerGroups.map((group, index) => {
                            return (
                                <ListItem key={index} dense button onClick={handleToggle(group.id)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            name={`group${index}`}
                                            value={group.id}
                                            checked={checked.indexOf(group.id) !== -1}
                                            tabIndex={-1}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={group.name}
                                        secondary={group.partnersNames} />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} id="save-partners-button" data-test-id="save-partners-button">
                    <FormattedMessage id="save" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

PartnerGroupsDialog.propTypes = {
    handlePartnerGroupSave: PropTypes.func,
    orderPartnerGroups: PropTypes.array,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
}

export default PartnerGroupsDialog
