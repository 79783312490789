import React, { useState, useContext } from 'react'
import MaterialTable from 'material-table'
import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { tableLocalization, tableIcons } from '../TableConfig'
import VehicleCategoriesContext from '../../context/VehicleCategoriesContext'

const PricingTable = (props) => {
    const intl = useIntl()
    const { categoryNameLookup } = useContext(VehicleCategoriesContext)

    const { priceComponents, addPriceComponent, updatePriceComponent, deletePriceComponent } = props

    return (
        <div>
            <MaterialTable
                icons={tableIcons}
                localization={tableLocalization}
                columns={[
                    { title: intl.formatMessage({ id: 'type' }), field: 'vehicleCategoryId', lookup: categoryNameLookup },
                    { title: intl.formatMessage({ id: 'pricing_starthour' }), field: 'startHour', type: 'numeric' },
                    { title: intl.formatMessage({ id: 'pricing_endhour' }), field: 'endHour', type: 'numeric' },
                    { title: intl.formatMessage({ id: 'pricing_daytype' }), field: 'dayType', lookup: { 0: 'Arkipäivä', 1: 'Viikonloppu/pyhä' } },
                    { title: intl.formatMessage({ id: 'pricing_priceperkilometer' }), field: 'pricePerKilometer', type: 'currency', currencySetting: { locale: 'fi', currencyCode: 'EUR' } },
                    { title: intl.formatMessage({ id: 'pricing_hourdriveprice' }), field: 'hourlyDrivePricePerHour', type: 'currency', currencySetting: { locale: 'fi', currencyCode: 'EUR' } },
                    { title: intl.formatMessage({ id: 'pricing_minimalfee' }), field: 'minimalFeePrice', type: 'currency', currencySetting: { locale: 'fi', currencyCode: 'EUR' } },
                    { title: intl.formatMessage({ id: 'pricing_baseprice' }), field: 'basePrice', type: 'currency', currencySetting: { locale: 'fi', currencyCode: 'EUR' } },
                    { title: intl.formatMessage({ id: 'pricing_waitingprice' }), field: 'waitingPricePerHour', type: 'currency', currencySetting: { locale: 'fi', currencyCode: 'EUR' } },
                    { title: intl.formatMessage({ id: 'pricing_hourlydrivebaseprice' }), field: 'hourlyDriveBasePrice', type: 'currency', currencySetting: { locale: 'fi', currencyCode: 'EUR' } },
                    { title: intl.formatMessage({ id: 'pricing_terminalpickupprice' }), field: 'terminalPickupPrice', type: 'currency', currencySetting: { locale: 'fi', currencyCode: 'EUR' } },
                ]}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                }}
                data={priceComponents}
                title={<FormattedMessage id="pricing_components" />}
                editable={{
                    onRowAdd: (newData) => new Promise((resolve) => {
                        setTimeout(() => {
                            addPriceComponent(newData)
                            resolve()
                        }, 100)
                    }),
                    onRowUpdate: (newData) => new Promise((resolve) => {
                        setTimeout(() => {
                            updatePriceComponent(newData)
                            resolve()
                        }, 100)
                    }),
                    onRowDelete: (oldData) => new Promise((resolve) => {
                        setTimeout(() => {
                            deletePriceComponent(oldData)
                            resolve()
                        }, 100)
                    }),
                }}
            />
        </div>
    )
}

PricingTable.propTypes = {
    priceComponents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        startHour: PropTypes.number.isRequired,
        endHour: PropTypes.number.isRequired,
        dayType: PropTypes.number,
        basePrice: PropTypes.number,
        minimalFeePrice: PropTypes.number,
        pricePerKilometer: PropTypes.number,
        perMinuteWaitingPrice: PropTypes.number,
        hourlyDrivePricePerHour: PropTypes.number,
    })).isRequired,
    addPriceComponent: PropTypes.func.isRequired,
    updatePriceComponent: PropTypes.func.isRequired,
    deletePriceComponent: PropTypes.func.isRequired,
}

export default PricingTable
