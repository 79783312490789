import React, { useContext, useEffect } from 'react'
import { Controller, useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { FormControl, DialogContent, FormHelperText, Grid, TextField, DialogTitle, Dialog, Select, MenuItem, InputLabel, DialogActions, Checkbox, Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event'
import VehicleCategoriesContext from '../../context/VehicleCategoriesContext';
import RideTimeLine from '../RideTimeLine';
import { ascendingRouteSteps } from '../../utils/ascendingRouteSteps';
import RenderGoogleMapsRouteLink from '../RenderGoogleMapsRouteLink';
import FileUpload from '../FileUpload';
import { useSnackbar } from 'notistack';
import { mapOrganizerEditRideDataToBackUpdate } from '../../utils/orderMapper';

const EditRideDialog = ({ showEditRideDialog, closeEditRideDialog, ride }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { categories } = useContext(VehicleCategoriesContext)
    const { register, unregister, control, handleSubmit, watch, getValues, setValue, formState: { errors } } = useForm({ defaultValues: { routeSteps: ascendingRouteSteps(ride.routeSteps) } })
    const { fields, append, remove, move } = useFieldArray({
        control,
        name: 'routeSteps',
    })

    const { plannedPickupDate, requestedVehicleClassGuid } = ride
    const { id: orderId, numberOfPassengers, driveType, terminalPickup, additionalInformation } = ride.order

    useEffect(() => {
        register('filesToUpload')
        return () => {
            unregister('filesToUpload')
        }
    }, [register, unregister])

    const uploadFile = (file) => {
        const formData = new FormData()
        formData.append('body', file)
        fetch(`/api/Attachments?orderId=${orderId}`, {
            method: 'POST',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    return enqueueSnackbar(<FormattedMessage id="order_file_upload_error" />, { variant: "error" });
                }
            })
            .catch(() => enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: "error" }))
    }

    const onSubmit = formData => {
        const formattedData = mapOrganizerEditRideDataToBackUpdate(formData, ride)
        fetch(`/api/Orders/${orderId}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                ...formattedData
            })
        })
            .then((response) => {
                if (!response.ok) { throw response }
                return response
            }).then(() => {
                // Upload possible new attachments..
                for (let d in formattedData.filesToUpload) {
                    uploadFile(formattedData.filesToUpload[d])
                }
                return enqueueSnackbar(<FormattedMessage id="order_save_success" />, { variant: "success" });
            })
            .catch((error) => {
                if (error.text) {
                    error.text().then(errorMessage => {
                        const errorMsg = errorMessage.slice(1, -1); //remove double quotation marks
                        enqueueSnackbar(<FormattedMessage id={errorMsg} />, { variant: "error" })
                    })
                } else {
                    enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: "error" })
                }
            })
            .finally(() => closeEditRideDialog())
    }

    return (
        <>
            <FormProvider getValues={getValues} fields={fields} control={control} append={append} remove={remove} move={move} errors={errors} setValue={setValue} watch={watch}>
                <Dialog
                    fullWidth
                    maxWidth={'md'}
                    open={showEditRideDialog}
                >
                    <DialogTitle><FormattedMessage id="organizer_edit_ride_confirmation" /></DialogTitle>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent>
                            <Grid container item spacing={1} direction="column">
                                <Grid item container direction="column" xs={12}>
                                <InputLabel name="plannedPickupDate" id="planned-pickup-date"><FormattedMessage id="order_pickup_date" /></InputLabel>
                                    <Controller
                                        control={control}
                                        name="plannedPickupDate"
                                        defaultValue={plannedPickupDate}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <KeyboardDateTimePicker
                                                ampm={false}
                                                id="plannedPickupDatePicker"
                                                format="dd.MM.yyyy HH:mm"
                                                minDateMessage={<FormattedMessage id="datepicker_error_minDateMessage" />}
                                                keyboardIcon={<EventIcon color="secondary" />}
                                                openTo="hours"
                                                onChange={(newDate) => onChange(newDate)}
                                                value={value}
                                            />)}
                                    />
                                </Grid>
                                <Grid item container direction="column" xs={12}>
                                    <FormControl error={Boolean(errors && errors.requestedVehicleClass)}>
                                        <InputLabel name="requestedVehicleClassGuidLabel" id="requested-vehicle-class-guid"><FormattedMessage id="order_car_type" /></InputLabel>
                                        <Controller
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    labelId="requested-vehicle-class-guid"
                                                    value={value}
                                                    onChange={(event) => onChange(event.target.value)}
                                                >
                                                    {categories && categories.map((vehicle) =>
                                                        <MenuItem key={vehicle.id} name={vehicle.name} value={vehicle.id}>{vehicle.name}</MenuItem>
                                                    )}
                                                </Select>
                                            )}
                                            rules={{ required: true }}
                                            control={control}
                                            name="requestedVehicleClassGuid"
                                            defaultValue={requestedVehicleClassGuid || ''}
                                        />
                                        {errors && errors.requestedVehicleClassGuid && <FormHelperText error={Boolean(errors.requestedVehicleClassGuid)}><FormattedMessage id="form_input_required" /></FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item container direction="column" xs={12}>
                                    <FormControl error={Boolean(errors && errors.numberOfPassengers)}>
                                        <Controller
                                            render={({ field: { onChange, value, name } }) => (
                                                <TextField
                                                    onChange={onChange}
                                                    value={value}
                                                    type="number"
                                                    name={name}
                                                    label={<FormattedMessage id="order_passenger_count" />}
                                                />
                                            )}
                                            defaultValue={numberOfPassengers || 0}
                                            control={control}
                                            name="numberOfPassengers"
                                            rules={{ required: true }}
                                        />
                                        {errors?.numberOfPassengers?.type === 'required' && <FormHelperText error><FormattedMessage id="form_input_required" /></FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item container direction="column" xs={12}>
                                    <FormControl error={Boolean(errors && errors.driveType)}>
                                        <InputLabel name="driveType" id="drive-type"><FormattedMessage id="order_drive_type" /></InputLabel>
                                        <Controller
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    value={value}
                                                    name="driveType"
                                                    labelId="drive-type"
                                                    onChange={(selected) => onChange(selected.target.value)}
                                                >
                                                    <MenuItem key={0} name="driveType1" value={0}><FormattedMessage id="order_drive_type_route" /></MenuItem>
                                                    <MenuItem key={2} name="driveType3" value={2}><FormattedMessage id="order_drive_type_hourly" /></MenuItem>
                                                </Select>
                                            )}
                                            rules={{ required: true }}
                                            control={control}
                                            name="driveType"
                                            defaultValue={driveType} // change this to be actual value from data
                                        />
                                        {errors && errors.driveType && <FormHelperText error={Boolean(errors.driveType)}><FormattedMessage id="form_input_required" /></FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item container direction="column" xs={12}>
                                    <RideTimeLine />
                                </Grid>
                                <Grid item container direction="column" xs={12}>
                                    <RenderGoogleMapsRouteLink routeSteps={watch('routeSteps')} />
                                </Grid>
                                <Box mt={2}/>
                                <Grid container alignItems="center">
                                    <FormControl>
                                        <Controller
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox
                                                    onChange={(event) => onChange(event.target.checked)}
                                                    checked={value}
                                                    value={value}
                                                    name="terminalPickup"
                                                />
                                            )}
                                            control={control}
                                            name="terminalPickup"
                                            defaultValue={terminalPickup}
                                        />
                                    </FormControl>
                                    <InputLabel name="terminalPickupLabel" id="terminal-pickup">
                                        <FormattedMessage id="order_terminal_pickup" />
                                    </InputLabel>
                                </Grid>
                                <Grid item container direction="column" xs={12}>
                                    <InputLabel name="additional_information" id="additional_information"><FormattedMessage id="order_additional_information" /></InputLabel>
                                    <FormControl error={Boolean(errors && errors.additionalInformation)}>
                                        <Controller
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    onChange={onChange}
                                                    value={value}
                                                    name="additionalInformation"
                                                    //label={<FormattedMessage id="order_additional_information" />}
                                                />
                                            )}
                                            defaultValue={additionalInformation || ''}
                                            control={control}
                                            name="additionalInformation"
                                            rules={{ required: false }}
                                        />
                                        {errors && errors.notes && <FormHelperText error={Boolean(errors.notes)}><FormattedMessage id="form_input_required" /></FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item container direction="column" xs={12}>
                                    <FileUpload orderId={orderId} />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                data-test-id="confirmEditRideButton"
                                type="submit"
                            >
                                <FormattedMessage id="save" />
                            </Button>
                            <Button
                                data-test-id="cancelEditRideButton"
                                onClick={() => closeEditRideDialog()}
                            >
                                <FormattedMessage id="cancel" />
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </FormProvider>
        </>
    );
}

export default EditRideDialog