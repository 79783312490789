import React, { useState, useEffect } from 'react'
import {
    Button, makeStyles, Grid, Checkbox,
    FormControlLabel, Typography, TextField, InputAdornment,
    DialogTitle, Dialog, DialogContent, DialogActions,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Email from '@material-ui/icons/Email'
import Phone from '@material-ui/icons/Phone'
import Lock from '@material-ui/icons/Lock'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    page: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
            left: '32px',
        },
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            transform: 'translateX(-50%)',
        },
        top: '80px',
    },
    container: {
        backgroundColor: '#FFF',
        color: '#000',
        borderRadius: '2px',
        boxShadow: '0px 3px 6px #00000029',
        padding: '1em',
        width: '100% !important',
    },
    errorRequired: {
        color: '#f00',
    },
    textAlignRight: {
        textAlign: 'right',
    },
}))

const UserRegistration = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const { register, control, handleSubmit, setValue, watch, formState: { errors } } = useForm()
    const [redirectDialogOpen, setRedirectDialogOpen] = useState(false)
    const classes = useStyles()

    const getInvitationToken = () => {
        const params = new URLSearchParams(window.location.search)
        const invitationToken = params.get('invitation')
        return invitationToken
    }

    const validateToken = () => {
        const invitationToken = getInvitationToken()
        fetch(`/api/users/validateinvitation?invitationToken=${invitationToken}`)
        .then((response) => {
            if (!response.ok) {
                throw Error()
            }
            return response.json()
        }).then((data) => {
            setValue('inviteEmail', data.emailAddress)
        }).catch(() => {
            // Fail silently
        })
    }

    useEffect(() => {
        validateToken()
    }, [])

    const onSubmit = (data, evt) => {
        evt.preventDefault()

        if (data.password !== data.passwordCheck) {
            enqueueSnackbar(<FormattedMessage id="reg_password_missmatch" />, { variant: 'warning' })
            return
        }

        const invitationToken = getInvitationToken()

        const registration = {
            fullName: data.fullName,
            username: data.inviteEmail,
            phone: data.phone,
            password: data.password,
            consent: data.consent,
            invitationToken,
        }

        fetch('/api/users/register', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(registration)
        }).then((response) => {
            if (response.ok) {
                setRedirectDialogOpen(true)
                enqueueSnackbar(<FormattedMessage id="reg_registration_success" />, { variant: 'success' })
            } else {
                return response.text()
            }
        }).then((responseBody) => {
            const err = JSON.parse(responseBody)
            if (err.code === 120) {
                enqueueSnackbar(<FormattedMessage id="reg_invite_expired" />, { variant: 'error' })
            } else {
                enqueueSnackbar(<FormattedMessage id="reg_registration_failed" />, { variant: 'error' })
            }
        }).catch(() => {
            // HTTP 302 redirect causes error with fetch eventhough it isn't
            // enqueueSnackbar(`Virhe rekisteröinnissä`, {variant: "error"});
        })
    }

    const redirectToLogin = () => {
        props.history.push('/')
    }
    
    return (
        <div className={classes.page}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4} justify="center" direction="column" className={classes.container}>
                    <Grid item xs={12}>
                        <Typography variant="h4"><FormattedMessage id="reg_welcome" /></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography><FormattedMessage id="reg_welcome_text" /></Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={6}>
                        <TextField
                            {...register('fullName', { required: true })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            id="fullName"
                            name="fullName"
                            label={<FormattedMessage id="reg_full_name_label" />}
                            error={Boolean(errors.fullName)}
                            helperText={errors.fullName && errors.fullName.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle color="secondary" />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} lg={6}>
                        <TextField
                            {...register('inviteEmail', {
                                required: true,
                            })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            disabled
                            id="inviteEmail"
                            name="inviteEmail"
                            type="email"
                            value={watch('inviteEmail') || ''}
                            label={<FormattedMessage id="reg_email_label" />}
                            error={Boolean(errors.inviteEmail)}
                            helperText={errors.inviteEmail && errors.inviteEmail.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email color="secondary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} lg={6}>
                        <TextField
                            {...register('phone', {
                                required: true,
                                pattern: {
                                    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/i,
                                    message: <FormattedMessage id="reg_phone_error" />,
                                },
                            })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            id="phone"
                            name="phone"
                            label={<FormattedMessage id="reg_phone_number_label" />}
                            error={Boolean(errors.phone)}
                            helperText={errors.phone && errors.phone.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Phone color="secondary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            <FormattedMessage id="reg_password_text" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={6}>
                        <TextField
                            {...register('password', {
                                required: true,
                                pattern: {
                                    value: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/i,
                                    message: <FormattedMessage id="reg_password_complexity" />
                                },
                            })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            id="password"
                            name="password"
                            type="password"
                            label={<FormattedMessage id="reg_password_label" />}
                            error={Boolean(errors.password)}
                            helperText={errors.password && errors.password.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock color="secondary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} lg={6}>
                        <TextField
                            {...register('passwordCheck', { required: true })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            id="passwordCheck"
                            name="passwordCheck"
                            type="password"
                            label={<FormattedMessage id="reg_password_re_enter_label" />}
                            error={Boolean(errors.passwordCheck)}
                            helperText={errors.passwordCheck && errors.passwordCheck.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock color="secondary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="consent"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    value="true"
                                    name='consent'
                                    control={
                                        <Checkbox
                                            value={value}
                                            onChange={(e) => onChange(e.target.checked)}
                                            color="primary"
                                            id="consent"
                                        />
                                    }
                                    label={<span className={Boolean(errors.consent) ? classes.errorRequired : null}>Hyväksyn palvelun <a target="_blank" href="/usagepolicy">käyttöehdot</a></span>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Button
                            type="submit"
                            fullWidth
                            id="save"
                            label="Rekisteröidy"
                            variant="contained"
                        >
                            <FormattedMessage id="save" />
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Dialog
                open={redirectDialogOpen}
                onClose={redirectToLogin}
            >
                <DialogTitle><FormattedMessage id="reg_dialog_registration_success" /></DialogTitle>
                <DialogContent><FormattedMessage id="reg_dialog_content" /></DialogContent>
                <DialogActions>
                    <Button onClick={redirectToLogin} data-test-id="redirectToLoginButton">
                        <FormattedMessage id="reg_dialog_ok_button" />
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )

}

export default UserRegistration
