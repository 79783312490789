import React, { useState, useEffect } from 'react';
import VehicleCategoriesTable from './VehicleCategoriesTable';
import { Grid, Box, } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

export const VehicleCategories = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);

  const loadData = () => {
    fetch("/api/vehiclecategories")
      .then(results => {
        return results.json();
      }).then(data => {
        setData(data)
      });
  }

  useEffect(() => {
    loadData()
  }, []);

  const updateVehicleCategory = (vehicleCategory) => {

    fetch(`/api/vehiclecategories/${vehicleCategory.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        id: vehicleCategory.id,
        name: vehicleCategory.name.trim(),
        maxPassengerCount: parseInt(vehicleCategory.maxPassengerCount),
        timeLimitHoursForOrderCancellation: parseFloat(vehicleCategory.timeLimitHoursForOrderCancellation),
        timeLimitHoursForOrderModification: parseFloat(vehicleCategory.timeLimitHoursForOrderModification),
        timeLimitHoursForOrderCreation: parseFloat(vehicleCategory.timeLimitHoursForOrderCreation),
        maxBaggageCount: parseFloat(vehicleCategory.maxBaggageCount),
      })
    }).then(response => {
      if(!response.ok) {
        throw Error(response.status);
      }
      loadData();
      enqueueSnackbar(<FormattedMessage id="save_succeed" />, {variant: "success"});
    }).catch(error => {
      console.log(error);
      loadData();
      enqueueSnackbar(<FormattedMessage id="save_failed" />, {variant: "error"});
    });

  }

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <h1><FormattedMessage id="vehicle_categories" /></h1>
          <Box m={4} />
          <VehicleCategoriesTable
            data={data}
            updateVehicleCategory={updateVehicleCategory}
          />
          <Box m={4} />
        </Grid>
      </Grid>
    </div>
  );

}