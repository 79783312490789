import React, { useState } from 'react'
import { IntlProvider } from 'react-intl'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import en from '../translations/en'
import fi from '../translations/fi'

const Context = React.createContext()

const languageSwitch = (lang) => ({
    null: 'fi',
    fi: 'fi',
    en: 'en',
})[lang]

const IntlProviderWrapper = (props) => {
    const localStorageLang = localStorage.getItem('apperClass_lang')
    const [locale, setLocale] = useState(languageSwitch(localStorageLang))
    const [messages, setMessages] = useState(languageSwitch(localStorageLang) === 'en' ? en : fi)

    const localeData = {
        locale,
        messages,
        toggleEn: () => {
            setLocale('en')
            localStorage.setItem('apperClass_lang', 'en')
            setMessages(en)
        },
        toggleFi: () => {
            setLocale('fi')
            localStorage.setItem('apperClass_lang', 'fi')
            setMessages(fi)
        },
    }
    const { children } = props
    return (
        <Context.Provider value={localeData}>
            <IntlProvider
                locale={locale}
                messages={messages}
                defaultLocale={languageSwitch(localStorageLang)}
            >
                {children}
            </IntlProvider>
            <Helmet htmlAttributes={{ lang: locale }} />
        </Context.Provider>
    )
}

IntlProviderWrapper.propTypes = {
    children: PropTypes.node,
}

IntlProviderWrapper.defaultProps = {
    children: undefined,
}

export { IntlProviderWrapper, Context as IntlContext }
