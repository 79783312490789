import React, { useState, useEffect, useCallback } from 'react'
import {
  makeStyles, Grid, Box, CircularProgress,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import {
  format, addDays, subDays, differenceInMinutes, startOfWeek, endOfWeek,
} from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { parseISO } from 'date-fns/esm'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import WeekBrowser from './WeekBrowser'
import DriveScheduleTypography from './DriveScheduleTypography'

const useStyles = makeStyles((theme) => ({
  page: {
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      margin: '0 !important',
      left: '0',
      minWidth: '320px',
    },
    [theme.breakpoints.up('sm')]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
    top: '60px',
  },

  container: {
    width: '100%',
    borderRadius: '2px',
    minWidth: '320px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
    backgroundColor: '#FFF',
    margin: '0 !important',
    boxShadow: '0px 3px 6px #00000029',
    padding: '1em',
  },

  table: {
    width: '100%',
    '& th': {
      padding: theme.spacing(1),
      textOverflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& td': {
      padding: theme.spacing(1),
    },
    '& tr': {
      borderTop: '1px solid #B7A355',
    }
  },

  genericProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: 0,
    marginLeft: 0,
  },

  schHeader: {
    backgroundColor: '#F8F6F1',
    border: '1px solid #B7A355',
  },
}))


const MySchedule = () => {
  const { oidcUser } = useReactOidc()
  const { profile } = oidcUser
  const [selectedWeek, setSelectedWeek] = useState(startOfWeek(new Date(), {weekStartsOn: 1}))
  const [schedules, setSchedules] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)

  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const handlePrevClick = () => {
    setSelectedWeek(subDays(selectedWeek, 7))
  }

  const handleNextClick = () => {
    setSelectedWeek(addDays(selectedWeek, 7))
  }

  const loadSchedules = useCallback(() => {
    setShowSpinner(true)
    const driverId = profile?.userid
    const orgId = profile?.org
    fetch(`/api/vehicleschedules?organizationId=${orgId}&includePrevNext=true&startTime=${startOfWeek(selectedWeek, { weekStartsOn: 1 }).toISOString()}&endTime=${endOfWeek(selectedWeek, { weekStartsOn: 1 }).toISOString()}&driverId=${driverId}&page=1&pageSize=100`)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.status)
        }
        return results.json()
      }).then((data) => {
        setSchedules(data.results)
        setShowSpinner(false)
      }).catch(() => {
        setShowSpinner(false)
        enqueueSnackbar(<FormattedMessage id="schedules_load_error" />, { variant: 'error' })
      })
  }, [enqueueSnackbar, selectedWeek])

  useEffect(() => {
    loadSchedules()
  }, [selectedWeek, loadSchedules])

  const formatHhMm = (endTime, startTime) => {
    const totalMinutes = differenceInMinutes(endTime, startTime)
    const hoursDecimal = totalMinutes / 60.0
    const hours = Math.floor(hoursDecimal)
    const minutes = parseInt((hoursDecimal % 1) * 60)
    return <>{hours}:{minutes < 10 ? `0${minutes}` : minutes}</>
  }

  const formatSamedayHhMm = (dateStamp) => {
    let stamp = ''
    stamp += `${format(dateStamp, 'dd.MM.')} `
    stamp += format(dateStamp, 'HH:mm')
    return stamp
  }

  return (
    <div className={classes.page}>
      <Grid container className={classes.container}>
        <Grid container justify="center" alignItems="center" direction="column">
          <Grid item xs={12}>
            <h1><FormattedMessage id="schedules_my_schedule_title" /></h1>
            <Box m={4} />
            <WeekBrowser
              hideResultCount
              handlePrevClick={handlePrevClick}
              handleNextClick={handleNextClick}
              date={selectedWeek}
            />
            <Box m={4} />
            {showSpinner && <CircularProgress size={24} className={classes.genericProgress} />}
            <Box m={4} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <table className={classes.table}>
            <thead className={classes.schHeader}>
              <tr>
                <th>
                  <FormattedMessage id="schedules_driver" />
                </th>
                <th>
                  <FormattedMessage id="schedules_vehicle" />
                </th>
                <th>
                  <FormattedMessage id="schedules_prev_shift" /><br/>
                  <FormattedMessage id="schedules_start" /> &#8594; <FormattedMessage id="schedules_end" /><br/>
                  <FormattedMessage id="schedules_next_shift" />
                </th>
              </tr>
            </thead>

            <tbody>
              {schedules.map(sch => (
                <tr key={sch.id}>
                  <td>{sch.driverName}</td>
                  <td><b>{sch.licensePlate}</b><br/>{sch.vehicleName}</td>
                  <td>
                  {sch.previousVehicleSchedule &&
                    <DriveScheduleTypography
                      id="previous"
                      derivedTime={sch.previousVehicleSchedule.derivedTime}
                      driverName={sch.previousVehicleSchedule.driverName }
                    />}
                    <br />
                    <b>{formatSamedayHhMm(parseISO(sch.startTime))} &#8594; {formatSamedayHhMm(parseISO(sch.endTime))}
                &nbsp;(<FormattedMessage id="schedules_total_abbrv" /> {formatHhMm(parseISO(sch.endTime), parseISO(sch.startTime))})</b>
                    <br />
                    {sch.nextVehicleSchedule &&
                      <DriveScheduleTypography
                      id="next"
                      derivedTime={sch.nextVehicleSchedule.derivedTime}
                      driverName={sch.nextVehicleSchedule.driverName }
                      />}
                  </td>
                  {/* v */}
                </tr>
              ))
              }
            </tbody>
          </table>
        </Grid>
      </Grid>

    </div>
  )
}

export default MySchedule
