import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { addDays, formatISO } from 'date-fns'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import rideStatusContext from './RidesStatusContext'

const RideStatusProvider = (props) => {
  const [unhandledRidesCount, setUnhandledRidesCount] = useState(0)
  const [unapprovedRidesCount, setUnapprovedRidesCount] = useState(0)
  const { oidcUser } = React.useContext(AuthenticationContext)

  const refreshCounts = () => {
    if (oidcUser?.profile?.userid == null ||
        !['Driver', 'Admin', 'Organizer'].includes(oidcUser?.profile?.role)) {
      return
    }

    const startDate = addDays(new Date(), -1)
    const endDate = addDays(new Date(), 90)

    if (['Admin', 'Organizer'].includes(oidcUser?.profile?.role)) {
      // Rides with status ordered (=waiting for confirmation = 10)
      // Show statuses only from own organization
      // Future: Show unconfirmed orders red status ball only from own organization (EDUS-619)
      // const userOrgId = oidcUser?.profile?.org; // ..and add it to URL: &serviceProviderId=${userOrgId}
      const organizerUrl = `/api/Rides?page=1&pageSize=0&startDate=${formatISO(startDate, { representation: 'date' })}&endDate=${formatISO(endDate, { representation: 'date' })}&statuses=10` // eslint-disable-line

      fetch(organizerUrl)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.status)
        }
        return results.json()
      }).then((data) => {
        setUnhandledRidesCount(data.totalCount)
      }).catch((error) => {
        console.log(error)
      })
    }

    // Rides waiting for driver approval
    const driverUrl = `/api/Rides?page=1&pageSize=0&startDate=${formatISO(startDate, { representation: 'date' })}&endDate=${formatISO(endDate, { representation: 'date' })}&statuses=30&assignedDriverId=${oidcUser?.profile?.userid}` // eslint-disable-line
    fetch(driverUrl)
    .then((results) => {
      if (!results.ok) {
        throw Error(results.status)
      }
      return results.json()
    }).then((data) => {
      setUnapprovedRidesCount(data.totalCount)
    }).catch((error) => {
      console.log(error)
    })
  }

  const fiveMinutesInMs = 300000

  useEffect(() => {
    refreshCounts()
    const interval = setInterval(() => {
      refreshCounts()
    }, fiveMinutesInMs)
    return () => clearInterval(interval)
  }, [oidcUser])

  return (
    <rideStatusContext.Provider value={{ unhandledRidesCount, unapprovedRidesCount, refreshCounts }}>
      {props.children}
    </rideStatusContext.Provider>
  )
}

RideStatusProvider.propTypes = {
  children: PropTypes.node,
}

export default RideStatusProvider
