import React, { useState } from 'react'
import {
    makeStyles,
    TextField,
    Grid,
    Button,
    Box,
    Checkbox,
    FormControlLabel,
    CircularProgress,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import qs from 'query-string'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
    page: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
            left: '0',
            minWidth: '320px',
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
        },
        top: '80px',
    },
}))

export default function CustomLogin(props) {
    const [loggingIn, setLoggingIn] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const { register, handleSubmit, control, formState: { errors } } = useForm()
    const classes = useStyles()

    const onSubmit = (data, evt) => {
        evt.preventDefault()

        const parsed = qs.parse(window.location.search)
        const returnUrl = parsed.ReturnUrl

        const details = {
            username: data.username,
            password: data.password,
            returnUrl,
            rememberMe: data.rememberMe,
        }

        setLoggingIn(true)
        fetch('/api/users/login', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(details),
        }).then((results) => {
            if (results.redirected) {
                window.location.href = results.url
            }
            if (!results.ok) {
                if (results.status === 401) {
                    enqueueSnackbar(<FormattedMessage id="login_failed" />, { variant: 'warning' })
                    setLoggingIn(false)
                } else {
                    setLoggingIn(false)
                    throw Error(results.status)
                }
            } else {
                enqueueSnackbar(<FormattedMessage id="login_success" />, { variant: 'success' })
                props.history.push('/')
            }
        }).catch(() => {
            setLoggingIn(false)
        })
    }
    return (
        <div className={classes.page}>
            <Box m={6} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    spacing={4}
                >
                    <Grid item xs={10}>
                        <h2><FormattedMessage id="login_title" /></h2>
                        <Box m={4} />
                    </Grid>
                    <Grid item xs={10}>
                        <TextField
                            {...register('username', {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: <FormattedMessage id="login_invalid_email" />,
                                },
                            })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            id="username"
                            name="username"
                            error={Boolean(errors.username)}
                            helperText={errors.username && errors.username.message}
                            label={<FormattedMessage id="login_username" />}
                            inputProps={{ 'data-test-id': 'login-username' }}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextField
                            {...register('password', { required: true })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="Password"
                            id="password"
                            name="password"
                            error={Boolean(errors.password)}
                            label={<FormattedMessage id="login_password" />}
                            inputProps={{ 'data-test-id': 'login-password' }}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <Controller
                            control={control}
                            name="rememberMe"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={value}
                                            onChange={(e) => onChange(e.target.checked)}
                                            id="rememberMe"
                                            color="primary"
                                            label={<FormattedMessage id="login_remember_me" />}
                                            inputProps={{ 'data-test-id': 'login-rememberme' }}
                                        />
                                    }
                                    label={<FormattedMessage id="login_remember_me" />}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        {!loggingIn &&
                            <Button
                                type="submit"
                                fullWidth
                                id="login"
                                data-test-id="login-button"
                                variant="outlined"
                            >
                                <FormattedMessage id="login_login" />
                            </Button> }
                        {loggingIn && <CircularProgress size={24} />}
                    </Grid>
                </Grid>
            </form>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={4}
            >
                <Grid item>
                    <Button href="/forgot-password"><FormattedMessage id="login_forgot_password" /></Button>
                </Grid>
            </Grid>

        </div>
    )
}
