import React, {
    useEffect,
    useCallback,
    useContext,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import {
    Divider,
    Grid,
    Typography,
    makeStyles,
    Box,
} from '@material-ui/core'
import { addMinutes } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { addHours, format, set } from 'date-fns/esm'

import VehicleCategoriesContext from '../context/VehicleCategoriesContext'

const useStyles = makeStyles(() => ({
    bold: {
        fontWeight: 700,
    },
}))

const currencyIntl = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' })

const EstimatedPrice = (props) => {
    const classes = useStyles()
    const { categories } = useContext(VehicleCategoriesContext)
    const [showPriceEstimate, setShowPriceEstimate] = useState(false)
    const [estimatedPriceData, setEstimatedPriceData] = useState({})

    const fetchPriceEstimate = useCallback((priceCalculationItems) => {
        const url = `api/pricing/estimate?vehicleCategoryId=${priceCalculationItems.vehicleCategoryId}&startTime=${new Date(priceCalculationItems.startTime).toISOString()}&endTime=${addMinutes(new Date(priceCalculationItems.endTime), priceCalculationItems.distanceMins).toISOString()}&rideLengthKm=${priceCalculationItems.rideLengthKm}&waitingMinutes=${priceCalculationItems.waitingMinutes}&isHourDrive=${priceCalculationItems.isHourDrive}&terminalPickup=${priceCalculationItems.terminalPickup}`
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.text()
                }
                return setShowPriceEstimate(false)
            })
            .then((priceData) => {
                if (!priceData) {
                    setShowPriceEstimate(false)
                } else {
                    const parsedPriceData = JSON.parse(priceData)
                    setEstimatedPriceData(parsedPriceData)
                    setShowPriceEstimate(true)
                }
            })
    }, [])

    const calculatePriceEstimate = useCallback((priceCalculationItems, distanceKms, distanceMins) => {
        const priceCalculationItemsCombined = { ...priceCalculationItems, rideLengthKm: distanceKms, distanceMins }
        fetchPriceEstimate(priceCalculationItemsCombined)
    }, [])

    useEffect(() => {
        const { routeSteps, priceCalculationItems } = props
        if (
            priceCalculationItems.vehicleCategoryId &&
            typeof priceCalculationItems.startTime !== 'undefined' &&
            !isNaN(priceCalculationItems.startTime) &&
            typeof priceCalculationItems.endTime !== 'undefined' &&
            !isNaN(priceCalculationItems.endTime) &&
            priceCalculationItems.rideLengthKm >= 0 &&
            priceCalculationItems.waitingMinutes >= 0 &&
            typeof priceCalculationItems.isHourDrive !== 'undefined' &&
            routeSteps.length >= 2
        ) {
            const addressesQuery = routeSteps.filter(routeStep => routeStep.address && routeStep.address).map(routeStep => routeStep.address)

            if(addressesQuery.length < 2) return setShowPriceEstimate(false)
            
            const addressesQueryJoined = 'address='+addressesQuery.map((address, index) => index !== addressesQuery.length +1 && address).join('&address=')
            const url = `api/map/distance?${addressesQueryJoined}`
            
            fetch(url)
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    return setShowPriceEstimate(false)
                })
                .then((distanceData) => ({ kms: distanceData.kms, mins: distanceData.mins }))
                .then((distance) => {
                    calculatePriceEstimate(props.priceCalculationItems, distance.kms, distance.mins)
                })
        }
    }, [props])
    return (
        !showPriceEstimate ?
            <Grid container justify="center">
                <Grid item>
                    <Box m={4}>
                        <FormattedMessage id="price_no_estimate" />
                    </Box>
                </Grid>
            </Grid> :
            <Box m={4}>
                <Grid container direction="column">
                    <Grid item container xs={12}>
                        <Grid item container direction="column" xs={3}>
                            <Grid item>
                                <Typography className={classes.bold}>
                                    {categories.filter((c) => c.id === props.priceCalculationItems.vehicleCategoryId)[0]?.name}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.bold}>
                                    {estimatedPriceData.pricingDayType === 0 ? <FormattedMessage id="pricing_weekday_ride" /> : <FormattedMessage id="pricing_weekend_or_holiday_ride" />}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.bold}>
                                    <FormattedMessage id="pricing_start_time" /> {estimatedPriceData.priceListStartHour}-{estimatedPriceData.priceListEndHour}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} />
                        <Grid item xs={3}>
                            <Typography className={classes.bold}>
                                <FormattedMessage id="pricing_quantity" />
                            </Typography>
                        </Grid>
                        <Grid item container xs={3} justify="flex-end">
                            <Typography className={classes.bold}>
                                <FormattedMessage id="price_total_abbrv" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid item container xs={12}>
                        {estimatedPriceData.rows.find((item) => item.chargeType === 0) &&
                            <Grid item container>
                                <Grid item xs={3}>
                                    <Typography>
                                        <FormattedMessage id="priceitem_base_fee" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        {estimatedPriceData.rows.find((item) => item.chargeType === 0).unitPrice} €
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        {estimatedPriceData.rows.find((item) => item.chargeType === 0).quantity}
                                    </Typography>
                                </Grid>
                                <Grid item container xs={3} justify="flex-end">
                                    <Typography>
                                        {currencyIntl.format(Math.round(estimatedPriceData.rows.find((item) => item.chargeType === 0).total * 100) / 100)}
                                    </Typography>
                                </Grid>
                            </Grid>}
                        {estimatedPriceData.rows.find((item) => item.chargeType === 1) &&
                            <Grid item container>
                                <Grid item xs={3}>
                                    <Typography>
                                        <FormattedMessage id="priceitem_hourlydrivebase_fee" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        {currencyIntl.format(Math.round(estimatedPriceData.rows.find((item) => item.chargeType === 1).unitPrice * 100) / 100)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        {estimatedPriceData.rows.find((item) => item.chargeType === 1).quantity}
                                    </Typography>
                                </Grid>
                                <Grid item container xs={3} justify="flex-end">
                                    <Typography>
                                        {currencyIntl.format(Math.round(estimatedPriceData.rows.find((item) => item.chargeType === 1).total * 100) / 100)}
                                    </Typography>
                                </Grid>
                            </Grid>}
                        {estimatedPriceData.rows.find((item) => item.chargeType === 2) &&
                            <Grid item container>
                                <Grid item xs={3}>
                                    <Typography>
                                        <FormattedMessage id="priceitem_km_fee" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        {currencyIntl.format(Math.round(estimatedPriceData.rows.find((item) => item.chargeType === 2).unitPrice * 100) / 100)}/km
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        {estimatedPriceData.rows.find((item) => item.chargeType === 2).quantity} <FormattedMessage id="price_estimate" />
                                    </Typography>
                                </Grid>
                                <Grid item container xs={3} justify="flex-end">
                                    <Typography>
                                        {currencyIntl.format(Math.round(estimatedPriceData.rows.find((item) => item.chargeType === 2).total * 100) / 100)}
                                    </Typography>
                                </Grid>
                            </Grid>}
                        {estimatedPriceData.rows.find((item) => item.chargeType === 3) &&
                            <Grid item container>
                                <Grid item xs={3}>
                                    <Typography>
                                        <FormattedMessage id="pricing_waiting_time" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        {currencyIntl.format(Math.round(estimatedPriceData.rows.find((item) => item.chargeType === 3).unitPrice * 100) / 100)}/h
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        {format(addHours(set(new Date(), { hours: 0, minutes: 0 }), estimatedPriceData.rows.find((item) => item.chargeType === 3).quantity), 'HH:mm')}
                                    </Typography>
                                </Grid>
                                <Grid item container xs={3} justify="flex-end">
                                    <Typography>
                                        {currencyIntl.format(Math.round(estimatedPriceData.rows.find((item) => item.chargeType === 3).total * 100) / 100)}
                                    </Typography>
                                </Grid>
                            </Grid>}

                        {estimatedPriceData.rows.find((item) => item.chargeType === 4) &&
                            <Grid item container>
                                <Grid item xs={3}>
                                    <Typography>
                                        <FormattedMessage id="priceitem_hourly_drive" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        {currencyIntl.format(Math.round(estimatedPriceData.rows.find((item) => item.chargeType === 4).unitPrice * 100) / 100)}/h
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        <FormattedMessage id="price_hourly_min_hours" />
                                    </Typography>
                                </Grid>
                                <Grid item container xs={3} justify="flex-end">
                                    <Typography>
                                        {currencyIntl.format(Math.round(estimatedPriceData.rows.find((item) => item.chargeType === 4).total * 100) / 100)}
                                    </Typography>
                                </Grid>
                            </Grid>}
                    </Grid>
                    <Divider />
                    <Grid item container>
                        <Grid item xs={9}>
                            <Typography className={classes.bold}>
                                <FormattedMessage id="price_minimum_price" />
                            </Typography>
                        </Grid>
                        <Grid item container xs={3} justify="flex-end">
                            <Typography className={classes.bold}>
                                {currencyIntl.format(Math.round(estimatedPriceData.minimumPrice * 100) / 100)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    {estimatedPriceData.rows.find((item) => item.chargeType === 5) &&
                    <>
                        <Grid item container>
                            <Grid item xs={9}>
                                <Typography>
                                    <FormattedMessage id="priceitem_terminal_pickup_fee" />
                                </Typography>
                            </Grid>
                            <Grid item container xs={3} justify="flex-end">
                                <Typography>
                                    {currencyIntl.format(Math.round(estimatedPriceData.rows.find((item) => item.chargeType === 5).total * 100) / 100)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                    </>
                    }
                    <Grid item container direction="column">
                        <Grid item container>
                            <Grid item xs={9}>
                                <Typography className={classes.bold}>
                                    <FormattedMessage id="price_estimate_price" />
                                </Typography>
                            </Grid>
                            <Grid item xs={3} container justify="flex-end">
                                <Typography className={classes.bold}>
                                    {currencyIntl.format(Math.round(estimatedPriceData.total * 100) / 100)}
                        </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container>
                            <Grid item xs={9}>
                                <Typography>
                                    <FormattedMessage id="pricing_total_vat" /> ({estimatedPriceData.vatPercent}%)
                        </Typography>
                            </Grid>
                            <Grid item xs={3} container justify="flex-end">
                                <Typography>
                                    {currencyIntl.format(Math.round(estimatedPriceData.totalVat * 100) / 100)}
                        </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
    )
}

EstimatedPrice.propTypes = {
    routeSteps: PropTypes.arrayOf(PropTypes.shape({
        address: PropTypes.string,
    })),
    priceCalculationItems: PropTypes.shape({
        vehicleCategoryId: PropTypes.string,
        startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        rideLengthKm: PropTypes.number,
        waitingMinutes: PropTypes.number,
        isHourDrive: PropTypes.bool,
        terminalPickup: PropTypes.bool,
    }),
}

EstimatedPrice.defaultProps = {
    routeSteps: {
        address: '',
    },
    priceCalculationItems: {
        vehicleCategoryId: '',
        startTime: '',
        endTime: '',
        rideLengthKm: 0,
        waitingMinutes: 0,
        isHourDrive: false,
    },
}

export default EstimatedPrice
