import React, { useCallback, useContext, useState, useEffect } from 'react';
import VehiclesTable from './VehiclesTable';
import { 
  TextField, MenuItem, InputLabel,
  Grid, Button, Box, Select,
  Dialog, DialogContent,
  DialogTitle, DialogActions, Typography, } from '@material-ui/core';
import uuid from 'uuid/v4';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import OrganizationsDropdown from './OrganizationDropdown';
import { AuthenticationContext } from '@axa-fr/react-oidc-context';

export const Vehicles = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [owner, setOwner] = useState();
  const [data, setData] = useState([])
  const { oidcUser } = useContext(AuthenticationContext)

  const loadData = useCallback(() => {
    fetch("/api/vehicles")
    .then(results=> {
      return results.json();
    }).then(data => {
      setData(data)
    });

    if(!categories || categories.length===0) {
      fetch("/api/vehiclecategories")
      .then(results=> {
        return results.json();
      }).then(data => {
        setCategories(data);
      });
    }
  },[categories])

  useEffect(() => {
      loadData()
  }, [loadData])


 const handleSubmit = (e) => {
    e.preventDefault();
    
    let category = categories.find(category => category.id === selectedCategory);
    let ownerId = oidcUser?.profile?.role === "Organizer" ? oidcUser?.profile?.org : owner?.id;

    const vehicle = {
      id: uuid(),
      name: e.target.vehicleName.value,
      description: e.target.vehicleDescription.value,
      licensePlate: e.target.vehicleLicensePlate.value,
      category: category,
      owner: {
        id: ownerId,
      }
    }

    fetch("/api/vehicles",{
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json"
      },
      body: JSON.stringify(vehicle)
    }).then(response => {
      if(!response.ok) {
        throw new Error(response.status);
      }
      enqueueSnackbar(<FormattedMessage id="save_succeed"/>, {variant: "success"});
      loadData()
      closeDialog();
    }).catch(error => {
      enqueueSnackbar(<FormattedMessage id="save_failed"/>, {variant: "warning"});
    });
  }

  const openDialog = () => {
   setDialogOpen(true)
  }
  const closeDialog = () => {
   setDialogOpen(false)
  }
  
  const updateVehicle = (vehicle) => {
    fetch(`/api/vehicles/${vehicle.id}`,{
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        id: vehicle.id,
        name: vehicle.name,
        description: vehicle.description,
        licensePlate: vehicle.licensePlate,
        category: vehicle.category,        
      })
    }).then(() => {
      enqueueSnackbar(<FormattedMessage id="save_succeed"/>, {variant: "success"});
      loadData()
    })
  } 

  const deleteVehicle = (vehicle) => {
    fetch(`/api/vehicles/${vehicle.id}`, {
      method: "DELETE"
    }).then(() => {
      enqueueSnackbar(<FormattedMessage id="delete_succeed"/>, {variant: "success"});
      loadData()
    });
  }

  
    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <h1><FormattedMessage id="vehicle_management_title" /></h1>
            <Box m={4} />
            <VehiclesTable 
              data={data} 
              categories={categories}
              updateVehicle={updateVehicle}
              deleteVehicle={deleteVehicle}
              />
            <Box m={4} />
            <Grid item xs={12}>
              <Button id='addVehicle' variant='outlined' onClick={openDialog}><FormattedMessage id="add" /></Button>
            </Grid>

          </Grid>
        </Grid>
        <Dialog 
            fullWidth={true} 
            maxWidth='md' 
            open={dialogOpen} 
            onClose={closeDialog} 
            aria-labelledby='form-dialog-title'>
          <form id="orgForm" onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title"><FormattedMessage id="vehicle" /></DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField 
                    required 
                    InputLabelProps={{shrink:true}}
                    fullWidth={true} 
                    id='vehicleLicensePlate' 
                    label={<FormattedMessage id="vehicle_license_plate_example" />} 
                    defaultValue=''
                    helperText=''>
                  </TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField 
                    required 
                    InputLabelProps={{shrink:true}}
                    fullWidth={true} 
                    id='vehicleName' 
                    label={<FormattedMessage id="vehicle_name" />}
                    defaultValue=''
                    helperText=''>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Box m={3} />
                  <TextField 
                    required 
                    InputLabelProps={{shrink:true}}
                    fullWidth={true} 
                    id='vehicleDescription' 
                    label={<FormattedMessage id="vehicle_description_example" />} 
                    defaultValue=''
                    helperText=''>
                  </TextField>
                  <Box m={3} />
                  <InputLabel id="category-select-label" shrink={true}>
                    <FormattedMessage id="vehicle_category" />
                  </InputLabel>
                  <Select
                    required
                    labelId="category-select-label"
                    id="category"
                    value={selectedCategory}
                    displayEmpty
                    onChange={ event => setSelectedCategory(event.target.value) }
                    fullWidth={true} 
                    >
                    {categories.map(category => (
                      <MenuItem value={category.id} key={category.id}>{category.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Box m={3} />
                  
                  {oidcUser?.profile?.role === "Admin" &&
                  <OrganizationsDropdown
                    handleOrgChange={orgId => setOwner(orgId)}
                    orgType="ServiceProvider"
                    defaultOrg={oidcUser?.profile?.org}
                    />
                  }

                  {oidcUser?.profile?.role === "Organizer" &&
                  <>
                    <InputLabel id="fixed-owner" shrink={true}>
                      <FormattedMessage id="vehicle_owner" />
                    </InputLabel>
                    <Typography>{oidcUser?.profile?.orgname}</Typography>
                  </>
                  }

                </Grid>
              </Grid>
              
              <DialogActions>
                <Button onClick={closeDialog} color="secondary"><FormattedMessage id="cancel" /></Button>
                <Button type="submit" color="primary"><FormattedMessage id="save" /></Button>
              </DialogActions>

            </DialogContent>
          </form>
        </Dialog>

      </div>
    )
    
    }