import React, { useState, useEffect, useCallback } from 'react'
import {
    Grid, TextField, Button, Select, FormControl,
    InputLabel, MenuItem, FormHelperText, makeStyles, Checkbox,
    Typography, Box, Link, Dialog, DialogActions,
    DialogTitle, DialogContentText, DialogContent,
    IconButton, CircularProgress,
} from '@material-ui/core'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    isBefore, format, addHours, set, isAfter, subHours
} from 'date-fns'

import AttachFileIcon from '@material-ui/icons/AttachFile'
import EventIcon from '@material-ui/icons/Event'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import AttachmentIcon from '@material-ui/icons/Attachment'

import { getHours, getMinutes } from 'date-fns/esm'
import { useSnackbar } from 'notistack'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import EstimatedPrice from './EstimatedPrice'
import {
    statuses, views, paymentTypes, emptyGuid,
} from '../utils/constants'
import StatusIcon from './StatusIcon'
import RideInvoicePanel from './RideInvoicePanel'
import configurationContext from '../context/ConfigurationContext'
import RideTimeLine from './RideTimeLine';
import RenderRouteSteps from './RenderRouteSteps';
import RenderGoogleMapsRouteLink from './RenderGoogleMapsRouteLink';
import { ascendingRouteSteps } from '../utils/ascendingRouteSteps';
import {
    tuntiKikkulaSuomeksi,
    tuntiPaivamaaraKikkulaSuomeksi,
    utcToFinnishTime,
    zonedToFinnishTime
} from "../utils/orderUtils";

const Attachment = (props) => {
    return (
        <Grid key={props.id} container item direction="row" alignItems="center">
            <a href={props.fileUrl}>
                <Typography>
                    <AttachmentIcon fontSize="small" /> {props.fileName}
                </Typography>
            </a>
            <IconButton data-test-id="deleteAttachmentButton" onClick={() => props.deleteAttachment()}>
                <DeleteIcon color="secondary" />
            </IconButton>
        </Grid>)
}

const useStyles = makeStyles((theme) => ({
    containerTop: {
        padding: theme.spacing(2),
        backgroundColor: '#F8F6F1',
    },
    containerForm: {
        padding: theme.spacing(4),
    },
    vehiclePicture: {
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            width: '40vw',
        },
        [theme.breakpoints.up('sm')]: {
            width: '20vw',
        },
        height: 'auto',
        margin: 'auto',
    },
    orderForm: {
        width: '100%',
        minWidth: '320px',
    },
    closeIcon: {
        position: 'absolute',
        right: '15px',
        top: '10px',
        '&:hover': {
            color: '#000',
            cursor: 'pointer',
        },
    },
    hidden: {
        visibility: 'hidden',
    },
    swapIcon: {
        position: 'absolute',
        right: '0px',
        transform: 'translateY(-56px)',
    },
    addAttachmentText: {
        cursor: 'pointer',
    },
}))

const emptyRouteSteps = [{
        address: '',
        stepOrder: 1,
        plannedPickupDate: null,
    },
    {
        address: '',
        stepOrder: 2,
        plannedPickupDate: null,
    }]

const OrderForm = (props) => {
    const {
        requestedVehicleClassGuid,
        driveType,
        routeSteps,
        additionalInformation,
        showAdditionalInfoForPassenger,
        terminalPickup,
        numberOfPassengers,
        status,
        passengerName,
        otherPassengerName,
        otherPassengerEmail,
        otherPassengerPhone,
        otherPassengerCompany,
        subscriberCompany,
        plannedPickupDate,
        driverName,
        driverPhone,
        vehicleName,
        vehicleLicensePlate,
        orderNumber,
        subscriberUser,
        passengerUser,
        dateCancelled,
        estimatedTripLengthKm,
        paymentType,
        paymentCreditCardGuid,
    } = props.data
    const intl = useIntl()
    const isNewOrder = (status === statuses.Copy || status === statuses.New)
    const isCancelledOrder = status === statuses.Cancelled
    const isRideEnded = status === statuses.RideEnded
    const configs = React.useContext(configurationContext)
    const [attachments, setAttachments] = useState([])
    const {
        control, 
        register, 
        setValue, 
        handleSubmit, 
        getValues, 
        watch, 
        formState: {errors}, 
        unregister, 
        trigger,
    } = useForm({mode: 'onBlur', defaultValues: {routeSteps: ascendingRouteSteps(routeSteps) || emptyRouteSteps}})
    const { fields, append, remove, move } = useFieldArray({
        control,
        name: 'routeSteps',
    })

    const classes = useStyles()
    const { oidcUser } = useReactOidc()
    const watchVehicleChoosen = watch('requestedVehicleClassGuid', requestedVehicleClassGuid || '')
    const watchPassengerUserGuid = watch('passengerUserGuid')
    const watchPaymentType = watch('paymentType')
    const watchPaymentCard = watch('paymentCreditCardGuid')
    const watchPlannedPickupDate = watch('plannedPickupDate')
    const selectedVehicle = props.vehicleCategories.filter((vehicle) => vehicle.id === watchVehicleChoosen)[0]
    const { enqueueSnackbar } = useSnackbar()
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [deletedAttachmentId, setDeletedAttachmentId] = useState()
    const [organizationUsers, setOrganizationUsers] = useState(props.organizationUsers)
    const [isLoading, setIsLoading] = useState(false)
    const [cards, setCards] = useState([])
    const isAdmin = oidcUser.profile.role === 'Admin'

    const fetchOrganizationUsersById = useCallback((subscriberCompanyId) => {
        setIsLoading(true)
        fetch(`/api/organizations/${subscriberCompanyId}/users`)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
                setIsLoading(false)
                return enqueueSnackbar(<FormattedMessage id="order_get_organizationUsers_error" />, { variant: 'error' });
            })
            .then((data) => {
                setIsLoading(false)
                setOrganizationUsers(data)
            })
            .catch(() => {
                enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: 'error' })
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (props.data.subscriberCompany?.id) {
            fetchOrganizationUsersById(props.data.subscriberCompany.id)
        }
    }, [props.data.subscriberCompany?.id])

    useEffect(() => {
        register('filesToUpload')
        return () => {
            unregister('filesToUpload')
        }
    }, [register, unregister])

    const loadAttachments = useCallback(() => {
        if (!props.data?.id) {
            return
        }
        setIsLoading(true)
        fetch(`/api/Orders/${props.data.id}/attachments`, {
            method: 'GET'
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
            }).then(res => {
                if (res && res.length > 0) {
                    setAttachments(res[0])
                } else {
                    setAttachments([])
                }
                setIsLoading(false)
            })

    }, [props.data.id])

    // fetch attachments
    useEffect(() => {
        loadAttachments()
    }, [loadAttachments])

    const placeHolderCardTemplate = {
        id: paymentCreditCardGuid,
        name: '',
        expiryDate: '',
        maskedPAN: intl.formatMessage({ id: 'cards_other_user_card' }),
    }

    const addPlaceHolderCard = () => {
        if (paymentCreditCardGuid && paymentCreditCardGuid !== emptyGuid) {
            setCards([placeHolderCardTemplate])
        }
    }

    const getCards = (userId) => {
        if (configs?.featureFlags?.cardPayments === false) {
            return
        }
        if (watchPassengerUserGuid !== oidcUser?.profile?.userid) {
            if (paymentCreditCardGuid && paymentCreditCardGuid !== emptyGuid) {
                setCards([placeHolderCardTemplate])
            } else {
                setCards([])
            }
            return
        }
        fetch(`/api/Cards?userAccountId=${userId}`)
        .then((response) => {
            if (response.ok) {
                return response.json()
            }
            return enqueueSnackbar(<FormattedMessage id="cards_get_cards_error" />, { variant: 'error' });
        })
        .then((data) => {
            const cardData = data ?? []
            const cardFound = cardData?.find(c => c.id === paymentCreditCardGuid)
            if (!cardFound) {
                if (paymentCreditCardGuid && paymentCreditCardGuid !== emptyGuid) {
                    // Show place-holder text for user's that don't have
                    // access to this credit card
                    cardData.push(placeHolderCardTemplate)
                }
            }
            setCards(cardData)
        })
        .catch(() => {
            enqueueSnackbar(<FormattedMessage id="serverError_common_server_error" />, { variant: 'error' })
        })
    }

    useEffect(() => {
        if (!isAdmin &&
            !isNewOrder &&
            watchPassengerUserGuid &&
            watchPaymentType === paymentTypes.Card &&
            configs?.featureFlags?.cardPayments === true) {
            getCards(passengerUser.id)
        } else if (isAdmin) {
            // Show place-holder for admin
            addPlaceHolderCard()
        }
    }, [watchPassengerUserGuid, configs, paymentType, watchPaymentType])

    useEffect(() => {
        if (!isAdmin) {
            if (isNewOrder) {
                if (watchPassengerUserGuid) {
                    if (watchPassengerUserGuid !== 'otherPassenger') {
                        getCards(watchPassengerUserGuid)
                    }
                }
            }
        } else {
            // Show place-holder for admin
            addPlaceHolderCard()
        }
    }, [watchPassengerUserGuid])

    const isAfterAllowedTimeLimit =
        (value) => isAfter(new Date(value), addHours(new Date(), selectedVehicle.timeLimitHoursForOrderModification))

    const isFormReadOnly = (!isNewOrder && !isAfterAllowedTimeLimit(plannedPickupDate)) || isRideEnded

    const addToUploads = (file, uploads) => {
        // Check if user clicked "cancel" on file dialog
        if (file) {
            if (uploads === undefined) {
                setValue('filesToUpload', [file])
            } else {
                uploads.push(file)
                setValue('filesToUpload', uploads)
            }
        }
    }

    const confirmAttachmentDelete = (attachmentId) => {
        setDeletedAttachmentId(attachmentId)
        setShowDeleteConfirmation(true)
    }

    // delete file that is already uploaded to backend
    const deleteAttachmentFromBackend = () => {
        fetch(`/api/Attachments/${deletedAttachmentId}`, {
            method: 'DELETE'
        })
            .then((response) => {
                if (response.ok) {
                    return response
                }
            }).then(() => {
                enqueueSnackbar(<FormattedMessage id="delete_succeed" />, { variant: 'info' })
                loadAttachments()
            })
            .catch(() => enqueueSnackbar(<FormattedMessage id="order_fileupload_failed" />, { variant: 'error' }))
            .finally(() => setShowDeleteConfirmation(false))
    }

    // delete file that is not yet uploaded
    const deleteAttachment = (file) => {
        const uploads = getValues()['filesToUpload']
        const attachments = []
        for (const f in uploads) {
            if (uploads[f].name !== file.name) {
                attachments.push(uploads[f])
            }
        }
        setValue('filesToUpload', attachments)
    }

    // Check if card payment should be allowed
    const renderCardPaymentNotAllowed = () => {
        return watchPassengerUserGuid !== oidcUser?.profile?.userid && 
            (!paymentCreditCardGuid || paymentCreditCardGuid === emptyGuid)
    }

    // Check if we should show a hint to user where to add credit cards to user profile
    const renderCardAddLink = () => {
        return watchPassengerUserGuid === oidcUser?.profile?.userid && (!cards || cards?.length === 0)
    }

    // Check if user tries to order with expired card
    const renderCardExpired = () => {
        if(watchPaymentCard && 
           watchPaymentCard !== emptyGuid &&
           cards) {
            const card = cards.find(c => c.id === watchPaymentCard)
            const expDate = new Date(card?.expiryEndOfMonthDate)
            const planned = new Date(watchPlannedPickupDate)
            if( expDate < planned) {
                return true
            }
        } 
        return false
    }

    return (
        <FormProvider fields={fields} control={control} append={append} remove={remove} move={move} errors={errors} setValue={setValue} watch={watch}>
        <form className={classes.orderForm}>
            {isLoading ? <CircularProgress color="secondary" /> :
                <>
                    <CloseIcon color="secondary" className={classes.closeIcon} onClick={props.onClose} />
                    {plannedPickupDate &&
                        <Grid container justify="center" alignItems="center" direction="column">
                            <Grid item container alignItems="center" className={classes.containerTop}>

                                <Grid container item xs={2} justify="center">
                                    <StatusIcon status={status} view={views.orderer} />
                                </Grid>
                                <Grid container item direction="column" xs={10}>
                                    <Grid container item>
                                        <Typography variant="subtitle1">
                                            <Box fontWeight="fontWeightBold">
                                                {passengerName || '- -'} / {numberOfPassengers || '-'} <FormattedMessage id="pers" />
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1">
                                            <Box fontWeight="fontWeightBold">
                                                {subscriberCompany?.name || '-'}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1">
                                            <Box fontWeight="fontWeightBold">
                                                {plannedPickupDate ? tuntiKikkulaSuomeksi(plannedPickupDate) : '-'}
                                            </Box>
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Box m={4}>
                                            <Typography variant="subtitle2">
                                                <Box fontWeight="fontWeightLight">
                                                    <RenderRouteSteps routeSteps={ascendingRouteSteps(routeSteps)} />
                                                </Box>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2">
                                            <Box fontWeight="fontWeightBold">
                                                <FormattedMessage id="car" />: {(`${vehicleName || '-'} ${vehicleLicensePlate || ''}`)}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2">
                                            <Box fontWeight="fontWeightBold">
                                                <FormattedMessage id="driver" />: {driverName || '-'}{driverPhone && ', puh. '}{driverPhone}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {(status === statuses.RideEnded) &&
                    <div>
                        <RideInvoicePanel rideId={props.data?.id} />
                    </div> }
                    <Grid container direction="column" className={classes.containerForm}>
                        {orderNumber &&
                            <Box mb={4}>
                                <Grid item container direction="column">
                                    <Grid item>
                                        <FormattedMessage id="order_order_number" />: {orderNumber}
                                    </Grid>
                                    <Grid item>
                                        <FormattedMessage id="order_customer_number" />: {subscriberCompany?.internalIdentifier || '-'} {subscriberCompany?.name || '-'}
                                    </Grid>
                                    <Grid item>
                                        <FormattedMessage id="order_orderer" />: {subscriberUser?.email || '-'} / tel. {subscriberUser?.phone || '-'}
                                    </Grid>
                                    {dateCancelled && <Grid item>
                                        <FormattedMessage id="order_date_cancelled"/>
                                        :&nbsp;
                                        {format(new Date(dateCancelled), 'dd.MM.yyyy HH:mm')}
                                    </Grid>}
                                    {dateCancelled && <Grid item>
                                        <FormattedMessage id="order_date_cancelled"/>
                                        :&nbsp;
                                        {tuntiPaivamaaraKikkulaSuomeksi(new Date(dateCancelled))}
                                    </Grid>}
                                </Grid>
                            </Box>
                        }

                        <FormControl error={Boolean(errors && errors.passengerUserGuid)} className={classes.formControl}>
                            <InputLabel name="passengerUserGuidLabel" id="passenger-user-guid" disabled={isFormReadOnly}><FormattedMessage id="passenger" /></InputLabel>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    organizationUsers ? <Select
                                        className={classes.contactNameSelect}
                                        labelId="passenger-user-guid"
                                        name="passengerUserGuid"
                                        disabled={isFormReadOnly}
                                        value={value}
                                        onChange={(event) => onChange(event.target.value)}
                                    >
                                        {organizationUsers && organizationUsers.map((person) => <MenuItem key={person.id} name={person.fullName} value={person.id}>{person.fullName}</MenuItem>)}
                                        <MenuItem key="otherPassenger" name="otherPassenger" value="otherPassenger"><FormattedMessage id="order_other_passenger" /></MenuItem>
                                    </Select> : null
                                )}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                name="passengerUserGuid"
                                defaultValue={passengerUser?.id || (otherPassengerName?.length ? 'otherPassenger' : '')}
                            />
                            {errors && errors.passengerUserGuid && <FormHelperText error={Boolean(errors.passengerUserGuid)}><FormattedMessage id="form_input_required" /></FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid container direction="column" className={`${classes.containerForm} ${!watch('passengerUserGuid', passengerUser?.id ? passengerUser.id : '') && classes.hidden}`}>
                        {(watch('passengerUserGuid') === 'otherPassenger') &&
                            <Grid item container spacing={1}>
                                <Grid item xs={12}>
                                    <Controller
                                        defaultValue={otherPassengerName}
                                        name="otherPassengerName"
                                        rules={{required: true}}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                label={<FormattedMessage id="order_other_passenger_name_label" />}
                                                fullWidth
                                                onChange={onChange}
                                                value={value}
                                                disabled={isFormReadOnly}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <Controller
                                        defaultValue={otherPassengerPhone}
                                        name="otherPassengerPhone"
                                        rules={{required: true}}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                label={<FormattedMessage id="order_phonenumber_label" />}
                                                fullWidth
                                                onChange={onChange}
                                                value={value}
                                                disabled={isFormReadOnly}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <Controller
                                        defaultValue={otherPassengerEmail}
                                        name="otherPassengerEmail"
                                        rules={{required: true}}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                label={<FormattedMessage id="order_email_label" />}
                                                fullWidth
                                                onChange={onChange}
                                                value={value}
                                                disabled={isFormReadOnly}
                                            />
                                        )}
                                        />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        defaultValue={otherPassengerCompany}
                                        name="otherPassengerCompany"
                                        rules={{required: true}}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                label={<FormattedMessage id="order_company_label" />}
                                                fullWidth
                                                onChange={onChange}
                                                value={value}
                                                disabled={isFormReadOnly}
                                            />
                                        )}
                                    />
                                    <Box m={2} />
                                </Grid>
                            </Grid>
                        }
                        <Grid item container spacing={1}>
                            <Grid item container direction="column" xs={12}>
                                <InputLabel name="plannedPickupDate" id="planned-pickup-date" disabled={isFormReadOnly}>
                                    <FormattedMessage id={isNewOrder ? "order_pickup_date_finnish_timezone" : "order_pickup_date"}/>
                                </InputLabel>
                                <Controller
                                    control={control}
                                    name="plannedPickupDate"
                                    defaultValue={(status === statuses.New ? props.weekDay : utcToFinnishTime(plannedPickupDate)) || null}
                                    render={({field: {onChange, value}}) => (
                                        <KeyboardDateTimePicker
                                            ampm={false}
                                            id="plannedPickupDatePicker"
                                            disablePast={!isFormReadOnly}
                                            disabled={isFormReadOnly}
                                            format="dd.MM.yyyy HH:mm"
                                            minDateMessage={<FormattedMessage id="datepicker_error_minDateMessage"/>}
                                            keyboardIcon={<EventIcon color="secondary"/>}
                                            openTo="hours"
                                            onChange={(newDate) => {
                                                onChange(newDate)
                                                trigger('plannedPickupDate')
                                                Boolean(getValues()['paymentType']) === paymentTypes.Card && trigger('paymentCreditCardGuid')
                                            }}
                                            value={value}
                                        />)}
                                    rules={{
                                        validate: {
                                            orderNotCreatable: (value) => {
                                                if (isNewOrder) {
                                                    const {
                                                        plannedPickupDate,
                                                        requestedVehicleClassGuid
                                                    } = getValues()

                                                    const currentVehicle = props.vehicleCategories.find((category) => category.id === requestedVehicleClassGuid)
                                                    
                                                    if (!currentVehicle) 
                                                        return true
                                                    
                                                    if (!plannedPickupDate && !value) 
                                                        return true
                                                    
                                                    const fiDate = zonedToFinnishTime(plannedPickupDate);
                                                    
                                                    const finnishPlannedPickupDateTime = set(fiDate, {
                                                        hours: getHours(zonedToFinnishTime(value)),
                                                        minutes: getMinutes(zonedToFinnishTime(value))
                                                    })
                                                    
                                                    const deadline = subHours(finnishPlannedPickupDateTime, currentVehicle.timeLimitHoursForOrderCreation);
                                                    const now = new Date();
                                                    const isOrderCreatable = isBefore(now, deadline);
                                                    return isOrderCreatable;
                                                }
                                                return true // Don't validate existing orders
                                            },
                                            orderNotEditable: (value) => {
                                                if (!isNewOrder) {
                                                    return isAfterAllowedTimeLimit(value)
                                                }
                                                return true // Don't validate new orders
                                            },
                                        },
                                        required: true,
                                    }}
                                />

                                {errors && errors.plannedPickupDate &&
                                    <FormHelperText error={Boolean(errors.plannedPickupDate)}>
                                        {errors.plannedPickupDate.type === 'orderNotCreatable' &&
                                            <>
                                                <FormattedMessage id="order_planned_pickup_time_error_part1" /> {selectedVehicle.timeLimitHoursForOrderCreation} <FormattedMessage id="order_planned_pickup_time_error_part2" /> {selectedVehicle.name} <FormattedMessage id="order_planned_pickup_time_error_part3" />
                                            </>}
                                            {errors.plannedPickupDate.type === 'orderNotEditable' &&
                                            <>
                                                <FormattedMessage id="order_planned_pickup_time_error_editable_part1" /> {selectedVehicle.timeLimitHoursForOrderModification} <FormattedMessage id="order_planned_pickup_time_error_editable_part2" /> {selectedVehicle.name} <FormattedMessage id="order_planned_pickup_time_error_editable_part3" />
                                            </>}
                                        {errors.plannedPickupDate.type === 'required' &&
                                            <FormattedMessage id="form_input_required" />}
                                    </FormHelperText>}
                            </Grid>
                        </Grid>
                        <Grid item container alignContent="center" className={classes.vehiclePicture}>
                            <Box mt={5}>
                                <img width='100%' alt={<FormattedMessage id="order_car_alt_text" />} src={selectedVehicle?.imagePath || '/images/cars/blank.png'} />
                            </Box>
                        </Grid>
                        <FormControl error={Boolean(errors && errors.requestedVehicleClass)} className={classes.formControl}>
                            <InputLabel name="requestedVehicleClassGuidLabel" id="requested-vehicle-class-guid" disabled={isFormReadOnly}><FormattedMessage id="order_car_type" /></InputLabel>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        labelId="requested-vehicle-class-guid"
                                        value={value}
                                        disabled={isFormReadOnly}
                                        onChange={(event) => {
                                            onChange(event.target.value)
                                            trigger('plannedPickupDate')
                                        }}
                                    >
                                        {props.vehicleCategories && props.vehicleCategories.map((vehicle) =>
                                            <MenuItem key={vehicle.id} name={vehicle.name} value={vehicle.id}>{vehicle.name}</MenuItem>
                                        )}
                                    </Select>
                                )}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                name="requestedVehicleClassGuid"
                                defaultValue={requestedVehicleClassGuid || ''}
                            />
                            {errors && errors.requestedVehicleClassGuid && <FormHelperText error={Boolean(errors.requestedVehicleClassGuid)}><FormattedMessage id="form_input_required" /></FormHelperText>}
                            {selectedVehicle &&
                                <FormHelperText>
                                    {selectedVehicle?.name} <FormattedMessage id="order_car_fits_text_part1" /> {selectedVehicle?.maxPassengerCount} <FormattedMessage id="order_car_fits_text_part2" /> {selectedVehicle?.maxBaggageCount} <FormattedMessage id="order_car_fits_text_part3" />
                                </FormHelperText>}
                        </FormControl>
                        <Box m={1} />
                        <FormControl error={Boolean(errors && errors.driveType)}>
                            <InputLabel name="driveType" id="drive-type" disabled={isFormReadOnly}><FormattedMessage id="order_drive_type" /></InputLabel>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        value={value}
                                        name="driveType"
                                        labelId="drive-type"
                                        disabled={isFormReadOnly}
                                        onChange={(selected) => {
                                            onChange(selected.target.value)
                                            if (Number(selected.target.value) === 0) trigger('additionalInformation')
                                        }}
                                    >
                                        <MenuItem key={0} name="driveType1" value={0}><FormattedMessage id="order_drive_type_route" /></MenuItem>
                                        <MenuItem key={2} name="driveType3" value={2}><FormattedMessage id="order_drive_type_hourly" /></MenuItem>
                                    </Select>
                                )}
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                name="driveType"
                                defaultValue={driveType} // change this to be actual value from data
                            />
                            {errors && errors.driveType && <FormHelperText error={Boolean(errors.driveType)}><FormattedMessage id="form_input_required" /></FormHelperText>}
                        </FormControl>
                        <Box m={1} />
                        <RideTimeLine disabled={isFormReadOnly} />
                        <Box mt={2}>
                            <RenderGoogleMapsRouteLink routeSteps={watch('routeSteps')} />
                        </Box>
                        <Box m={1} />
                        <FormControl error={Boolean(errors && errors.numberOfPassengers)} className={classes.formControl}>
                            <Controller
                                render={({ field: { onChange, value, name } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        type="number"
                                        name={name}
                                        label={<FormattedMessage id="order_passenger_count" />}
                                        inputProps={{ max: selectedVehicle?.maxPassengerCount }}
                                        disabled={isFormReadOnly}
                                    />
                                )}
                                defaultValue={numberOfPassengers || 0}
                                control={control}
                                name="numberOfPassengers"
                                rules={{
                                    validate: {
                                        maxPassengerCount: (value) => {
                                            const getCurrentVehicleGuid = getValues().requestedVehicleClassGuid
                                            const currentVehicle = props.vehicleCategories.filter((vehicle) => vehicle.id === getCurrentVehicleGuid)[0]
                                            if (currentVehicle?.maxPassengerCount) {
                                                if (parseInt(value, 10) > currentVehicle?.maxPassengerCount) {
                                                    return false
                                                }
                                            }
                                            return true
                                        },
                                        minPassengerCount: (value) => {
                                            if (parseInt(value, 10) < 1) {
                                                return false
                                            }
                                            if (!value) {
                                                return false
                                            }
                                            return true
                                        },
                                    },
                                    required: true,
                                }}
                            />
                            {errors?.numberOfPassengers?.type === 'maxPassengerCount' && <FormHelperText error><FormattedMessage id="order_chosen_vehicle_max_persons" /> {selectedVehicle.maxPassengerCount}</FormHelperText>}
                            {errors?.numberOfPassengers?.type === 'required' && <FormHelperText error><FormattedMessage id="form_input_required" /></FormHelperText>}
                            {errors?.numberOfPassengers?.type === 'minPassengerCount' && <FormHelperText error><FormattedMessage id="order_add_one_passenger" /></FormHelperText>}
                        </FormControl>
                        <Box m={1} />
                        {configs?.featureFlags?.cardPayments && <>
                        <FormControl error={Boolean(errors && errors.paymentType)} className={classes.formControl}>
                            <InputLabel name="paymentTypeLabel" id="payment-type" disabled={isFormReadOnly || isAdmin}><FormattedMessage id="cards_payment_method" /></InputLabel>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        value={value}
                                        name="paymentType"
                                        labelId="payment-type"
                                        onChange={(event) => {
                                            onChange(event.target.value)
                                            if(event.target.value === paymentTypes.Invoice) {
                                                unregister('paymentCreditCardGuid')
                                            }
                                        }}
                                        inputProps={{ 'data-test-id': 'payment-type' }}
                                    >
                                        <MenuItem
                                            name="invoice"
                                            value={paymentTypes.Invoice}
                                            data-test-id="invoice-payment-type"
                                        >
                                            <FormattedMessage id="cards_invoice" />
                                        </MenuItem>
                                        {watchPassengerUserGuid !== 'otherPassenger' &&
                                            <MenuItem
                                                name="credit-card"
                                                value={paymentTypes.Card}
                                                data-test-id="credit-card-payment-type"
                                            >
                                                <FormattedMessage id="cards_credit_card" />
                                            </MenuItem>}
                                    </Select>
                                )}
                                rules={{ required: true }}
                                control={control}
                                name="paymentType"
                                defaultValue={paymentType || paymentTypes.Invoice}
                                disabled={isFormReadOnly || isAdmin}
                            />
                            {errors && errors.paymentType && <FormHelperText error={Boolean(errors.paymentType)}><FormattedMessage id="form_input_required" /></FormHelperText>}
                        </FormControl>
                        <Box m={1} />
                        </>
                        }
                        {(Boolean(watch('paymentType') === paymentTypes.Card) && configs?.featureFlags?.cardPayments) && <>
                            <FormControl error={Boolean(errors && errors.paymentCreditCardGuid)} className={classes.formControl}>
                                <InputLabel name="paymentCreditCardGuid" id="payment-card" disabled={isFormReadOnly || isAdmin}><FormattedMessage id="cards_choose_credit_card" /></InputLabel>
                                <Controller
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            value={value}
                                            name="paymentCreditCardGuid"
                                            labelId="payment-card"
                                            onChange={(selected) => {
                                                onChange(selected.target.value)
                                            }}
                                        >
                                            {cards.map((card) =>
                                                <MenuItem key={card.id} name={card.issuerName} value={card.id}>
                                                    {card.name}
                                                    &nbsp;
                                                    {card.maskedPAN}
                                                    &nbsp;
                                                    {card.expiryDate && `(exp. ${card.expiryDate})`}
                                                </MenuItem>)}
                                        </Select>
                                    )}
                                    control={control}
                                    name="paymentCreditCardGuid"
                                    defaultValue={paymentCreditCardGuid !== emptyGuid ? paymentCreditCardGuid : ''}
                                    disabled={isFormReadOnly || isAdmin}
                                    rules={{ required: true, validate: {
                                        cardExp: () => {
                                            if(
                                                watchPaymentCard && 
                                                watchPaymentCard !== emptyGuid &&
                                                cards
                                            ) {
                                                const card = cards.find(c => c.id === watchPaymentCard)
                                                const expDate = new Date(card?.expiryEndOfMonthDate)
                                                const planned = new Date(watch('plannedPickupDate'))
                                                if( expDate < planned) {
                                                    return false
                                                }
                                            }
                                            return true
                                        },
                                    }, }}
                                />
                                {errors && errors.paymentCreditCardGuid?.type === 'required' && <FormHelperText error={Boolean(errors.paymentCreditCardGuid)}><FormattedMessage id="form_input_required" /></FormHelperText>}
                                {renderCardPaymentNotAllowed() && <FormHelperText error><FormattedMessage id="orders_card_own_orders" /></FormHelperText>}
                                {errors && errors.paymentCreditCardGuid?.type === 'cardExp' && renderCardExpired() && <FormHelperText error><FormattedMessage id="orders_card_expired" /></FormHelperText>}
                                {renderCardAddLink() &&
                                <FormHelperText>
                                    <FormattedMessage id="order_cards_can_be_added_from" />
                                    &nbsp;
                                    <Link href="/profile/cards">
                                        <FormattedMessage id="orders_user_profile_card_link_title" />
                                    </Link>
                                </FormHelperText>}
                            </FormControl>
                            <Box m={1} />
                        </>
                        }
                        <Grid container alignItems="center">
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox
                                        checked={value}
                                        value={value}
                                        disabled={isFormReadOnly}
                                        onChange={(event) => {
                                            onChange(event.target.checked)
                                        }}
                                        name="terminalPickup"
                                    />
                                )}
                                control={control}
                                name="terminalPickup"
                                defaultValue={terminalPickup || false}
                            />
                            <InputLabel name="terminalPickup" id="terminalPickup" disabled={isFormReadOnly}><FormattedMessage id="order_terminal_pickup" /></InputLabel>
                        </Grid>

                        <Controller
                            name="additionalInformation"
                            rules={{
                                validate: {
                                    driveType: (value) => !(watch('driveType') === 2 && !value),
                                    terminalPickup: (value) => !(watch('terminalPickup') && !value),
                                }
                            }}
                            defaultValue={additionalInformation}
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                                <TextField
                                    value={value}
                                    name={name}
                                    onChange={onChange}
                                    label={<FormattedMessage id="order_additional_information" />}
                                    multiline
                                    disabled={isFormReadOnly}
                                />)}
                        />
                        {errors && errors.additionalInformation && <FormHelperText error={Boolean(errors.additionalInformation)}><FormattedMessage id="form_input_required" /></FormHelperText>}
                        <Grid container alignItems="center">
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox
                                        checked={value}
                                        value={value}
                                        disabled={isFormReadOnly}
                                        onChange={(event) => {
                                            onChange(event.target.checked)
                                        }}
                                        name="showAdditionalInfoForPassenger"
                                    />
                                )}
                                control={control}
                                name="showAdditionalInfoForPassenger"
                                defaultValue={showAdditionalInfoForPassenger || false}
                            />
                            <InputLabel name="showAdditionalInfoForPassenger" id="showAdditionalInfoForPassenger" disabled={isFormReadOnly}><FormattedMessage id="order_show_additional_information_passenger_sms" /></InputLabel>
                        </Grid>
                        <Grid item container alignItems="flex-start" direction="column">
                            {attachments && attachments.map(attachment => <Attachment key={attachment.id} id={attachment.id} fileUrl={attachment.fileUrl} fileName={attachment.fileName} deleteAttachment={() => confirmAttachmentDelete(attachment.id)}></Attachment>)}
                            {watch()['filesToUpload'] && watch()['filesToUpload'].map((f, index) => <Attachment key={index} fileName={f.name} deleteAttachment={() => deleteAttachment(f)}></Attachment>)}
                        </Grid>
                        <Box m={1} />
                        <Grid item container direction="column">
                            <input
                                accept=".gif, .png, .jpg, .jpeg, .zip, .pdf, .doc, .docx, .xls, .xlsx, .rtf"
                                className={classes.input}
                                style={{ display: 'none' }}
                                id="outlined-button-file"
                                type="file"
                                name="filesToUpload"
                                disabled={isFormReadOnly}
                                onChange={(e) => {
                                    addToUploads(e.target.files[0], getValues()['filesToUpload'])
                                    // EDUS-355: Clear input field so that user can select file with same name again
                                    // https://stackoverflow.com/a/42192710/3605
                                    e.target.value = null
                                }}
                            />
                            <label htmlFor="outlined-button-file">
                                <Typography variant="subtitle2" disabled={isFormReadOnly} color="secondary" className={classes.addAttachmentText}>
                                    <AttachFileIcon />
                                    <FormattedMessage id="order_add_attachment_button" />
                                </Typography>
                            </label>
                        </Grid>
                        {(status !== statuses.RideEnded) &&
                        <EstimatedPrice
                            routeSteps={ascendingRouteSteps(watch('routeSteps'))}
                            priceCalculationItems={{
                                vehicleCategoryId: watch('requestedVehicleClassGuid'),
                                startTime: set(new Date(watch('plannedPickupDate')), { hours: getHours(new Date(watch('plannedPickupDate'))), minutes: getMinutes(new Date(watch('plannedPickupDate'))) }),
                                endTime: set(new Date(watch('plannedPickupDate')), { hours: getHours(new Date(watch('plannedPickupDate'))), minutes: getMinutes(new Date(watch('plannedPickupDate'))) }),
                                rideLengthKm: estimatedTripLengthKm || 0,
                                waitingMinutes: 0,
                                isHourDrive: Boolean(watch('driveType') === 2) || false,
                                terminalPickup: watch('terminalPickup') || false,
                            }}
                        /> }

                        <Button data-test-id="saveOrderButton" color="secondary" type="button" disabled={isFormReadOnly || isCancelledOrder} onClick={handleSubmit(props.onSubmitForm)}>{isNewOrder ? <FormattedMessage id="order_send_order_button" /> : <FormattedMessage id="order_save_order_button" />}</Button>
                        {!isNewOrder && <Button type="button" data-test-id="cancelOrderButton" disabled={!props.isOrderCancelable() || isCancelledOrder} onClick={props.cancelRide}><FormattedMessage id="order_cancel_order_button" /></Button>}

                        {(!isNewOrder) && <Typography color="secondary">
                            <FormattedMessage id="confirm_modal_cancel_text_part1" /> {selectedVehicle.timeLimitHoursForOrderCancellation} <FormattedMessage id="confirm_modal_cancel_text_part2" /> {selectedVehicle.timeLimitHoursForOrderModification} <FormattedMessage id="confirm_modal_cancel_text_part3" />
                        </Typography>}
                    </Grid>
                    <Dialog open={showDeleteConfirmation} maxWidth="xs">
                        <DialogTitle><FormattedMessage id="order_attachment_are_you_sure" /></DialogTitle>
                        <DialogContent>
                            <DialogContentText><FormattedMessage id="order_attachment_cannot_be_retrieved" /></DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button data-test-id="cancelAttachmentDelete" color="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                                <FormattedMessage id="cancel" />
                            </Button>
                            <Button data-test-id="confirmAttachmentDelete" color="primary" variant="outlined" onClick={() => deleteAttachmentFromBackend()}>
                                <FormattedMessage id="delete" />
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>}
        </form>
        </FormProvider>
    )
}

OrderForm.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string,
        dateCancelled: PropTypes.string,
        requestedVehicleClass: PropTypes.string,
        plannedPickupDate: PropTypes.string,
        deliveryAddress: PropTypes.shape({
            address: PropTypes.string,
            city: PropTypes.string,
        }),
        vehicleName: PropTypes.string,
        orderNumber: PropTypes.string,
        passengerName: PropTypes.string,
        status: PropTypes.number,
        requestedVehicleClassGuid: PropTypes.string,
        numberOfPassengers: PropTypes.number,
        additionalInformation: PropTypes.string,
        showAdditionalInfoForPassenger: PropTypes.bool,
        terminalPickup: PropTypes.bool,
        driveType: PropTypes.number,
        vehicleLicensePlate: PropTypes.string,
        driverName: PropTypes.string,
        driverPhone: PropTypes.string,
        passengerUser: PropTypes.shape({
            id: PropTypes.string,
        }),
        otherPassengerName: PropTypes.string,
        otherPassengerEmail: PropTypes.string,
        otherPassengerPhone: PropTypes.string,
        otherPassengerCompany: PropTypes.string,
        subscriberUser: PropTypes.shape({
            email: PropTypes.string,
            phone: PropTypes.string,
        }),
        subscriberCompany: PropTypes.shape({
            name: PropTypes.string,
            internalIdentifier: PropTypes.string,
        }),
        estimatedTripLengthKm: PropTypes.number,
        paymentType: PropTypes.number,
        paymentCreditCardGuid: PropTypes.string,
    }).isRequired,
    vehicleCategories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        imagePath: PropTypes.string,
        timeLimitHoursForOrderCancellation: PropTypes.number,
        timeLimitHoursForOrderModification: PropTypes.number,
        timeLimitHoursForOrderCreation: PropTypes.number,
    })).isRequired,
    weekDay: PropTypes.instanceOf(Date).isRequired,
    onSubmitForm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    cancelRide: PropTypes.func,
    isOrderCancelable: PropTypes.func,
    onFilesToUploadChange: PropTypes.func,
    routeSteps: PropTypes.arrayOf(PropTypes.shape({
        stepId: PropTypes.string,
        address: PropTypes.string,
        stepOrder: PropTypes.number,
        plannedPickupDate: PropTypes.string,
    })),
}

export default OrderForm