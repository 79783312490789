import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Button, makeStyles, DialogContent, DialogActions, DialogTitle } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
    }
}))

const ConfirmationDialog = (props) => {
    const classes = useStyles()

    return (
        <>
            <DialogTitle>{props.headerText}</DialogTitle>
            <DialogContent>
                <Grid container direction="column" justify="center" className={classes.container}>
                    <Grid item>
                        <Typography>
                            {props.typography1}
                        </Typography>
                    </Grid>
                    {props.typography2 && <Grid item>
                        <Typography>
                            {props.typography2}
                        </Typography>
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" autoFocus onClick={() => props.onConfirmClick()}>{props.modalConfirmButtonText}</Button>
                <Button color="secondary" onClick={props.onCloseClick}><FormattedMessage id="back" /> </Button>
            </DialogActions>
        </>
    )
}

ConfirmationDialog.propTypes = {
    onCloseClick: PropTypes.func,
    onConfirmClick: PropTypes.func,
    modalConfirmButtonText: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    headerText: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    typography1: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    typography2: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
}

export default ConfirmationDialog