export const paymentTypes = {
    Invoice: 0,
    Card: 1,
}

export const statuses = {
    Ordered: 10,
    Confirmed: 20,
    Assigned: 30,
    DriverConfirmed: 40,
    DriverStarted: 50,
    RideStarted: 60,
    RideEnded: 70,
    Cancelled: 99,
    Copy: 888,
    New: 999,
}

export const views = {
    orderer: 'ORDERER',
    organizer: 'ORGANIZER',
    driver: 'DRIVER',
}

export const emptyGuid = '00000000-0000-0000-0000-000000000000'

// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const FinnishTime = 'Europe/Helsinki'
