import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, InputLabel, Grid, Typography, Box, Badge } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const OrderFilterer = (props) => {
    return (
        <Box mt={2}>
            <Typography><FormattedMessage id="organizer_show" />:</Typography>
            {props.filtersList.map((filter, index) =>
                <Grid key={index} container alignItems="center">
                    <InputLabel>
                        <Checkbox defaultChecked={filter.defaultChecked} name={filter.filterName} onChange={(e) => props.onSelectFilter(e.target.name, e.target.checked)} />
                        <Badge badgeContent={(props.statusCounts ? props.statusCounts[`${filter.status}`] : 0)}>
                            <Typography>
                                {filter.name}
                            </Typography>
                        </Badge>
                    </InputLabel>
                </Grid>
            )}
        </Box>
    )
}

OrderFilterer.propTypes = {
    filtersList: PropTypes.array,
    onSelectFilter: PropTypes.func,
    statusCounts: PropTypes.object,
}

export default OrderFilterer
