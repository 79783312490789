import React, { useState } from 'react'
import { InputLabel, Select, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import vehicleCategoriesContext from '../context/VehicleCategoriesContext'

const VehicleCategoriesDropdown = ({ handleCategoryChange, defaultValue, labelText }) => {
    const { categories } = React.useContext(vehicleCategoriesContext)
    const [category, setCategory] = useState('')

    const categoryItems = categories.map((ctry) => {
        const { id, name } = ctry
        return (
            <MenuItem
                value={id}
                key={id}
            >
                {name}
            </MenuItem>)
    })

    const handleChange = (event) => {
        setCategory(event.target.value)
        handleCategoryChange(event.target.value)
    }

    return (
        <>
            {categories?.length !== 1 &&
                <>
                    <InputLabel id="organizations-select-label" shrink>{labelText}</InputLabel>
                    <Select
                        labelId="organizations-select-label"
                        id="organizations-select"
                        value={category || defaultValue}
                        defaultValue={defaultValue || ''}
                        onChange={handleChange}
                        fullWidth
                    >
                        <MenuItem value="" key="">
                            <FormattedMessage id="choose" />
                        </MenuItem>
                        {categoryItems}
                    </Select>
                </>}
        </>
    )
}

VehicleCategoriesDropdown.propTypes = {
    handleCategoryChange: PropTypes.func,
    defaultValue: PropTypes.string,
    labelText: PropTypes.object,
}

VehicleCategoriesDropdown.defaultProps = {
    handleCategoryChange: null,
    defaultValue: '',
    labelText: <FormattedMessage id="vehicle_class" />,
}

export default VehicleCategoriesDropdown
