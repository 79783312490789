import React, {
    useState, useContext, useEffect, useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { format, differenceInMinutes } from 'date-fns'
import { addMinutes } from 'date-fns/esm'

import {
    Grid, Typography, makeStyles, Box,
    DialogContent, IconButton, Button,
    Dialog, DialogTitle, DialogActions,
    withStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AttachmentIcon from '@material-ui/icons/Attachment'

import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import { FormattedMessage } from 'react-intl'

import { views, statuses, } from '../../utils/constants'
import vehicleCategoriesContext from '../../context/VehicleCategoriesContext'
import StatusIcon from '../StatusIcon'
import StatusIconText from '../StatusIconText'
import EndRideDetails from './EndRideDetails'
import RideInvoicePanel from '../RideInvoicePanel'
import RidePaymentPanel from '../RidePaymentPanel'
import RenderRouteSteps from '../RenderRouteSteps'
import RenderGoogleMapsRouteLink from '../RenderGoogleMapsRouteLink'
import { ascendingRouteSteps } from '../../utils/ascendingRouteSteps'
import { TimelineContent } from '@material-ui/lab'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineDot from '@material-ui/lab/TimelineDot'

const StyledTimelineItem = withStyles({
    root: {
        paddingLeft: '0px',
        paddingRight: '0px',
    },
    missingOppositeContent: {
        '&:before': {
            display: 'none',
        },
    },
})(TimelineItem)

const StyledTimeline = withStyles({
    root: {
        paddingLeft: '0px',
        paddingRight: '0px',
    },
})(Timeline)

const StyledTimelineContent = withStyles({
    root: {
        paddingRight: '0px',
    },
})(TimelineContent)

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        margin: '0 !important',
        width: '100% !important',
    },
    statusIcon: {
        margin: '0 !important',
        padding: '0 !important',
        width: '100%',
        paddingTop: theme.spacing(4),
        textAlign: 'center',
        height: '200px',
        marginLeft: 'auto',
    },
    dialogPage: {
        padding: theme.spacing(1),
    },
    rideInfo: {
        backgroundColor: '#F8F6F1',
        //margin: theme.spacing(1),
        /* Width and margin below relates to: https://github.com/mui-org/material-ui/issues/7466*/
        width: '100% !important',
        margin: '0 !important',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        zIndex: 1202,
    },
    hintText: {
        color: '#00000089',
    },
    inputSection: {
        marginTop: theme.spacing(3),
    },
    boldText: {
        fontWeight: 'bold',
    },
    noWrapField: {
        whiteSpace: 'nowrap',
    },
    button: {
        borderRadius: '17px',
        boxShadow: '0px 3px 6px #00000029',
        border: 'none',
    },
}))

const Attachment = (props) => {
    const { id, fileUrl, fileName } = props
    return (
        <Grid key={id} container item direction="row">
            <a href={fileUrl}>
                <Typography>
                    <AttachmentIcon fontSize="small" />
                    {fileName}
                </Typography>
            </a>
        </Grid>)
}

const RideDetails = (props) => {
    const classes = useStyles()
    const { categories } = React.useContext(vehicleCategoriesContext)
    const [selectedRide, setSelectedRide] = useState(null)
    const [attachments, setAttachments] = useState([])
    const [showEndRideDetails, setShowEndRideDetails] = useState(false)
    const [showFreeUpRideConfirmation, setShowFreeUpRideConfirmation] = useState(false)
    const { oidcUser } = useContext(AuthenticationContext)
    const decimalIntl = new Intl.NumberFormat('fi-FI')

    useEffect(() => {
        setSelectedRide(props.rides.find((ride) => ride.id === props.rideId))
    }, [props.rides, props.rideId])

    const loadAttachments = useCallback(() => {
        if (!selectedRide?.order?.id) {
            return
        }
        fetch(`/api/Orders/${selectedRide.order.id}/attachments`, {
            method: 'GET'
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
            }).then((res) => {
                if (res && res.length > 0) {
                    setAttachments(res[0])
                } else {
                    setAttachments([])
                }
            })

    }, [selectedRide])

    // fetch attachments
    useEffect(() => {
        loadAttachments()
    }, [loadAttachments])

    const renderButtons = () => {
        const buttonsToShow = [
            <Box m={1}><Button variant="outlined" className={classes.button} data-test-id="endRideButton" onClick={() => setShowEndRideDetails(true)} color="secondary"><FormattedMessage id="ride_end_ride_button" /></Button></Box>,
            <Box m={1}><Button variant="outlined" className={classes.button} data-test-id="moveToRideButton" onClick={() => props.changeStatus(statuses.DriverStarted)} color="secondary"><FormattedMessage id="ride_move_to_location_button" /></Button></Box>,
            <Box m={1}><Button variant="outlined" className={classes.button} data-test-id="startRideButton" onClick={() => props.changeStatus(statuses.RideStarted)} color="secondary"><FormattedMessage id="ride_start_ride_button" /></Button></Box>,
            <Box m={1}><Button data-test-id="freeUpRideButton" onClick={() => setShowFreeUpRideConfirmation(true)} color="secondary"><FormattedMessage id="ride_free_up" /></Button></Box>,
            <Box m={1}><Button data-test-id="completedRideButton" onClick={() => setShowEndRideDetails(true)} color="secondary"><FormattedMessage id="ride_completed_ride_button" /></Button></Box>,
            <Box m={1}><Button variant="outlined" className={classes.button} data-test-id="confirmRideButton" onClick={() => props.changeStatus(statuses.DriverConfirmed)} color="secondary"><FormattedMessage id="ride_sign_ride" /></Button></Box>,
        ]
        if (selectedRide.status === statuses.Assigned) {
            return (
            <>
            {buttonsToShow[3]}
            {buttonsToShow[5]}
            </>)
        }
        if (selectedRide.status === statuses.RideStarted) {
            return (
            <>
            {buttonsToShow[0]}
            </>)
        }
        if (selectedRide.status === statuses.DriverConfirmed) {
            return (
            <>
            {buttonsToShow[3]}
            {buttonsToShow[1]}
            </>)
        }
        if (selectedRide.status === statuses.DriverStarted) {
            return (
            <>
            {buttonsToShow[2]}
            </>)
        }
        if (selectedRide.status === statuses.RideEnded &&
            !selectedRide.order?.invoiced) {
            return (
            <>
            {buttonsToShow[4]}
            </>)
        }
        return <></>
    }

    const submitRideDetails = (data) => {
        props.submitRideDetails(selectedRide.id, data)
        setShowEndRideDetails(false)
    }

    const freeUpRide = () => {
        props.freeUpRide(selectedRide.id)
        props.onCloseClick()
    }

    const renderTimeLineConnector = (index) => selectedRide.routeSteps.length > (index + 1)

    const renderInvoiceAndDetails = () =>
        selectedRide.status === statuses.RideEnded &&
        oidcUser?.profile?.role === "Organizer"

    return (
        selectedRide &&
        <>
            <IconButton aria-label="close" className={classes.closeButton} onClick={props.onCloseClick}>
                <CloseIcon />
            </IconButton>
            {showEndRideDetails ?
            <EndRideDetails
                rideActualStartDate={selectedRide.actualStart}
                rideActualEndDate={selectedRide.actualEnd}
                rideApprovedLength={selectedRide.approvedTripLengthKm}
                rideApprovedWaitingMinutes={selectedRide.approvedWaitingMinutes}
                rideApprovedDriverNotes={selectedRide.approvedDriverNotes}
                rideEstimatedTripLengthKm={selectedRide.estimatedTripLengthKm}
                rideStatus={selectedRide.status}
                onSubmitRideDetails={(data) => submitRideDetails(data)}
            /> :
            <DialogContent className={classes.dialogPage}>
                <div className={classes.rideInfo}>
                    <Grid container direction="row" spacing={4} className={classes.container}>
                        <Grid item xs={3} sm={2} className={classes.statusIcon}>
                            <Grid container item className={classes.statusIcon} alignItems="center" direction="column" justify="center">

                                {selectedRide.status === statuses.Assigned &&
                                    selectedRide.driverId === oidcUser?.profile?.userid &&
                                    <>
                                        <IconButton
                                            data-test-id="confirmAssignmentButton"
                                            onClick={() => {
                                                props.changeStatus(statuses.DriverConfirmed)
                                            }}
                                        >
                                            <StatusIcon status={selectedRide.status} view={views.driver} />
                                        </IconButton>
                                        <StatusIconText status={selectedRide.status} />
                                    </>
                                }
                                {(selectedRide.status !== statuses.Assigned ||
                                    selectedRide.driverId !== oidcUser?.profile?.userid) &&
                                    <>
                                        <StatusIcon status={selectedRide.status} view={views.driver} />
                                        <StatusIconText status={selectedRide.status} />
                                    </>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={9} sm={10}>
                            <Grid container className={classes.container} direction="row">
                                <Grid item xs={12}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {selectedRide.order.passengerUser && selectedRide.order.passengerUser.fullName}{selectedRide.order.otherPassengerName && <>{selectedRide.order.otherPassengerName} {selectedRide.order.otherPassengerCompany}</>} / {selectedRide.order.numberOfPassengers} henk.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {selectedRide.order.subscriberCompany.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {format(new Date(selectedRide.plannedPickupDate), 'HH:mm')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container direction="column" className={classes.container}>
                                        <Grid item>
                                            <Typography variant="subtitle2">
                                                <RenderRouteSteps routeSteps={selectedRide.routeSteps} />
                                            </Typography>
                                        </Grid>
                                        <Box mt={1} />
                                        <Grid item>
                                            <Typography variant="subtitle2">
                                                {selectedRide.status === statuses.RideEnded &&
                                                    <>
                                                        <FormattedMessage id="ride_actual_ride_length" /> {decimalIntl.format(selectedRide.approvedTripLengthKm)} km<br />
                                                        <FormattedMessage id="ride_actual_ride_duration" /> {format(new Date(selectedRide.actualStart), 'HH:mm')} - {format(new Date(selectedRide.actualEnd), 'HH:mm')} ({differenceInMinutes(new Date(selectedRide.actualEnd), new Date(selectedRide.actualStart))} min.)<br />
                                                        <FormattedMessage id="ride_waiting_time" /> {selectedRide.approvedWaitingMinutes} min<br />
                                                        <FormattedMessage id="ride_other_notes" />:<br />{selectedRide.approvedDriverNotes}<br />
                                                    </>
                                                }
                                                {selectedRide.status !== statuses.RideEnded &&
                                                    <>
                                                        <FormattedMessage id="ride_expected_duration" /> {selectedRide.estimatedDurationMinutes} min<br />
                                                        <FormattedMessage id="ride_estimated_trip_length_km" /> {selectedRide.estimatedTripLengthKm} km <br />
                                                        <FormattedMessage id="ride_expected_arrival_time" /> {format(addMinutes(new Date(selectedRide.plannedPickupDate), selectedRide.estimatedDurationMinutes), 'HH:mm')}
                                                    </>
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Box mt={2}>
                                                <Typography color="primary" variant="subtitle2" className={classes.boldText}>
                                                    <FormattedMessage id="car" />: {selectedRide.vehicleLicensePlate || '-'}
                                                </Typography>
                                                <Typography color="primary" variant="subtitle2" className={classes.boldText}>
                                                    <FormattedMessage id="driver" />: {selectedRide.driverName || '-'}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} alignItems="center" direction="column" justify="center">
                        {renderButtons()}
                    </Grid>
                </div>
                {renderInvoiceAndDetails() &&
                <div>
                    <RideInvoicePanel rideId={selectedRide.order?.id} />
                    <RidePaymentPanel
                        paymentType={selectedRide.order.paymentType}
                        invoiced={selectedRide.order.invoiced}
                    />
                </div>}
                <div>
                    <Grid container className={classes.container} direction="column" justify="center" alignItems="center" spacing={4}>
                        <Grid item xs={12} className={classes.container}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                <Grid container item xs={6} direction="column" alignItems="flex-start">
                                    <Typography variant="subtitle2"><FormattedMessage id="ride_ride_number" /> {selectedRide.order.number}</Typography>
                                    <Typography variant="subtitle2"><FormattedMessage id="customer" /> {selectedRide.order.subscriberCompany.name}</Typography>
                                    <div><Typography variant="subtitle2"><FormattedMessage id="orderer" />: <span className={classes.noWrapField}>{selectedRide.order.subscriberUser.fullName}</span> / <span className={classes.noWrapField}>tel. <a href={"tel:" + selectedRide.order.subscriberUser.phone}>{selectedRide.order.subscriberUser.phone}</a></span></Typography></div>
                                    <div><Typography variant="subtitle2"><FormattedMessage id="passenger" />: <span className={classes.noWrapField}>{selectedRide.order.otherPassengerName || selectedRide.order.passengerUser.fullName}</span> {selectedRide.order.otherPassengerCompany && <span className={classes.noWrapField}>({selectedRide.order.otherPassengerCompany})</span>} / <span className={classes.noWrapField}>tel. <a href={"tel:" + (selectedRide.order.otherPassengerPhone?.length ? selectedRide.order.otherPassengerPhone : selectedRide.order.passengerUser.phone)}>{selectedRide.order.otherPassengerPhone?.length ? selectedRide.order.otherPassengerPhone : selectedRide.order.passengerUser.phone}</a></span></Typography></div>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2"><FormattedMessage id="attachment" /></Typography>
                                    {attachments && attachments.map(attachment => <Attachment key={attachment.id} id={attachment.id} fileUrl={attachment.fileUrl} fileName={attachment.fileName} ></Attachment>)}
                                </Grid>
                            </Grid>
                            <Grid container className={classes.inputSection}>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={9} container direction="column" spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={8}>
                                                <Box color="#00000089" fontWeight="fontWeightLight" className={classes.hintText}>
                                                    <Typography variant="subtitle2"><FormattedMessage id="vehicle_class" /></Typography>
                                                </Box>
                                                <Typography variant="subtitle2">{categories.filter(c => c.id === selectedRide.requestedVehicleClassGuid)[0]?.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={8} container alignItems="center">
                                                <Grid item>
                                                    <Box mt={2} color="#00000089" fontWeight="fontWeightLight" className={classes.hintText}>
                                                        <Typography variant="subtitle2"><FormattedMessage id="drive_type" /></Typography>
                                                    </Box>
                                                    <Typography variant="subtitle2">
                                                        {selectedRide.order.driveType === 0 && <FormattedMessage id="order_drive_type_route" />}
                                                        {selectedRide.order.driveType === 2 && <FormattedMessage id="order_drive_type_hourly" />}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <StyledTimeline>
                                            {ascendingRouteSteps(selectedRide.routeSteps).map((routeStep, index) => (
                                                <StyledTimelineItem key={index}>
                                                    <TimelineSeparator>
                                                        <TimelineDot color="secondary" />
                                                        {renderTimeLineConnector(index) && <TimelineConnector />}
                                                    </TimelineSeparator>
                                                    <StyledTimelineContent>
                                                        <Grid key={index} container>
                                                            <Grid item>
                                                                <Box color="#00000089" fontWeight="fontWeightLight" className={classes.hintText}>
                                                                    <Typography variant="subtitle2">
                                                                        {index === 0 &&  <FormattedMessage id="ride_departure" />}
                                                                        {index > 0 && (index + 1 !== selectedRide.routeSteps.length) && <FormattedMessage id="ride_route_stop" />}
                                                                        {index + 1 === selectedRide.routeSteps.length && <FormattedMessage id="ride_destination" />}
                                                                    </Typography>
                                                                </Box>
                                                                
                                                                <Typography variant="subtitle2">{routeStep.address}</Typography>
                                                                {
                                                                    index > 0 && 
                                                                    (index + 1 !== selectedRide.routeSteps.length) && 
                                                                    <Typography variant="subtitle2"><FormattedMessage id="ride_start_time_from_destination" />: {routeStep.plannedPickupDate ? format(new Date(routeStep.plannedPickupDate), 'dd.MM.yyyy HH:mm') : '-'}</Typography>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTimelineContent>
                                                </StyledTimelineItem>
                                            ))}
                                        </StyledTimeline>

                                        <RenderGoogleMapsRouteLink routeSteps={selectedRide.routeSteps} />

                                        <Grid container direction="column" spacing={1}>
                                            <Box mt={2}>
                                                <Grid item>
                                                    <Typography variant="subtitle2" color="secondary"><FormattedMessage id="ride_estimated_travel_time" /> {selectedRide.estimatedDurationMinutes} min</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2" color="secondary"><FormattedMessage id="ride_estimated_trip_length_km" /> {selectedRide.estimatedTripLengthKm} km</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2" color="secondary"><FormattedMessage id="ride_estimated_arrival_time" /> {format(new Date(selectedRide.plannedDropoffDate), 'HH:mm')}</Typography>
                                                </Grid>
                                            </Box>
                                            <Box mt={2} color="#00000089" fontWeight="fontWeightLight" className={classes.hintText}>
                                                <Typography variant="subtitle2">
                                                    <FormattedMessage id="ride_additional_services" />
                                                </Typography>
                                            </Box>
                                            <Typography variant="subtitle2">{selectedRide.order.terminalPickup && <FormattedMessage id="ride_terminal_pickup" />}</Typography>
                                            <Box mt={2} color="#00000089" fontWeight="fontWeightLight" className={classes.hintText}>
                                                <Typography variant="subtitle2"><FormattedMessage id="ride_additional_information" />&nbsp;
                                                    {selectedRide.order.showAdditionalInfoForPassenger && <>(<FormattedMessage id="ride_show_info_for_passenger" />)</>}
                                                    {!selectedRide.order.showAdditionalInfoForPassenger && <>(<FormattedMessage id="ride_hidden_info_for_passenger" />)</>}
                                                </Typography>
                                            </Box>
                                            <Typography variant="subtitle2">{selectedRide.order.additionalInformation}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            }
            <Dialog open={showFreeUpRideConfirmation}>
                <DialogTitle>
                    <FormattedMessage id="are_you_sure" />
                </DialogTitle>
                <DialogContent>
                    <FormattedMessage id="ride_free_up_confirmation" />
                </DialogContent>
                <DialogActions>
                    <Button
                        data-test-id="confirmFreeUpRideButton"
                        onClick={() => freeUpRide()}
                        color="secondary"
                    >
                        <FormattedMessage id="yes" />
                    </Button>
                    <Button
                        data-test-id="cancelFreeUpRideButton"
                        onClick={() => setShowFreeUpRideConfirmation(false)}
                        color="secondary"
                    >
                        <FormattedMessage id="no" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

RideDetails.propTypes = {
    onCloseClick: PropTypes.func,
    onDriverConfirm: PropTypes.func,
    freeUpRide: PropTypes.func,
}

export default RideDetails